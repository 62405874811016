import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';

export default function TermsAndConditions() {
    const header = (text: string) => <Typography variant='h5'>{text}</Typography>
    const paragraph = (text: string, type: 'body1' | 'subtitle1' = "body1") => <Typography variant={type}>{text}</Typography>
    return (
        <Paper>
            <Stack
                direction={'column'}
                spacing={1}
                sx={{ p: 2 }}
            >
                {header("TERMS OF USE PLANVAS")}
                {paragraph("Established on 2023-07-23")}
                {paragraph(`1	BACKGROUND`, 'subtitle1')}
                {paragraph(`1.1	Plexillion AB, org. nr. 559386-0173, ("we" or "us") has developed and provides a digital platform for innovation promoters ("Planvas"). These terms of use ("Terms") shall apply when you as a user ("you") register an account that gives access to Planvas.`)}
                {paragraph(`1.2	You are only entitled to use Planvas in accordance with the Terms. By registering a User Account (defined below) and/or by accessing or using Planvas, you accept and agree to be bound by these Terms, which constitute a binding agreement between you and us.`)}
                {paragraph(`2	USER ACCOUNT`, 'subtitle1')}
                {paragraph(`2.1	To gain access to Planvas, you must register an account ("User Account"). To register a User Account, you must provide the information requested in the registration process. By registering a User Account, you guarantee that the registered information from you is true, correct, and complete.`)}
                {paragraph(`2.2	Username, password, and any other login details are personal and may not be transferred to, shared with, or disclosed to anyone else. You are obliged to keep login details confidential and ensure that these are handled securely. You are responsible for your use of Planvas and for all activity that takes place on your User Account.`)}
                {paragraph(`3	USE`, 'subtitle1')}
                {paragraph(`3.1	You must always use Planvas in a responsible manner, in accordance with these Terms and in accordance with our instructions provided at any time.`)}
                {paragraph(`3.2	It is not permitted to:`)}
                {paragraph(`     a)	copy, distribute, make available, transmit, display, perform, reproduce, publish, license Planvas, or transfer or sell any software, product, service or information obtained from Planvas;`)}
                {paragraph(`     b)	use Planvas in violation of applicable laws and regulations, including but not limited to intellectual property laws, applicable laws for the processing of personal data, or use Planvas in an illegal manner or for an illegal purpose;`)}
                {paragraph(`     c)	create, install, add or distribute viruses, malware or other malicious code in or through Planvas;`)}
                {paragraph(`d)	to reverse engineer, disassemble, attempt to derive the source code of, modify, or create adaptations of Planvas, or equivalent manipulation of Planvas; or`)}
                {paragraph(`e)	disrupt or block Planvas.`)}
                {paragraph(`4	INTELLECTUAL PROPERTY`, 'subtitle1')}
                {paragraph(`4.1	All intellectual property rights to Planvas, including updates, upgrades, extensions, new versions and add-ons and all adaptations and versions thereof belong to us and/or, where applicable, our suppliers and/or licensors.`)}
                {paragraph(`4.2	You are aware that we or other users may share information, data, and other material through Planvas and that this may be protected by copyright or other intellectual property rights. You therefore undertake not to, on your own or through others, directly or indirectly, attempt to register, use or infringe our or other users' patents, copyrights, trademarks, company names or other intellectual property rights, with the exception of what expressly follows from these Terms.`)}
                {paragraph(`5	CONFIDENTIALITY`, 'subtitle1')}
                {paragraph(`You undertake not to disclose to a third party any information received from other users of Planvas, without their consent. This includes any information that may reasonably be of a confidential nature, including but not limited to trade secrets, template documents, know-how and the like. The confidentiality commitment shall not include confidential information that you can demonstrate (i) was already known to you before the conclusion of the Terms, (ii) is or has become publicly known in a way other than through violation of the Terms, (iii) you have independently developed without use of confidential information. This confidentiality commitment shall apply even after your User Account has been terminated or the Terms have otherwise ceased to apply.`)}
                {paragraph(`6	TERMINATION AND SUSPENSION`, 'subtitle1')}
                {paragraph(`6.1	In addition, we reserve the right to suspend or terminate, temporarily or permanently, your access to all or part of Planvas at any time, without liability and without prior notice to you and without having to state any reason, if we discover or suspect that you:`)}
                {paragraph(`     a)	have acted in breach of the Terms;`)}
                {paragraph(`     b)	use Planvas in a way that may cause harm to us or third parties; or`)}
                {paragraph(`     c)	according to our judgement, risk damaging our or an innovative promoter’s brand or reputation.`)}
                {paragraph(`7	SERVICES PROVIDED BY THIRD PARTIES`, 'subtitle1')}
                {paragraph(`Planvas may contain information about or link to services or products provided by third parties. We are not responsible for the services or products of third parties or for the information published by third parties, the information published about third parties or for the acts or negligence of third parties.`)}
                {paragraph(`8	LIMITATION OF LIABILITY`, 'subtitle1')}
                {paragraph(`8.1	Planvas is provided “as is” and “as available” and without warranties or representations, whether express or implied, by operation of law or otherwise, with respect to Planvas, including that it is error-free, of a certain quality, has certain performance or any warranties of merchantability or fitness for a particular purpose.`)}
                {paragraph(`8.2	Our liability for Planvas is regulated exclusively with innovation promoters and we are, under no circumstances, liable to you for direct or indirect damages (including loss of data, loss of profit, etc.) caused in connection with the use of Planvas. You yourself are responsible for the decision to use or refrain from using Planvas. As the outcome of the use depends, among other things, on the answers provided as well as subsequent analyses, considerations, and decisions, we are not responsible for measures taken - or omitted to be taken - as a result of the use.`)}
                {paragraph(`9	ADDITIONS AND CHANGES`, 'subtitle1')}
                {paragraph(`9.1	We reserve the right to make additions to, or otherwise amend, these Terms at any time. By continuing to use Planvas, you confirm that you accept such adjustment to the Terms.`)}
                {paragraph(`9.2	We reserve the right to change Planvas through updates, upgrades, extensions and/or new versions of Planvas without prior notice and assume no responsibility for such changes other than our responsibility for Planvas in general under these Terms.`)}
            </Stack>






        </Paper>
    )
}



import React, { useCallback, useState } from 'react';
import { Editor, TLStoreSnapshot, Tldraw } from 'tldraw';
import CanvasBoxShapeUtil from './compontents/BoxShape';
import { useCanvasLayout } from './helpers/useCreateCanvasLayout';
import { useMetricsLayout } from './helpers/useMetricsLayout';
import { useSuccessDef } from './helpers/useSuccessDef';
import _jsonSnapshot from './snapshots/snapshot1.json';
import './tldraw.css';
const jsonSnapshot = _jsonSnapshot as TLStoreSnapshot

const customShape = [CanvasBoxShapeUtil as any]

const width = 250
const h = 350
const initX = 200
const initY = 200
const margin = 10
function TlDrawDemo() {

    const [editor, setEditor] = useState<undefined | Editor>(undefined)

    const renderLeanCanvas = useCanvasLayout(
        {
            title: 'Lean Canvas',
            width,
            h,
            initX,
            initY,
            margin,
            labels: [
                { header: 'Problem', subheader: '' },
                { header: 'Solution', subheader: '' },
                { header: 'Key Metrics', subheader: '' },
                { header: 'Unique Value Proposition', subheader: '' },
                { header: 'Unfair Advantage', subheader: '' },
                { header: 'Channels', subheader: '' },
                { header: 'Customer Segments', subheader: '' },
                { header: 'Cost Structure', subheader: '' },
                { header: 'Revenue Streams', subheader: '' },
            ]
        })

    const renderBusinessModelCanvas = useCanvasLayout({
        title: 'Business Model Canvas',
        h,
        width,
        initX: initX + 6 * width + margin * 3,
        initY: initY,
        margin,
        labels: [
            { header: 'Customer Relationships', subheader: '' },
            { header: 'Customer Segments', subheader: '' },
            { header: 'Key Partners', subheader: '' },
            { header: 'Key Activities', subheader: '' },
            { header: 'Key Resources', subheader: '' },
            { header: 'Cost Structure', subheader: '' },
            { header: 'Revenue Streams', subheader: '' },
            { header: 'Channels', subheader: '' },
            { header: 'Value Proposition', subheader: '' },
        ]
    })

    const renderMetrics = useMetricsLayout({
        title: 'Metrics',
        h,
        width,
        initX: initX,
        initY: width * 10 + margin,
        margin,
        labels: [

        ]

    })

    const renderSuccessDef = useSuccessDef({
        title: 'Success Definition',
        h,
        width,
        initX: initX,
        initY: 230 * 15 + margin,
        margin,
        labels: [
        ]
    })


    const renderContent = useCallback((e: Editor) => {
        const bounds = renderLeanCanvas(e)
        renderBusinessModelCanvas(e)
        renderMetrics(e)
        renderSuccessDef(e)
    }, [renderBusinessModelCanvas, renderLeanCanvas])

    const isSet = React.useRef(false)
    const handleOnMount = useCallback((e?: Editor) => {
        if (e && isSet.current === false) {
            setEditor(e);
            // e.store.loadSnapshot(jsonSnapshot);
            (window as any).editor = e
            renderContent(e)
            console.log(e.store.getSnapshot('all'))
            isSet.current = true
        }
    }, [renderContent])

    return (

        <div style={{
            position: 'fixed',
            inset: 0,
            marginLeft: 280,
            marginTop: 60
        }}>
            <Tldraw
                shapeUtils={customShape}
                onMount={handleOnMount}
            >
            </Tldraw>
        </div>
    );
}

export default TlDrawDemo;

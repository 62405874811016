import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as teamReducer } from 'src/slices/team';
import { reducer as projectReducer } from 'src/slices/project';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as templateReducer } from 'src/slices/template';
import { reducer as canvasReducer } from 'src/slices/canvas';
import { reducer as overviewReducer } from 'src/slices/overview';
import { reducer as connectionReducer } from 'src/slices/connection-status';


export const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  team: teamReducer,
  project: projectReducer,
  user: userReducer,
  template: templateReducer,
  canvas: canvasReducer,
  overview: overviewReducer,
  connection: connectionReducer
});

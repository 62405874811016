
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { MouseEventHandler, useCallback, useState } from 'react';

import { FC } from 'react';
type Props = {
  title,
  content,
  onConfirm,
  onCancel,
  onButtonClick?: (event: MouseEventHandler<HTMLButtonElement> | undefined) => void,
  // buttonProps?: any
  component: any
  actionProps?: {
    confirmButtonProps?: any,
    cancelButtonProps?: any
  }
  actionLabels?: {
    confirmButtonLabel?: string,
    cancelButtonLabel?: string
  }
}

const ClickableButton = ({ component: Component, ...props }) => {
  return <Component {...props} />;
}

const ConfirmPopover: FC<Props> = ({
  title,
  content,
  onConfirm,
  onCancel,
  component,//: Component,
  actionLabels,
  onButtonClick,
  actionProps
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event: MouseEventHandler<HTMLButtonElement> | undefined) => {
    onButtonClick?.(event)
    setOpen(true);
  }, [onButtonClick])

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen])

  const handleConfirm = useCallback(() => {
    handleClose();
    onConfirm();
  }, [onConfirm, handleClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    onCancel();
  }, [onCancel, handleClose])

  const popoverContent = (
    <div style={{ padding: '10px' }}>
      {content}
    </div>
  );
  return (
    <>
      {/* <Component
        // component={Component}
        // onClick={handleClick} />
        onClick={() => console.log("CKUCKED")} /> */}
      {component(handleClick.bind(this))}
      {title}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 999999999999999999
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          {popoverContent}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            {...actionProps?.cancelButtonProps}
          >
            {actionLabels?.cancelButtonLabel || "Cancel"}
          </Button>
          <Button onClick={handleConfirm}
            color="primary"
            {...actionProps?.confirmButtonProps}
          >
            {actionLabels?.confirmButtonLabel || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmPopover;
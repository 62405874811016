
import { Button, Stack } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { useEffect, useState } from 'react';
import { Droppable } from "react-beautiful-dnd";
import { CanvasTile } from 'src/API';
import { useDispatch, useSelector } from 'src/store';
import { tryParseJsonArray } from 'src/utils/try-parse-json';
import { v4 as uuidV4 } from 'uuid';
import { slice as canvasSlice } from "../../slices/canvas";
import CanvasTileBoxHeader from './canvas-tile-box-header';
import CanvasTileTask from './canvas-tile-task';



export type Task = {
    id: string,
    description: string,
    updatedAt: string
}

const useCanvasTile = (tileId: string): { tasks?: Task[] | null, canvasTile?: null | CanvasTile } => {
    const [id, setId] = useState(tileId)
    const canvasTile = useSelector(state => state.canvas.canvasTiles.byId[id]);
    const [tasks, setTasks] = useState<Task[] | null>(tryParseJsonArray(canvasTile?.tasks))

    useEffect(() => {
        setId(tileId)
    }, [tileId])

    useEffect(() => {
        setTasks(tryParseJsonArray(canvasTile?.tasks))
    }, [canvasTile?.tasks])

    return {
        tasks,
        canvasTile
    }
}

export default function CanvasTileBox({
    canvasTileId,
    permissions,
    popper
}: {
    canvasTileId: string
    permissions,
    popper
}) {
    const { tasks, canvasTile } = useCanvasTile(canvasTileId)
    const dispatch = useDispatch()
    const handleNewTask = () => {
        if (!canvasTile) return
        dispatch(canvasSlice.actions.createCanvasTask({
            id: canvasTile.id,
            tasks: [{
                id: uuidV4(),
                description: "",
                updatedAt: new Date().toISOString(),
            }]
        }))
    }
    if (!canvasTile) return null
    return (
        <>
            <Stack
                style={{ maxHeight: "100%" }}
            >
                <CanvasTileBoxHeader canvasTile={canvasTile}></CanvasTileBoxHeader>
                <div style={{ overflow: 'auto' }}>
                    <Droppable droppableId={canvasTile.id}
                        type="bucket"
                        isDropDisabled={!permissions.canEdit}
                        direction="vertical">
                        {(provided, snapshot) => {
                            return (
                                <div {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ minHeight: 5, }}
                                >
                                    {tasks && tasks.map((task, i) => {
                                        return <CanvasTileTask
                                            index={i}
                                            {...provided}
                                            popper={popper}
                                            tile={canvasTile}
                                            permissions={permissions}
                                            onNewTask={handleNewTask}
                                            editMode={permissions.canEdit}
                                            key={task.id + task.updatedAt}
                                            task={task}></CanvasTileTask>
                                    })}
                                    {provided.placeholder}
                                </div>
                            )

                        }}
                    </Droppable>
                    {
                        permissions.canEdit && <Button
                            fullWidth
                            startIcon={<PlusIcon></PlusIcon>}
                            onClick={handleNewTask}
                        >Add</Button>
                    }
                </div>

            </Stack>
        </>
    )
}

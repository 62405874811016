export function tryParseJsonArray(json?: string | null): any[] {
    if (!json) return [];
    try {
        return JSON.parse(json);
    } catch (e) {
        return [];
    }
}

export function tryParseJsonObject(json?: string | null): any {
    if (!json) return {};
    try {
        return JSON.parse(json);
    } catch (e) {
        return {};
    }
}
import { AppThunk } from '../store/index';
import { CreateTemplateRequest, templateApi } from '../api/template/index';
import { slice } from '../slices/template';
import { DeleteTemplateInput, Template, UpdateTemplateInput } from 'src/API';
import { WithRequired } from 'src/slices/kanban';
import toast from 'react-hot-toast';

type GetTemplateParams = {

}

const GetTemplates = (params: GetTemplateParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await templateApi.getTemplates()
    dispatch(slice.actions.getTemplates(response))
}

const updateTemplate = (params: UpdateTemplateInput): AppThunk => async (dispatch): Promise<void> => {
    const response = await templateApi.updateTemplate(params)
    dispatch(slice.actions.updateTemplate(response))
}

const updateTemplates = (params: UpdateTemplateInput[]): AppThunk => async (dispatch): Promise<void> => {
    const response = await templateApi.updateTemplates(params)
    dispatch(slice.actions.updateTemplates(response))
}

const deleteTemplate = (params: DeleteTemplateInput): AppThunk => async (dispatch): Promise<void> => {
    const response = await templateApi.deleteTemplate(params)
    dispatch(slice.actions.deleteTemplate(response))
}

const createTemplate = (params: CreateTemplateRequest): AppThunk => async (dispatch): Promise<void> => {
    const response = await templateApi.createTemplate(params)
    if (!response.ok) {
        toast.error('failed to create template')
    } else {
        toast.success(`Template ${params.name} created!`)
    }
    // dispatch(slice.actions.createTemplate(response))
}

export const thunks = {
    GetTemplate: GetTemplates,
    updateTemplate,
    updateTemplates,
    deleteTemplate,
    createTemplate
} 
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { ListCanvasTilesQueryVariables, ListCanvassQuery, UpdateCanvasInput, UpdateCanvasMutation } from '../../API';
import { listCanvass } from '../../graphql/queries';
import PlanvasBaseApi from '../api';
import { updateCanvas } from 'src/graphql/mutations';
import { CanvasAIResponse } from 'src/pages/canvas/create-ai-canvas/create-ai-canvas-page';


type GetCanvasRequest = ListCanvasTilesQueryVariables//{ nextToken?: string };
type CloneCanvasRequest = { canvasId: string }
type DeleteCanvasRequest = { canvasId: string }
export type CreateCanvasRequest = { name: string, projectId: string, teamId: string, template: string }
// type GetCanvasResponse = Promise<Canvas[]>;
type ListResponse<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>
class CanvasApi extends PlanvasBaseApi {
    canvasEndpoint = "/canvas"

    async getCanvases(request?: GetCanvasRequest): Promise<ListResponse<ListCanvassQuery>> {
        const response = await API.graphql<GraphQLQuery<ListCanvassQuery>>(graphqlOperation(listCanvass, {
            ...request
        }));

        if (response.data?.listCanvass?.nextToken) {
            const nextResponse = await this.getCanvases({ ...request, nextToken: response.data.listCanvass.nextToken });
            response.data.listCanvass.items = response.data.listCanvass.items.concat(nextResponse?.data?.listCanvass?.items || []);
        }

        return response;
    }

    async cloneCanvas(request: CloneCanvasRequest): Promise<any> {
        return super.post(this.canvasEndpoint + "/clone", request)
    }

    async deleteCanvas(request: DeleteCanvasRequest): Promise<any> {
        return super.delete(this.canvasEndpoint, request)
    }

    async create(request: { name: string, projectId: string, teamId: string, template: string }) {
        return super.post(this.canvasEndpoint, request)
    }

    async getAssistedCanvas(request: { templateId: string, teamId: string }) {
        return super.get(this.canvasEndpoint + "/assisted", { templateId: request.templateId, teamId: request.teamId })
    }

    async createAssistedCanvas(request: { name: string, projectId: string, teamId: string, templateId: string, assistedCanvas: CanvasAIResponse[] }) {
        return super.post(this.canvasEndpoint + "/assisted", request)
    }

    async downloadCanvas(canvasId: string) {
        return super.getBlob(this.canvasEndpoint + "/download/" + canvasId, {})
    }

    async updateCanvas(request: UpdateCanvasInput) {
        return API.graphql(graphqlOperation(updateCanvas, { input: request }))
    }
}

export const canvasApi = new CanvasApi();

import { CanvasLayoutConfig, IShape } from "./getCanvasLayout"


interface RowsConfig {
    columns: {
        header: string
        subheader: string
    }[]
    rows:
    {
        name: string
        values: string[]
    }[]

}

export function getSuccessDefLayout(config: CanvasLayoutConfig): IShape[] {
    // const initX = 10;
    // const initY = 10;
    const { initX, initY } = config;
    const cellWidth = 100;
    const cellHeight = 100;
    const margin = 5;

    const headers = ['Metrics / Time', 'Current', '6m', '18m', '36m'];
    const rows = ['EBITA', 'Revenue', 'Company Value'];

    let shapes: IShape[] = [];

    // Create header cells
    headers.forEach((header, index) => {
        shapes.push({
            x: initX + index * (cellWidth + margin),
            y: initY,
            w: cellWidth,
            h: cellHeight,
            text: header,
            fill: 'lightgrey', // optional: color for headers
            isFilled:true
        });
    });

    // Create data cells
    rows.forEach((row, rowIndex) => {
        shapes.push({
            x: initX,
            y: initY + (rowIndex + 1) * (cellHeight + margin),
            w: cellWidth,
            h: cellHeight,
            text: row,
            isFilled: true
        });

        // Assuming dummy financial data to fill the cells
        [120000, 130000, 150000, 180000].forEach((value, colIndex) => {
            shapes.push({
                isFilled: true,
                x: initX + (colIndex + 1) * (cellWidth + margin),
                y: initY + (rowIndex + 1) * (cellHeight + margin),
                w: cellWidth,
                h: cellHeight,
                text: `$${value.toLocaleString()}` // Formatting numbers as currency
            });
        });
    });

    return shapes;
}
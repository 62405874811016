import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FaIcon({ icon, ...props }) {
    return (
        <div title={props.title}
            {...props}
        >
            <FontAwesomeIcon
                icon={icon}

            />
        </div>
    )
}

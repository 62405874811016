import { CloudUploadRounded } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { organizationApi } from 'src/api/organizations';
import { QuillEditor } from 'src/components/quill-editor';
import { useOrganization } from 'src/pages/settings/organization';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/organization';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function OrganizationProfile() {
    const o = useOrganization()
    const dispatch = useDispatch()
    const [hasChanged, setHasChanged] = useState(Math.random())
    const [description, setDescription] = useState(o?.description)

    useEffect(() => {
        setDescription(o?.description)

        return () => { }
    }, [o])


    const handleSaveClick = useCallback(async () => {
        console.log(o)
        if (!o) return
        if (!o.id) return
        if (!description) return
        console.log("dispatching")
        dispatch(thunks.updateOrganization({
            id: o.id,
            description
        }))

    }, [description, dispatch, o])

    const onChange = useCallback(async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        if (!o) return
        const res = await organizationApi.uploadIcon(formData, o.id)
        setHasChanged(Math.random())
    }, [o])

    return (
        <div style={{ overflow: 'auto' }}>
            <Stack
                spacing={4}
                p={4}
            >
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <Typography gutterBottom
                                variant="h5"
                                component="div">
                                {o?.name} logo
                            </Typography>
                            <div>
                                {o?.iconUrl && (
                                    <img src={o?.iconUrl}
                                        alt={o?.name}
                                        key={o.iconUrl + hasChanged}
                                        style={{ height: 100 }} />
                                )}
                            </div>
                            <div>
                                <Button component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadRounded />}>
                                    Change icon
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={onChange} />
                                </Button>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography gutterBottom
                            variant="h5"
                            component="div">
                            Information
                        </Typography>
                        <QuillEditor
                            style={{ minHeight: 400 }}
                            value={description}
                            onChange={(value) => setDescription(value)}
                        ></QuillEditor>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSaveClick}
                            >
                                Save
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </Stack >
        </div>
    )
}

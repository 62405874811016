import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Project } from '../API';
import { Canvas } from '../types/canvas';
import { UpdateProjectRequest } from '../api/project/index';
import { slice as teamSlice } from 'src/slices/team';
import logger from 'src/log/log';


type RequestState = {
    success: boolean,
    error?: string
}

type CreateState = RequestState
type DeleteState = RequestState


type DeleteCanvasAction = PayloadAction<{ canvasId }>;

export interface ProjectState {
    project?: Project
    create?: CreateState
    delete?: DeleteState
    currentId?: string
}

export interface SetProjectState extends ProjectState {
    isInitial: boolean
}

type GetProjectAction = PayloadAction<Project> | undefined;
type CreateProjectAction = PayloadAction<CreateState> | undefined
type DeleteProjectAction = PayloadAction<DeleteState> | undefined
type UpdateProjectAction = PayloadAction<UpdateProjectRequest> | undefined
type SetProjectAction = PayloadAction<SetProjectState> | undefined
const initialState: ProjectState = {
    project: undefined,
    currentId: undefined
};

const reducers = {
    getProject(state: ProjectState, action: GetProjectAction): void {
        if (!action) return
        
        // state = { ...state, project: action.payload, currentId: action.payload.id }
        state.project = action.payload
        state.currentId = action.payload.id

    },
    createProject(state: ProjectState, action: CreateProjectAction): void {
        if (!action) return
        state.create = action.payload
    },
    updateProject(state: ProjectState, action: UpdateProjectAction): void {
        teamSlice.actions.updateProjectInTeam({
            updatedProject: {
                ...action?.payload
            }
        })
    },
    deleteProject(state: ProjectState, action: DeleteProjectAction): void {
        if (!action) return
        state.delete = action.payload
    },
    setCurrentProject(state: ProjectState, action: SetProjectAction): void {
        // console.log("setCurrentProject", action)
        if (!action) return
        if (!action.payload.project) {
            logger.debug("No project in slice")
            return
        }

        state.project = action.payload.project
        state.currentId = action.payload.project.id

    },
    deleteCanvas(state: ProjectState, action: DeleteCanvasAction): void {
        if (!state.project?.canvases?.items) return
        const filteredCanvases = state.project?.canvases?.items.filter((canvas: Canvas) => canvas?.id !== action.payload.canvasId);
        state.project.canvases.items = filteredCanvases
    },
    // deleteBucket(state: ProjectState, action: PayloadAction<{ bucketId?}>) {
    //     console.log("state.project", state.project, action.payload)
    //     // state.project?.buckets.map(b=>b.name)
    //     // state.project?.buckets = state.project?.buckets?.filter(b=>b.id!== action.payload.bucketId)

    // }
};

export const slice = createSlice({
    name: 'Project',
    initialState,
    reducers
});

export const { reducer } = slice;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCanvas = /* GraphQL */ `
  query GetCanvas($id: ID!) {
    getCanvas(id: $id) {
      id
      name
      lowercaseName
      teamId
      canvasTiles {
        items {
          id
          canvasId
          tasks
          teamId
          message
          position
          title
          createdAt
          updatedAt
          updatedBy
          info
          owner
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listCanvass = /* GraphQL */ `
  query ListCanvass(
    $filter: ModelCanvasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanvass(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lowercaseName
        teamId
        canvasTiles {
          nextToken
          __typename
        }
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        createdAt
        updatedAt
        owner
        channelId
        properties
        ownerEmail
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCanvasTile = /* GraphQL */ `
  query GetCanvasTile($id: ID!) {
    getCanvasTile(id: $id) {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listCanvasTiles = /* GraphQL */ `
  query ListCanvasTiles(
    $filter: ModelCanvasTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanvasTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        canvasId
        tasks
        teamId
        message
        position
        title
        createdAt
        updatedAt
        updatedBy
        info
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      description
      tiles
      sequence
      createdAt
      aiEnabled
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tiles
        sequence
        createdAt
        aiEnabled
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        experiment
        sequence
        teamId
        bucket
        progress
        priority
        startDate
        dueDate
        notes
        checkList
        attachments
        comments
        completed
        assigned
        updatedBy
        createdBy
        createdAt
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      projects {
        items {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          nextToken
          __typename
        }
        createdAt
        fileUrl
        updatedAt
        owner
        teamFlags
        description
        teamSize
        phoneNumber
        valueProposition
        address
        color
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamGroup = /* GraphQL */ `
  query GetTeamGroup($id: ID!) {
    getTeamGroup(id: $id) {
      id
      name
      teamIds
      managerGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeamGroups = /* GraphQL */ `
  query ListTeamGroups(
    $filter: ModelTeamGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        teamIds
        managerGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      lowercaseName
      teamId
      team {
        id
        name
        projects {
          nextToken
          __typename
        }
        createdAt
        fileUrl
        updatedAt
        owner
        teamFlags
        description
        teamSize
        phoneNumber
        valueProposition
        address
        color
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      canvases {
        items {
          id
          name
          lowercaseName
          teamId
          projectId
          createdAt
          updatedAt
          owner
          channelId
          properties
          ownerEmail
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitedUser = /* GraphQL */ `
  query GetInvitedUser($id: ID!) {
    getInvitedUser(id: $id) {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvitedUsers = /* GraphQL */ `
  query ListInvitedUsers(
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        teamId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        channelId
        content
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        members
        organizations
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageChannel = /* GraphQL */ `
  query GetMessageChannel($id: ID!) {
    getMessageChannel(id: $id) {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      messages {
        items {
          id
          type
          channelId
          content
          createdBy
          createdAt
          updatedAt
          updatedBy
          owner
          members
          organizations
          __typename
        }
        nextToken
        __typename
      }
      owner
      permissionHash
      __typename
    }
  }
`;
export const listMessageChannels = /* GraphQL */ `
  query ListMessageChannels(
    $filter: ModelMessageChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members
        organizations
        entity
        entityType
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        owner
        permissionHash
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        size
        type
        teamId
        parentFolders
        searchKey
        shareSearchKey
        relativePath
        organizationId
        treeId
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      role
      description
      ownerRole
      iconUrl
      properties
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        role
        description
        ownerRole
        iconUrl
        properties
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      displayName
      avatar
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        displayName
        avatar
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const canvasByProject = /* GraphQL */ `
  query CanvasByProject(
    $projectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCanvasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    canvasByProject(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lowercaseName
        teamId
        canvasTiles {
          nextToken
          __typename
        }
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        createdAt
        updatedAt
        owner
        channelId
        properties
        ownerEmail
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const canvasTilesByCanvas = /* GraphQL */ `
  query CanvasTilesByCanvas(
    $canvasId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCanvasTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    canvasTilesByCanvas(
      canvasId: $canvasId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        canvasId
        tasks
        teamId
        message
        position
        title
        createdAt
        updatedAt
        updatedBy
        info
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const canvasTilesByTeam = /* GraphQL */ `
  query CanvasTilesByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCanvasTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    canvasTilesByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        canvasId
        tasks
        teamId
        message
        position
        title
        createdAt
        updatedAt
        updatedBy
        info
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taskByProjectAndUpdatedAt = /* GraphQL */ `
  query TaskByProjectAndUpdatedAt(
    $projectId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskByProjectAndUpdatedAt(
      projectId: $projectId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        experiment
        sequence
        teamId
        bucket
        progress
        priority
        startDate
        dueDate
        notes
        checkList
        attachments
        comments
        completed
        assigned
        updatedBy
        createdBy
        createdAt
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taskByProject = /* GraphQL */ `
  query TaskByProject(
    $projectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskByProject(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        experiment
        sequence
        teamId
        bucket
        progress
        priority
        startDate
        dueDate
        notes
        checkList
        attachments
        comments
        completed
        assigned
        updatedBy
        createdBy
        createdAt
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taskByTeam = /* GraphQL */ `
  query TaskByTeam(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        projectId
        project {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        experiment
        sequence
        teamId
        bucket
        progress
        priority
        startDate
        dueDate
        notes
        checkList
        attachments
        comments
        completed
        assigned
        updatedBy
        createdBy
        createdAt
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectByTeam = /* GraphQL */ `
  query ProjectByTeam(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invitedUserByTeam = /* GraphQL */ `
  query InvitedUserByTeam(
    $teamId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitedUserByTeam(
      teamId: $teamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        teamId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messageByChannel = /* GraphQL */ `
  query MessageByChannel(
    $channelId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByChannel(
      channelId: $channelId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        channelId
        content
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        members
        organizations
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byPermissionHash = /* GraphQL */ `
  query ByPermissionHash(
    $permissionHash: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPermissionHash(
      permissionHash: $permissionHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        members
        organizations
        entity
        entityType
        createdAt
        updatedAt
        messages {
          nextToken
          __typename
        }
        owner
        permissionHash
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const filesByTeam = /* GraphQL */ `
  query FilesByTeam(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    filesByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        size
        type
        teamId
        parentFolders
        searchKey
        shareSearchKey
        relativePath
        organizationId
        treeId
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const filesBySearchKey = /* GraphQL */ `
  query FilesBySearchKey(
    $teamId: ID
    $searchKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    filesBySearchKey(
      teamId: $teamId
      searchKey: $searchKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        size
        type
        teamId
        parentFolders
        searchKey
        shareSearchKey
        relativePath
        organizationId
        treeId
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const OrgFilesBySearchKey = /* GraphQL */ `
  query OrgFilesBySearchKey(
    $organizationId: String
    $searchKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgFilesBySearchKey(
      organizationId: $organizationId
      searchKey: $searchKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        size
        type
        teamId
        parentFolders
        searchKey
        shareSearchKey
        relativePath
        organizationId
        treeId
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fileByOwnerAndTreeId = /* GraphQL */ `
  query FileByOwnerAndTreeId(
    $owner: String
    $treeId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByOwnerAndTreeId(
      owner: $owner
      treeId: $treeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        size
        type
        teamId
        parentFolders
        searchKey
        shareSearchKey
        relativePath
        organizationId
        treeId
        createdBy
        createdAt
        updatedAt
        updatedBy
        owner
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      nextToken
      __typename
    }
  }
`;

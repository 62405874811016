import { Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import PropTypes from 'prop-types';
import type { FC } from 'react';
interface WelcomeStepProps {
    onNext?: () => void;
    onBack?: () => void;
}

export const WelcomeStep: FC<WelcomeStepProps> = (props) => {
    const { onBack, onNext, ...other } = props;


    return (
        <Stack
            spacing={3}
            {...other}
        >
            <Stack spacing={2}>
                <Card
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        p: 2,
                        // ...(category === option.value && {
                        //     backgroundColor: 'primary.alpha12',
                        //     boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`
                        // })
                    }}
                >
                    <CardContent>

                        <Stack spacing={1}>
                            <Typography variant='subtitle1'>Welcome to Planvas!</Typography>
                            <Typography
                                sx={{ wordBreak: 'break-word' }}
                            >
                                We're thrilled to have you and your team on board, ready to embark on an exciting journey of turning your business idea into a reality. Our mission is to empower you with the tools and resources you need to succeed in the competitive world of business.<br /><br />

                                With Planvas, you'll find a supportive platform that fosters collaboration, innovation, and creativity. We believe in the power of teamwork and the impact of great ideas. Together, we can make a difference and create something truly remarkable.<br /><br />

                                As you begin this transformative process, we encourage you to share your passion, vision, and expertise. Your unique insights will shape the way our application guides you through the various stages of business development.<br /><br />

                                Whether you're an experienced entrepreneur or just starting, rest assured that you have a dedicated team here to assist you every step of the way. We're committed to providing you with a seamless and enriching onboarding experience.<br /><br />

                                Now, let's dive in and start shaping your business idea into a well-defined plan. Together, we'll explore, strategize, and achieve remarkable milestones.<br /><br />

                                Thank you for choosing Planvas. We can't wait to see your dreams take flight!
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <div>
                <Button
                    endIcon={(
                        <SvgIcon>
                            <ArrowRightIcon />
                        </SvgIcon>
                    )}
                    onClick={onNext}
                    variant="contained"
                >
                    Next
                </Button>
            </div>
        </Stack>
    );
};

WelcomeStep.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

import { Button, Dialog, DialogActions, DialogContent, Paper, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Stack } from "@mui/system";
import { useFormik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { QuillEditor } from "src/components/quill-editor";
import { DialogController } from "src/hooks/use-dialog";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/template";
import * as Yup from 'yup';
import { CanvasTemplateDialogData } from ".";

type CanvasTemplateDialogProps = {
    dialog: DialogController<CanvasTemplateDialogData>
}

const CanvasTemplateDialog: FC<CanvasTemplateDialogProps> = (props) => {
    const { dialog } = props;
    const dispatch = useDispatch()
    const [name, setName] = useState(dialog.data?.canvas.name || '')
    const [description, setDescription] = useState('')

    const formik = useFormik({
        initialValues: {},
        onSubmit: () => { },
        validationSchema: {
            name: Yup.string(),
            description: Yup.string(),

        },
    })

    const handleCreateTemplate = useCallback(() => {
        if (!name || !description || !dialog.data?.canvas) return;

        dispatch(thunks.createTemplate({
            name,
            description,
            canvasId: dialog.data?.canvas.id
        }))
        dialog.handleClose()

    }, [dispatch, name, description, dialog])

    return (
        <Dialog
            open={dialog.open}
        >
            <DialogContent>
                <Stack
                    direction={'column'}
                    spacing={2}
                >
                    <Typography variant="h6">Canvas template</Typography>

                    <Paper
                        sx={{
                            p: 2,
                            backgroundColor: grey[100]
                        }}

                    >
                        <Typography variant="body1">The Canvas template streamlines the process of crafting canvases. It duplicates your current canvas, enabling teams to utilize it as a blueprint when establishing new canvases.</Typography>
                        <br></br>
                        <Typography variant="caption">The Canvas template settings can be found under <i>Administration</i> if you need to change this.</Typography>
                    </Paper>
                    <TextField
                        placeholder="Name of the canvas template"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        autoFocus
                        label="Canvas template name"
                    ></TextField>

                    <QuillEditor
                        value={description}
                        onChange={(e) => setDescription(e)}
                        placeholder="Write a description of your canvas template here..."
                    ></QuillEditor>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={dialog.handleClose}>Close</Button>
                <Button onClick={handleCreateTemplate}>Create template</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CanvasTemplateDialog;
import Axios, { AxiosRequestConfig } from "axios";
import { getBackendUrl } from '../config';
import $log from "../log/log";
// import apiErrorHandler from './ApiDispatchError'
import { getJwtToken } from './auth/index';
import logger from 'src/log/log';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { graphqlOperation } from 'aws-amplify';

const apiErrorHandler = (error: any) => {
    logger.debug("apiErrorHandler", error)
    return error
}
export default class PlanvasBaseApi {
    apiEndpoint = getBackendUrl()

    async recursiveGet<T, T1>(accessor, fn: (request?: T1) => Promise<GraphQLResult<GraphQLQuery<T>>>, initialPayload) {
        const response = await fn(initialPayload)
        if (response.data?.[accessor]?.nextToken) {
            const nextResponse = await fn({ ...initialPayload, nextToken: response.data[accessor].nextToken });
            response.data[accessor].items = response.data[accessor].items.concat(nextResponse?.data?.[accessor]?.items || []);
        }
        return response as GraphQLResult<GraphQLQuery<T>>

    }

    private async getToken(): Promise<string> {
        return getJwtToken()
    }

    private getHeaders(jwtToken: string) {
        return {
            "Authorization": "AWS " + jwtToken,
        }
    }

    onFailed = (err) => {
        // $log.debug("Request failed", { err })
        logger.error(err)
        return apiErrorHandler(err)
    }

    postWithProgress(endpoint, body, headers, onUploadProgress, options?: AxiosRequestConfig) {
        return this.post(endpoint, body, headers, { onUploadProgress, ...options })
    }

    post(endpoint, body, headers = {}, options: AxiosRequestConfig = {}) {
        return this.getToken().then(jwtToken => {
            return Axios({
                method: "POST",
                headers: { ...this.getHeaders(jwtToken), ...headers, },
                data: body,
                url: this.apiEndpoint + endpoint,
                ...options

            }).then(res => apiErrorHandler(res.data)).catch(this.onFailed);
        })
    }

    delete(endpoint, body, options: AxiosRequestConfig = {}): Promise<any | undefined> {
        return this.getToken().then(jwtToken => {
            return Axios({
                method: "DELETE",
                headers: this.getHeaders(jwtToken),
                data: body,
                url: this.apiEndpoint + endpoint,
                ...options

            }).then(res => apiErrorHandler(res.data)).catch(this.onFailed);
        })
    }

    get(endpoint, queryStringParameters = {}, options: AxiosRequestConfig = {}) {
        return this.getToken().then(jwtToken => {
            return Axios({
                method: "GET",
                headers: this.getHeaders(jwtToken),
                params: queryStringParameters,
                url: this.apiEndpoint + endpoint,
                ...options
            }).then(res => apiErrorHandler(res.data)).catch(this.onFailed);
        })
    }

    getBlob(endpoint, queryStringParameters, options: AxiosRequestConfig = {}) {
        return this.getToken().then(jwtToken => {

            return Axios({
                method: "GET",
                headers: this.getHeaders(jwtToken),
                params: queryStringParameters,
                url: this.apiEndpoint + endpoint,
                responseType: "blob",
                ...options
            }).then(res => apiErrorHandler(res.data)).catch(this.onFailed);
        })
    }

    put(endpoint, body, options: AxiosRequestConfig = {}) {
        return this.getToken().then(jwtToken => {
            return Axios({
                method: 'PUT',
                headers: this.getHeaders(jwtToken),
                data: body,
                url: this.apiEndpoint + endpoint,
                ...options
            }).then(res => apiErrorHandler(res.data)).catch(this.onFailed);
        })
    }

}
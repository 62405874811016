import { Organization } from 'src/API';
import { chatApi } from 'src/api/chat';
import { organizationApi } from 'src/api/organizations';
import { teamApi } from 'src/api/team';
import { userApi } from 'src/api/user';
import { slice } from 'src/slices/chat';
import type { AppThunk } from 'src/store';


const getMessagesByThread = (threadId: string, nextToken?: string): AppThunk => async (dispatch, getState) => {
  const response = await chatApi.getMessagesForThread(threadId, nextToken);
  dispatch(slice.actions.getMessagesByThread(response))
}

const getContacts = ({ teamId }): AppThunk => async (dispatch): Promise<void> => {
  // const response = await chatApi.getContacts({});
  const organization: Organization[] = await organizationApi.getOrganizations()
  const users = await teamApi.getTeamUsers(teamId)

  dispatch(slice.actions.getContacts({ organization, users }));
};

const getThreads = (): AppThunk => async (dispatch): Promise<void> => {
  const response = await chatApi.getThreads();
  dispatch(slice.actions.getThreads(response));
};

type GetThreadParams = {
  threadKey: string;
};

const getThread = (params: GetThreadParams): AppThunk => async (dispatch): Promise<string | undefined> => {
  console.log('getThread')
  const response = await chatApi.getThread(params);

  dispatch(slice.actions.getThread(response));

  return response?.id;
};

type MarkThreadAsSeenParams = {
  threadId: string;
}

const markThreadAsSeen = (params: MarkThreadAsSeenParams): AppThunk => async (dispatch): Promise<void> => {
  await chatApi.markThreadAsSeen(params);

  dispatch(slice.actions.markThreadAsSeen(params.threadId));
};

type SetCurrentThreadParams = {
  threadId?: string;
};

const setCurrentThread = (params: SetCurrentThreadParams): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setCurrentThread(params.threadId));
};

type AddMessageParams = {
  threadId?: string;
  recipientIds?: string[];
  body: string;
}

const addMessage = (params: AddMessageParams): AppThunk => async (dispatch): Promise<string | undefined> => {
  const response = await chatApi.addMessage(params);

  // dispatch(slice.actions.addMessage(response));

  return response?.threadId;
};


export const thunks = {
  addMessage,
  getContacts,
  getThread,
  getThreads,
  markThreadAsSeen,
  setCurrentThread,
  getMessagesByThread
};

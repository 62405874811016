import {
  Box,
  Button,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { UpdateUserInput, User } from 'src/API';
import { UserAvatar } from 'src/components/user-avatar';
import { useDialog } from 'src/hooks/use-dialog';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/user';
import * as Yup from 'yup';
import { UpdateType } from '../../../api/project/index';
import { AvatarGenerator } from './avatar-generator';


interface AccountGeneralSettingsProps {
  avatar: string;
  email: string;
  name: string;
  userid: string
}

const validationSchema = Yup.object({
  displayName: Yup.string().max(255)
})

interface Values {
  displayName: string;
}

const getInitialValues = (displayName?: string): Values => ({
  displayName: displayName || ''
})

export const AccountGeneralSettings: FC<AccountGeneralSettingsProps> = (props) => {
  const { userid, avatar, email, name } = props;
  const dispatch = useDispatch()
  const dialog = useDialog()
  const navigate = useNavigate()

  const [tar, setAvatar] = useState(avatar)

  const updateUser = useCallback((data: UpdateUserInput) => {
    dispatch(thunks.updateUser(data))
  }, [dispatch,])

  const createNewUser = useCallback((user) => {
    dispatch(thunks.createUser(user))
  }, [dispatch])

  const [searchParams, setSearchParams] = useSearchParams()
  const isNew = searchParams.get('new') === 'true'

  const formik = useFormik({
    initialValues: getInitialValues(name),
    validationSchema,
    onSubmit: (values: UpdateType<User>) => {
      if (!userid) {
        console.error('userid is required')
        return
      }
      if (isNew) {
        const newUser = { id: userid, ...values, avatar: JSON.stringify(tar) }
        createNewUser(newUser)
        setSearchParams({ new: 'false' })

      } else {
        console.log("updateUser", values)
        updateUser({ id: userid, ...values })
      }

    },
  })

  const handleAvatarSave = (avatarConfig) => {
    dialog.handleClose()
    setAvatar(avatarConfig)
    if (!isNew) {
      dispatch(thunks.getAccount(userid))
      updateUser({ id: userid, avatar: JSON.stringify(avatarConfig) })
    }
  }


  return (
    <Stack
      spacing={4}
      {...props}
    >
      {
        isNew && (
          <Card>
            <CardContent>
              <Stack direction={'column'}
                spacing={2}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >

                <Typography variant="h6"
                  color={'error'}
                  sx={{ mb: 2 }}>
                  We are missing some details from you.
                </Typography>
                <Typography variant="subtitle2"
                  sx={{ mb: 2 }}>
                  Fill in your name and Save.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        )
      }
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant="h6">
                Basic details
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack spacing={3}>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <Box
                    sx={{
                      borderColor: 'neutral.300',
                      borderRadius: '50%',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      p: '4px'
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                      }}
                    >
                      {/* <Box
                        sx={{
                          alignItems: 'center',
                          backgroundColor: (theme) => alpha(theme.palette.neutral[700], 0.5),
                          borderRadius: '50%',
                          color: 'common.white',
                          cursor: 'pointer',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          left: 0,
                          opacity: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          zIndex: 1,
                          '&:hover': {
                            opacity: 1
                          }
                        }}
                      >
                        <Stack
                          alignItems="center"
                          direction="row"
                          spacing={1}
                        >
                          <SvgIcon color="inherit">
                            <Camera01Icon />
                          </SvgIcon>
                          <Typography
                            color="inherit"
                            variant="subtitle2"
                            sx={{ fontWeight: 700 }}
                          >
                            Select
                          </Typography>
                        </Stack>
                      </Box> */}
                      <UserAvatar
                        avatarStyle={{ width: 100, height: 100 }}
                        currentUser
                        config={tar}
                      ></UserAvatar>

                      {/* <Avatar
                        src={avatar}
                        sx={{
                          height: 100,
                          width: 100
                        }}
                      >
                        <SvgIcon>
                          <User01Icon />
                        </SvgIcon>
                      </Avatar> */}
                    </Box>
                  </Box>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={dialog.handleOpen}
                  >
                    Change
                  </Button>
                  <AvatarGenerator
                    onSave={handleAvatarSave}
                    onClose={dialog.handleClose}
                    open={dialog.open}
                    config={avatar}
                  ></AvatarGenerator>
                </Stack>
                <form
                  onSubmit={formik.handleSubmit}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >

                    <TextField
                      defaultValue={formik.values.displayName}
                      label="Full Name"
                      name="displayName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.displayName && formik.errors.displayName}
                      error={Boolean(formik.touched.displayName && formik.errors.displayName)}
                      sx={{ flexGrow: 1 }}
                    />
                    <Button
                      color="inherit"
                      size="small"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Stack>
                </form>

                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <TextField
                    defaultValue={email}
                    disabled
                    label="Email Address"
                    required
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                  />
                  {/* <Button
                    color="inherit"
                    size="small"
                  >
                    Edit
                  </Button> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant="h6">
                Public profile
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={8}
            >
              <Stack
                divider={<Divider />}
                spacing={3}
              >
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">
                      Make Contact Info Public
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      Means that anyone viewing your profile will be able to see your contacts
                      details.
                    </Typography>
                  </Stack>
                  <Switch />
                </Stack>
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">
                      Available to hire
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      Toggling this will let your teammates know that you are available for
                      acquiring new projects.
                    </Typography>
                  </Stack>
                  <Switch defaultChecked />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}


      {/** TO BE ADDED - DELETE ACCOUNT */}
      {/** TO BE ADDED - DELETE ACCOUNT */}
      {/** TO BE ADDED - DELETE ACCOUNT */}

      {/* <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant="h6">
                Delete Account
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack
                alignItems="flex-start"
                spacing={3}
              >
                <Typography variant="subtitle1">
                  Delete your account and all of your source data. This is irreversible.
                </Typography>
                <Button
                  color="error"
                  variant="outlined"
                >
                  Delete account
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
    </Stack>
  );
};

AccountGeneralSettings.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

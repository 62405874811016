import { Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import VisibilityTableDetached from '../dashboard/visiblity/visibility-table-detached';
interface ShareWithOrganizationStepProps {
    onNext?: () => void;
    onBack?: () => void;
    onRoleChange: (roles: string[]) => void;
    roles: string[];
}

export const ShareWithOrganizationStep: FC<ShareWithOrganizationStepProps> = (props) => {
    const { onBack, onNext, onRoleChange, roles, ...other } = props;


    return (
        <Stack
            spacing={3}
            {...other}
        >
            <Stack spacing={2}>
                <Card
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        p: 2,
                    }}
                >
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography>
                                Here you can share your team with organizations, this will expose canvases and team related data for viewing only for organizations.<br /><br />
                                You can always change this later under Team Settings.
                            </Typography>
                            <VisibilityTableDetached
                                initRoles={roles || []}
                                onRoleChange={onRoleChange}
                            ></VisibilityTableDetached>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <Stack spacing={2}
                direction={'row'}>
                <Button
                    endIcon={(
                        <SvgIcon>
                            <ArrowLeftIcon />
                        </SvgIcon>
                    )}
                    onClick={onBack}
                    variant="contained"
                >
                    Back
                </Button>
                <Button
                    // disabled={!createdTeam}
                    endIcon={(
                        <SvgIcon>
                            <ArrowRightIcon />
                        </SvgIcon>
                    )}
                    onClick={onNext}
                    variant="contained"
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
};

ShareWithOrganizationStep.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

import { ArrowDownwardSharp, ArrowUpwardSharp, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, IconButton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { Template } from "src/API";
import ConfirmPopover from "src/components/confirm-popover";
import { QuillEditor } from "src/components/quill-editor";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/template";
import * as Yup from 'yup';



type CanvasAccordionProps = {
    template: Template
    orderItems: (template: Template, direction: 'up' | 'down') => void
    firstItem: boolean,
    lastItem: boolean
}

type Values = {
    name: string,
    description: string
}
const validationSchema = Yup.object({
    name: Yup.string().min(1).max(255).required(),
    description: Yup.string().min(1).max(1000).required(),
});

export default function TemplateAccordion(props: CanvasAccordionProps) {
    const { template, orderItems, firstItem, lastItem } = props
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: template.name,
            description: template.description
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            console.log('values', values)
            dispatch(thunks.updateTemplate({ id: template.id, name: values.name, description: values.description }))
            setExpanded(false)
        }
    })

    const handleMoveUp = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            orderItems(template, 'up')
        },
        [orderItems, template],
    )

    const handleMoveDown = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            orderItems(template, 'down')
        },
        [orderItems, template],
    )

    const handleDeleteTemplate = useCallback((e) => {
        dispatch(thunks.deleteTemplate({ id: template.id }))
    }, [dispatch, template])

    return (
        <>
            <Accordion
                onChange={e => setExpanded(t => !t)}
                expanded={expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >

                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2}
                        sx={{
                            flex: 'auto'
                        }}
                    >
                        <Box sx={{ marginRight: 'auto' }}>
                            <Typography>
                                {template.name}
                            </Typography>
                        </Box>
                        <Box >
                            {!firstItem && <IconButton
                                onClick={handleMoveUp}
                                color="primary"
                                title='Move Up'
                            >
                                <ArrowUpwardSharp></ArrowUpwardSharp>
                            </IconButton>}
                            {!lastItem && <IconButton
                                onClick={handleMoveDown}
                                color="secondary"
                                title='Move Down'
                            >
                                <ArrowDownwardSharp></ArrowDownwardSharp>
                            </IconButton>
                            }
                            {lastItem && <div style={{ height: 30, width: 80 }}></div>}
                        </Box>
                    </Stack>

                </AccordionSummary>
                <form onSubmit={formik.handleSubmit}>

                    <AccordionDetails>
                        <Stack
                            spacing={2}
                        >
                            <TextField
                                error={!!(formik.touched.name && formik.errors.name)}
                                fullWidth
                                helperText={formik.touched.name && formik.errors.name}
                                label="Template name"
                                name="name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}

                            ></TextField>
                            <Typography>Description</Typography>
                            <QuillEditor
                                onChange={(value: string) => {
                                    formik.setFieldValue('description', value)
                                }}
                                value={formik.values.description || undefined}
                            ></QuillEditor>
                        </Stack>
                    </AccordionDetails>
                    <AccordionActions>

                        <ConfirmPopover
                            title=''
                            onConfirm={handleDeleteTemplate}
                            onCancel={() => {
                            }}
                            component={(handleClick) => <IconButton
                                size="small"
                                color="error"
                                onClick={e => handleClick(e)}
                                title='Delete tile'
                            >
                                <Trash02Icon></Trash02Icon>
                            </IconButton>}
                            content={<Typography>This will delete the <b>template</b> and is irreversible.</Typography>}
                        />
                        <Button style={{ marginLeft: 'auto' }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Save
                        </Button>

                    </AccordionActions>
                </form>

            </Accordion>
        </>

    )
}

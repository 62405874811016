import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { format } from 'date-fns';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Team } from 'src/API';
import { useDispatch, useSelector } from 'src/store';
import { thunks as teamThunk } from 'src/thunks/team';
const parse = require('html-react-parser');



const useTeams = () => {
    const teams = useSelector((state) => state.team.listTeams)
    const dispatch = useDispatch()
    const { teamId } = useParams()

    const fetchTeams = useCallback(() => {
        dispatch(teamThunk.getTeams({ teamId }))
    }, [dispatch, teamId])

    useEffect(() => {
        fetchTeams()
    }, [dispatch, fetchTeams])
    return teams.filter(f => f) as Team[]
}

export default function OrganizationListCanvases() {
    const teams = useTeams()

    const teamMap = useMemo(() => {
        const map = new Map<string, Team>()
        teams.forEach(t => map.set(t.id, t))
        return map
    }, [teams])

    const columns = useMemo<MRT_ColumnDef<Team>[]>(
        () => [
            {
                accessorKey: 'name', //access nested data with dot notation
                header: 'Name',
                size: 150,
                accessorFn: (d) => <a href={`/t/${d.id}`}>{d.name}</a>,
                filterFn: (row, id, filterValue) => {
                    const t = teamMap.get(row.original.id)
                    if (!t) return false
                    return t.name.toLowerCase().includes(filterValue.toLowerCase())
                }
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 150,
                // accessorFn: (d: any) => d.description ? <div dangerouslySetInnerHTML={{ __html: d.description }}></div> : null,
                accessorFn: (d: any) => {
                    if (!d.description) return null
                    const content = parse(d.description)
                    return content
                },
                filterFn: (row, id, filterValue) => {
                    const t = teamMap.get(row.original.id)
                    if (!t) return false
                    return t.description?.toLowerCase().includes(filterValue.toLowerCase()) || false
                },
            },
            {
                accessorKey: 'createdAt', //normal accessorKey
                header: 'Created',
                size: 200,
                accessorFn: d => format(new Date(d.updatedAt as any), 'yyyy-MM-dd HH:mm'),
                enableColumnFilter: false

            },
            {
                accessorKey: 'phoneNumber', //normal accessorKey
                header: 'Phone Number',
                size: 200,
                accessorFn: d => d.phoneNumber,
                filterFn: (row, id, filterValue) => {
                    const t = teamMap.get(row.original.id)
                    if (!t) return false
                    return t.phoneNumber?.toLowerCase().includes(filterValue.toLowerCase()) || false
                }

                // accessorFn: d => format(new Date(d.updatedAt as any), 'yyyy-MM-dd HH:mm'),
            }
        ],
        [teamMap],
    );
    return (
        <Stack
            sx={{
                flexGrow: 1,
                overflow: 'auto',
                // alignItems: 'center',
                // justifyContent: 'center'
            }}
        >
            <Divider></Divider>
            <MaterialReactTable
                enableGlobalFilter={false}
                enableDensityToggle={false}
                columns={columns}
                data={teams} />
        </Stack>)

    // return (
    //     <>
    //         <div>organization-list-canvases</div>
    //         <Table>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>Name</TableCell>
    //                     <TableCell>Description</TableCell>
    //                     <TableCell>Modified</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {canvases.map((canvas) => (
    //                     <TableRow key={canvas.id}>
    //                         <TableCell component="th"
    //                             scope="row">
    //                             {canvas.name}
    //                         </TableCell>
    //                         <TableCell>{canvas.teamId}</TableCell>
    //                         <TableCell>{canvas.updatedAt}</TableCell>
    //                         <TableCell>{canvas.projectId}</TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //             {/* {canvases.filter(f => f).map((canvas: Canvas) => (
    //                 <li key={canvas.id}>{canvas.name}</li>
    //             ))} */}
    //         </Table>
    //     </>

    // )
}

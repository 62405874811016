import { CaseReducer, CaseReducerWithPrepare, PayloadAction } from '@reduxjs/toolkit';
import { success } from '../theme/colors';
import logger from 'src/log/log';

export type CrudStatus<T> = {
    _status: {
        [K in keyof T]?: {
            success?: string | null,
            error?: string | null,
            lastUpdated?: string
            hasFetched?: boolean
        }
    }
};



export const crudSlice: CrudStatus<any> = {
    _status: {}
}




type CrudAction<T> = PayloadAction<{ message?: string | null, attribute: keyof T }>;
export const crudReducer = <T>() => ({
    setError: (state: CrudStatus<T>, action: CrudAction<T>) => {
        const { payload } = action;

        try {
            if (!state?._status?.[action.payload.attribute]) return
            state._status[action.payload.attribute]!.error = payload.message
            state._status[action.payload.attribute]!.lastUpdated = new Date().toISOString()
            state._status[action.payload.attribute]!.hasFetched = true
        } catch (error) {
            console.error(error)
        }
    },
    setSuccess: (state: CrudStatus<T>, action: CrudAction<T>) => {
        const { payload } = action;

        try {
            logger.debug("settings success", state?._status, [action.payload.attribute])
            if (!state?._status?.[action.payload.attribute]) {
                state._status[action.payload.attribute] = { success: null, error: null }
            }

            state._status[action.payload.attribute]!.success = payload.message
            state._status[action.payload.attribute]!.lastUpdated = new Date().toISOString()
            state._status[action.payload.attribute]!.hasFetched = true
        } catch (error) {
            console.error(error)
        }
    },
})

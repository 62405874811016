import { APIClass, GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { updateProject } from 'src/graphql/mutations';
import { GetProjectQuery, Project } from '../../API';
import { getProject } from '../../graphql/queries';
import PlanvasBaseApi from '../api';

type GetProjectRequest = {
    id: string
};

export type CreateProjectRequest = {
    name: string,
    teamId: string
}

export type DeleteProjectRequest = {
    id: string
}

export type UpdateProjectNameRequest = {
    id: string,
    name: string,
}

type P<T> = keyof Omit<T, '__typename'>
export type UpdateType<T> = {
    [key in P<T>]?: T[key]
}

export type UpdateProjectRequest = UpdateType<Project>


type GetResponse<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>

class ProjectApi extends PlanvasBaseApi {
    endpoint = "/project"
    async getProject(request: GetProjectRequest): GetResponse<GetProjectQuery> {
        const response = await API.graphql<GraphQLQuery<GetProjectQuery>>(graphqlOperation(getProject, { id: request.id }));
        return response
    }
    async createProject(request: CreateProjectRequest): Promise<any> {
        return await super.post(this.endpoint, request)
    }

    async deleteProject(request: DeleteProjectRequest) {
        return await super.delete(this.endpoint, request)
    }

    async updateProjectName(request: UpdateProjectNameRequest) {
        return await API.graphql(graphqlOperation(updateProject, { input: { id: request.id, name: request.name } }))
    }
    async updateProject(request: UpdateProjectRequest) {
        if (!request.id) throw new Error('id is required')
        return await API.graphql(graphqlOperation(updateProject, { input: request }))

    }
}
export const projectApi = new ProjectApi()
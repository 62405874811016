import { FC, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'src/hooks/use-auth';

import { useSelector } from 'src/store';

export type Permission = ('admin' | 'editor' | 'viewer' | 'organization')

type TeamPermissionGuardProps = {
    children: any
    permission: Permission[]
}

export type TeamPermissionParam = {
    permissions: Permission[]
}

function getTeamGroups(teamId: string) {
    return {
        admin: teamId + '-admin',
        editor: teamId + '-editor',
        viewer: teamId + '-viewer',
    }
}


export type PermissionGuard = {
    isAdmin: boolean | string,
    isEditor: boolean,
    isViewer: boolean,
    canEdit: boolean,
    canView: boolean,
    isOrganization: boolean,
}

export const usePermissionGuard = ({ permissions: init }: TeamPermissionParam) => {
    const { current: team } = useSelector((state) => state.team)
    const { user } = useAuth()
    const groups = useMemo(() => user?.groups || [], [user])
    const teamGroups = useMemo(() => team ? getTeamGroups(team.id) : {
        admin: 'NOT_APPLICABLE',
        editor: 'NOT_APPLICABLE',
        viewer: 'NOT_APPLICABLE',
    }, [team])
    const [permissions, setPermissions] = useState(init)

    useEffect(() => {
        if (JSON.stringify(permissions) !== JSON.stringify(init)) {
            setPermissions(init)
        }
    }, [init, permissions])


    const hasOrganizationPermission = useMemo(() => permissions.includes('organization'), [permissions])

    const isOrganization = useMemo(
        () =>
            hasOrganizationPermission &&
            !!team?.managerGroups?.some((group) => group && groups.includes(group)),
        [hasOrganizationPermission, team, groups]
    )

    const isAdmin = useMemo(
        () => permissions?.includes('admin') && teamGroups?.admin && groups.includes(teamGroups.admin),
        [permissions, groups, teamGroups?.admin]
    )

    const isEditor = useMemo(
        () => permissions?.includes('editor') && groups.includes(teamGroups.editor),
        [permissions, groups, teamGroups?.editor]
    )

    const isViewer = useMemo(
        () => permissions?.includes('viewer') && groups.includes(teamGroups.viewer),
        [permissions, groups, teamGroups?.viewer]
    )

    const canEdit = useMemo(() => isAdmin || isEditor, [isAdmin, isEditor])
    const canView = useMemo(() => isOrganization || canEdit || isViewer, [
        isOrganization,
        canEdit,
        isViewer,
    ])

    return {
        isAdmin,
        isEditor,
        isViewer,
        canEdit,
        canView,
        isOrganization,
    }
}
/**
 * The code defines and exports a functional component called "PermissionGuard" that takes in props of type "TeamPermissionGuardProps".

The component makes use of the "useSelector" hook to extract relevant information from the Redux state, specifically the current user and the current team. The "children" and "permission" properties are also extracted from the props object.

If there is no current team, the component returns null.

The "getTeamGroups" function is called with the team id to retrieve the team's group information.

The component then checks the user's permission level against the team's groups. 
If the user has the same group as an admin, editor, or viewer, or if the user belongs to the management group of the team, the children are returned. Otherwise, null is returned.
 * 
 * @param props 
 * @returns 
 */
export const PermissionGuard: FC<TeamPermissionGuardProps> = ({ children, permission }: TeamPermissionGuardProps) => {
    const { current: team } = useSelector((state) => state.team)
    const permissions = usePermissionGuard({ permissions: permission })
    if (!team || !permission.length) {
        return null
    }

    if (permissions.canView) {
        return children
    }

    return null
}
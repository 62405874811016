import { Button, Card, CardContent, Stack, SvgIcon } from '@mui/material';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { TeamTableDetached, TeamTableDetachedValues } from '../dashboard/team/team-table-detached';
interface CanvasStepProps {
    onUserChange: (user: TeamTableDetachedValues[]) => void;
    onNext?: () => void;
    onBack?: () => void;
    users: TeamTableDetachedValues[];
}

export const MembersStep: FC<CanvasStepProps> = (props) => {
    const { onBack, onNext, onUserChange, ...other } = props;

    return (
        <Stack
            spacing={3}
            {...other}
        >
            <Stack spacing={2}>
                <Card
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        p: 2,
                    }}
                >
                    <CardContent>
                        <TeamTableDetached
                            users={props.users}
                            onUserChange={users => onUserChange(users)}
                        ></TeamTableDetached>
                    </CardContent>
                </Card>
            </Stack>
            <Stack spacing={2}
                direction={'row'}>
                <Button
                    endIcon={(
                        <SvgIcon>
                            <ArrowLeftIcon />
                        </SvgIcon>
                    )}
                    onClick={onBack}
                    variant="contained"
                >
                    Back
                </Button>
                <Button
                    // disabled={!createdTeam}
                    endIcon={(
                        <SvgIcon>
                            <ArrowRightIcon />
                        </SvgIcon>
                    )}
                    onClick={onNext}
                    variant="contained"
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
};

MembersStep.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

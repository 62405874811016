import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { mergeWith } from 'lodash';
import { ListMessageChannelsQuery, Message, MessageByChannelQuery, MessageChannel, Organization } from 'src/API';
import { ITeamUser, ITeamUserResponse } from 'src/api/team';
import logger from 'src/log/log';
import { getUserList } from 'src/sections/dashboard/team/team-table';
import { SocketData } from 'src/socket/useSocket';
import type { Thread } from 'src/types/chat';
import { objArrayFromArray, objFromArray } from 'src/utils/obj-from-array';
import { v4 as uuidV4 } from 'uuid';

interface ChannelBasePayload {
  name: string,
  entity?: {
    type: string,
    id: string
  },
  members?: string[]
  organizations?: string[]
}

interface ChannelModifyPayload extends ChannelBasePayload {
  id: string
}



interface ChatState {
  contacts: {
    organizations: {
      byId: Record<string, ITeamUser>;
      allIds: string[];
    },
    users: {
      byId: Record<string, ITeamUser>;
      allIds: string[];
    }
  };
  currentThreadId?: string;
  threads: {
    byId: Record<string, Thread>;
    allIds: string[];
  };
  messagesByChannel: {
    byId: Record<string, Message[]>;
    allIds: string[],
    nextToken?: string | null;
  },
  changedChannelQueue: {
    action: 'CREATE' | 'UPDATE' | 'DELETE',
    channel: ChannelModifyPayload | ChannelBasePayload
    changeId: string
  }[],

}

type GetContactsAction = PayloadAction<{ organization: Organization[], users: ITeamUserResponse }>;

type GetThreadsAction = PayloadAction<GraphQLResult<GraphQLQuery<ListMessageChannelsQuery>> | undefined>;

type GetThreadAction = PayloadAction<Thread | null>;

type MarkThreadAsSeenAction = PayloadAction<string>;

type SetCurrentThreadAction = PayloadAction<string | undefined>;

type AddMessageAction = PayloadAction<SocketData<Message>>;

const initialState: ChatState = {
  contacts: {
    organizations: {
      byId: {},
      allIds: []
    },
    users: {
      byId: {},
      allIds: []
    },
  },
  currentThreadId: undefined,
  threads: {
    byId: {},
    allIds: []
  },
  messagesByChannel: {
    byId: {},
    allIds: [],
    nextToken: undefined
  },
  changedChannelQueue: []
};

const reducers = {

  getMessagesByThread(state: ChatState, action: PayloadAction<GraphQLResult<MessageByChannelQuery>>): void {
    const newObjects = objArrayFromArray(action.payload.data?.messageByChannel?.items || [], 'channelId');
    const newIds = Object.keys(state.messagesByChannel.byId);

    // Perform a deep merge of state.messagesByChannel.byId and newObjects
    state.messagesByChannel.byId = mergeWith({}, state.messagesByChannel.byId, newObjects, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        const srcIds = srcValue.map(i => i.id).sort()
        const notInArray = objValue.filter(f => !srcIds.includes(f.id))
        // If both values are arrays, concatenate them and remove duplicates
        return [...new Set([...notInArray, ...srcValue])];
      }
    });

    // Update state.messagesByChannel.allIds by merging it with newIds and removing duplicates
    state.messagesByChannel.allIds = [...new Set([...state.messagesByChannel.allIds, ...newIds])];

    // Update state.messagesByChannel.nextToken
    state.messagesByChannel.nextToken = action.payload.data?.messageByChannel?.nextToken;

  },
  createThread(state: ChatState, action: PayloadAction<SocketData<MessageChannel>>): void {
    const messageChannel = action.payload.item
    state.threads.byId[messageChannel.id] = messageChannel;
    state.threads.allIds.push(messageChannel.id);
  },
  leaveChannel(state: ChatState, action: PayloadAction<SocketData<MessageChannel>>): void {
    const channel = action.payload.item
    state.threads.byId[channel.id] = channel
  },
  deleteThread(state: ChatState, action: PayloadAction<string>): void {
    if (!action.payload) return
    logger.debug(`Deleting thread ${action.payload}`)
    try {
      delete state.threads.byId[action.payload]
      state.threads.allIds = state.threads.allIds.filter((id) => id !== action.payload)
    } catch (error) {
      logger.log('Failed to delete thread', error)
    }
  },
  deleteMessage(state: ChatState, action: PayloadAction<SocketData<{ id: string }>>): void {
    const message = action.payload.item;
    if (message.id) {
      for (let i = 0; i < state.messagesByChannel.allIds.length; i++) {
        const channelId = state.messagesByChannel.allIds[i];
        const index = state.messagesByChannel.byId[channelId]
          .filter(f => f)
          .findIndex(m => m.id === message.id)
        if (index > -1) {
          logger.debug("delete message", channelId, index)
          delete state.messagesByChannel.byId[channelId][index]
          break; // exit the loop once the index is found
        }
      }
    }
  },
  updateMessage(state: ChatState, action: PayloadAction<SocketData<Message>>) {
    const message = action.payload.item;
    const messagesInChannel = state.messagesByChannel.byId[message.channelId];
    const index = messagesInChannel.findIndex(msg => msg.id === message.id);
    if (index > -1) {
      messagesInChannel[index] = message;
    }
    state.messagesByChannel.byId[message.channelId] = messagesInChannel
  },
  getContacts(state: ChatState, action: GetContactsAction): void {
    const { organization, users } = action.payload;

    state.contacts.organizations.byId = objFromArray(organization)
    state.contacts.organizations.allIds = Object.keys(state.contacts.organizations.byId)

    const userList = getUserList(users.result.users)
    state.contacts.users.byId = objFromArray(userList)
    state.contacts.users.allIds = Object.keys(state.contacts.users.byId)

  },
  getThreads(state: ChatState, action: GetThreadsAction): void {
    const result = action.payload;
    if (!result) return
    const threads = result.data?.listMessageChannels?.items
    if (!threads) return
    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state: ChatState, action: GetThreadAction): void {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id!] = thread;

      if (!state.threads.allIds.includes(thread.id!)) {
        state.threads.allIds.unshift(thread.id!);
      }
    }
  },
  markThreadAsSeen(state: ChatState, action: MarkThreadAsSeenAction): void {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      // thread.unreadCount = 0;
    }
  },
  setCurrentThread(state: ChatState, action: SetCurrentThreadAction): void {
    state.currentThreadId = action.payload;
  },
  addMessage(state: ChatState, action: AddMessageAction): void {
    const message = action.payload.item

    if (!state.messagesByChannel.byId[message.channelId]) {
      state.messagesByChannel.byId[message.channelId] = []
    }

    state.messagesByChannel.byId[message.channelId].push(message)
    if (!state.messagesByChannel.allIds.includes(message.channelId)) {
      state.messagesByChannel.allIds.push(message.channelId)
    }
  },
  // createChannel(state: ChatState, action: PayloadAction<ChannelBasePayload>): void {
  //   state.changedChannelQueue.push({
  //     action: 'CREATE',
  //     channel: action.payload,
  //     changeId: uuidV4()
  //   })
  // },
  updateChannel(state: ChatState, action: PayloadAction<ChannelModifyPayload>) {
    state.changedChannelQueue.push({
      action: 'UPDATE',
      channel: action.payload,
      changeId: uuidV4()
    })
  }
};

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers
});

export const { reducer } = slice;

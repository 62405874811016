export const customListTeams = /* GraphQL */ `
  query customListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          nextToken
          items{
            id
            name
            teamId
            buckets
            canvases{
                items {
                  id
                  name
                  lowercaseName
                  teamId
                  projectId
                  createdAt
                  updatedAt
                  owner
                  channelId
                  properties
                  ownerEmail
                  adminGroups
                  editorGroups
                  viewerGroups
                  managerGroups
                  __typename
                }
                nextToken
                __typename
              }
            readiness
            createdAt
            updatedAt
          }
        }
        fileUrl
        color
        createdAt
        updatedAt
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        teamFlags
        address
        description
        teamSize
        phoneNumber
        valueProposition
        owner
      }
      nextToken
    }
  }
`;

import { Avatar, Button, Grid, List, ListItem, ListItemIcon, ListItemText, StepIconProps, SvgIcon, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import Loading01 from '@untitled-ui/icons-react/build/esm/Loading01';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Team } from 'src/API';
import { teamApi } from 'src/api/team';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useDispatch } from 'src/store';
import { thunks as teamThunk } from 'src/thunks/team';
import { CreationState } from "./onboarding-create-form";

type OnboardingCreationStepProps = {
    data: CreationState
}

const d = {
    "teamInformation": {
        "name": "asdf",
        "address": "",
        "phoneNumber": "01203012",
        "description": "<p>asdf</p>",
        "teamSize": 1,
        "valueProposition": "<p>unique value props</p>"
    },
    "members": [
        {
            "email": "emil.torlen@hotmail.com",
            "role": "viewer"
        }
    ],
    "share": [
        "uic"
    ]
}

const StepIcon: FC<StepIconProps> = (props) => {
    let { active, completed, icon } = props;

    return (
        <Avatar
            sx={{
                height: 40,
                width: 40,
                ...(active && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText'
                }),
                ...(completed && {
                    backgroundColor: 'success.main',
                    color: 'primary.contrastText'
                }),
            }}
            variant="rounded"
        >
            {
                completed
                    ? (
                        <SvgIcon>
                            <CheckIcon />
                        </SvgIcon>
                    )
                    : (active ? (
                        <SvgIcon style={{
                            animation: 'spin 2s infinite linear'
                        }}>
                            <Loading01 />
                        </SvgIcon>
                    ) : icon)
            }
        </Avatar>
    );
};



type StepCreationProps = {
    promise: (data) => Promise<any>,
    completedIndex: number,
    data: any,
}

// Custom hook to handle the process
const useTeamCreation = (inputData: CreationState) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completed, setCompleted] = useState<number[]>([]);
    const [team, setTeam] = useState<Team | undefined | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (completed.length !== 0) return
        const createTeamAndShare = async () => {
            // await new Promise(r => setTimeout(r, 1000));
            if (!inputData.teamInformation?.name) return
            const createResult = await teamApi.create({
                name: inputData.teamInformation?.name,
                description: inputData.teamInformation?.description,
                teamSize: inputData.teamInformation?.teamSize,
                valueProposition: inputData.teamInformation?.valueProposition,
                managerGroups: inputData.share
            })
            return createResult
        }

        async function runCreationSteps() {
            let createResult
            try {
                createResult = await createTeamAndShare()
                setActiveStep(1)
                setCompleted([0])
            } catch (error) {
                console.error("Failed to create team", error)
                setCompleted([])
                toast.error("Failed to create team")
            }

            try {
                if (!createResult.ok) return
                setTeam(createResult.result)
                const memberPromises = inputData.members.map((member) => {
                    return teamApi.addUserToTeam(createResult.result.id, member.email, member.role)
                })
                await Promise.all(memberPromises)
                setCompleted(s => [...s, 1])

            } catch (error) {
                toast.error("Failed to create team")
            }
        }
        runCreationSteps();
    }, [inputData, completed.length, dispatch]); // Dependency array includes inputData to re-run the effect if it changes

    return { activeStep, completed, team };
}
export default function OnboardingCreationStep({ data }: OnboardingCreationStepProps) {
    const { activeStep, completed, team } = useTeamCreation(data)
    const router = useRouter();
    const dispatch = useDispatch();
    const { refreshUser } = useAuth()

    const steps = useMemo(
        () => {
            return [
                {
                    label: 'Creating',
                    text: "We are creating a new team for you and sharing with your team mates.",
                },
                {
                    label: 'Inviting',
                    text: "We are inviting team members to join your team."
                }
            ]
        }, [])

    const handleGoToTeam = useCallback(async () => {
        // refresh so we get the new groups
        dispatch(teamThunk.getTeams())
        await refreshUser()
        router.push(paths.team.overview(team?.id!))
    }, [dispatch, refreshUser, router, team?.id])

    return (
        <>
            <style>{`
            @keyframes spin {
                 0% { transform: rotate(-360deg); }
                 100% { transform: rotate(0deg); }
            }`}
            </style>
            <Grid container
                spacing={3}>
                <Grid item
                    xs={1}></Grid>
                <Grid item
                    xs={8}>
                    <Stack
                        direction={'column'}
                        spacing={2}
                        alignContent={'center'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <List>
                            {
                                steps.map((step, index) => {
                                    return (
                                        <ListItem key={step.label + index}>
                                            <ListItemIcon>
                                                <StepIcon
                                                    active={activeStep === index}
                                                    completed={completed.indexOf(index) > -1}
                                                    icon={<CheckIcon />
                                                    }></StepIcon>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant='h6'>{step.label}</Typography>
                                                <Typography variant='body1'>{step.text}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }

                        </List>
                        <div>
                            {
                                team && (
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        // href={`/t/${team?.id}`}
                                        onClick={handleGoToTeam}
                                    >
                                        Go to team
                                    </Button>)
                            }
                        </div>

                    </Stack>
                </Grid>
            </Grid>
        </>

    )
}

import { Icon, Stack, TextField } from "@mui/material";

import styled from "@emotion/styled";
import { DragHandleRounded } from "@mui/icons-material";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { CanvasTile } from "src/API";
import { slice as canvasSlice } from "../../slices/canvas";
import { useCanvasPopper } from "./canvas-popper";
import { Task } from "./canvas-tile-box";
import { useDraggableInPortal } from "./useDraggablePortal";


type CanvasTileTaskProps = {
    tile: CanvasTile,
    task: Task,
    editMode: boolean,
    onNewTask: () => void,
    permissions,
    index: number
    popper: ReturnType<typeof useCanvasPopper>
}

const DragHandle = styled('div')((theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.color,
    "&:hover": {
        cursor: "grab"
    },
}));




export default function CanvasTileTask({ tile, task, editMode, onNewTask, popper, index, ...other }: CanvasTileTaskProps) {

    const [description, setDescription] = useState<string>(task.description || '')


    useEffect(() => {
        setDescription(task.description || '')
    }, [task.description])

    const handleSelect = (event) => {
        popper.handleClick(event, tile, task)
        setTimeout(popper.handleClose, 2000)
    };

    const dispatch = useDispatch()
    const debouncedOnChange = useRef(debounce((description) => {
        const t = { ...task, description }
        dispatch(canvasSlice.actions.updateCanvasTileTask({
            id: tile.id,
            tasks: [t]
        }))
    }, 1000));

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
        debouncedOnChange.current(event.target.value)
    }
    const renderDraggable = useDraggableInPortal()

    if (!task.id) return null
    return (
        <Draggable
            key={task.id + "droppable"}
            draggableId={task.id}
            index={index}
        //   isDragDisabled={columnId === UNCATEGORIZED || !permissions.canEdit}
        >

            {renderDraggable((provided) => (
                <Stack
                    sx={{
                        padding: 0.2
                    }}
                    direction={'row'}
                    justifyContent={"center"}
                    alignItems={"center"}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >

                    <TextField
                        value={description}
                        // fullWidth
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            display: "flex",
                            flex: 1
                        }}

                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                onNewTask()
                            }
                        }}
                        onClick={(e) => handleSelect(e)}
                        variant={'outlined'}
                        onChange={handleDescriptionChange}
                        disabled={!editMode}
                        multiline
                    ></TextField>
                    <DragHandle
                        {...provided.dragHandleProps}
                        tabIndex={-1}
                    >
                        <Icon
                            style={{ transform: "rotate(90deg)" }}>
                            <DragHandleRounded></DragHandleRounded>
                        </Icon>
                    </DragHandle>
                </Stack >
            ))}
        </Draggable>
    )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Canvas, ListCanvassQuery, ListTasksQuery, Task } from 'src/API';

import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

type OverviewState = {
    tasks: {
        list: Task[]
    },
    canvases: {
        list: Canvas[]
    }
}

const initialState: OverviewState = {
    tasks: {
        list: [],
    },
    canvases: {
        list: [],
    },
}
const reducers = {
    getTasks: (state: OverviewState, action?: PayloadAction<GraphQLResult<GraphQLQuery<ListTasksQuery>>>): void => {
        if (!action?.payload?.data?.listTasks?.items) return
        if (action.payload.data.listTasks?.items?.length > 0) {
            state.tasks.list = action.payload.data.listTasks.items as any
        }
    },
    getCanvases: (state: OverviewState, action?: PayloadAction<GraphQLResult<GraphQLQuery<ListCanvassQuery>>>): void => {
        if (!action?.payload.data?.listCanvass?.items) return
        state.canvases.list = action.payload.data.listCanvass.items as any
    },
}


export const slice = createSlice({
    name: 'overview',
    initialState,
    reducers
});

export const { reducer } = slice;

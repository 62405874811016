
import { Checkbox, FormControlLabel, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useAllOrganizations } from 'src/hooks/use-all-organizations';
type Props = {
    onRoleChange: (roles: string[]) => void
    initRoles?: string[]
}
export default function VisibilityTableDetached({ onRoleChange, initRoles }: Props) {
    const { organizations } = useAllOrganizations()
    const [roles, setRoles] = useState<string[]>(initRoles || [])
    const [lastChange, setLastChange] = useState(JSON.stringify(roles))

    useEffect(() => {
        const j = JSON.stringify(roles)
        if (j !== lastChange) {
            setLastChange(JSON.stringify(roles))
            onRoleChange(roles)
        }
    }, [roles, setLastChange, lastChange, onRoleChange])

    const handleAddManagementGroup = (event) => {
        event.preventDefault();
        const role = event.target.value
        if (!roles.includes(role)) {
            setRoles(s => [...s, role])
        }
    }

    const handleRemoveManagementGroup = (event) => {
        event.preventDefault();
        const role = event.target.value
        setRoles(s => s.filter(r => r !== role))
    }

    return (
        <>
            <Stack
                spacing={2}>

                <Table>
                    <TableBody>
                        {
                            organizations.map((org, index) => {
                                return (
                                    <TableRow key={"row-" + org.id}>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={org.id}
                                                        value={org.role}
                                                        checked={roles.includes(org.role)}
                                                        onChange={(e) => e.target.checked ? handleAddManagementGroup(e) : handleRemoveManagementGroup(e)}
                                                    />
                                                }
                                                label={org.name}
                                            />
                                            <br></br>
                                            <Typography variant='body1'>
                                                {org.description && parse(org.description)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table >
            </Stack>
        </>
    )
}

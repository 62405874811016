import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/guards/guest-guard';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Issuer } from 'src/utils/auth';

// Amplify
const TermsAndConditions = lazy(() => import('src/pages/terms-and-conditions'));
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));

export const legalRoutes: RouteObject[] = [
    {
        index: true,
        element: (
            <IssuerGuard issuer={Issuer.Amplify}>
                <GuestGuard>
                    <AuthLayout>
                        <AmplifyLoginPage />
                    </AuthLayout>
                </GuestGuard>
            </IssuerGuard>
        )
    },
    {
        path: 'legal',
        children: [
            {
                path: 'document',
                element: (
                    <IssuerGuard issuer={Issuer.Amplify}>
                        <GuestGuard>
                            <AuthLayout>
                                <Outlet />
                            </AuthLayout>
                        </GuestGuard>
                    </IssuerGuard>
                ),
                children: [
                    {
                        path: 'terms-and-conditions',
                        element: <TermsAndConditions></TermsAndConditions>
                    }
                ]
            },

        ]
    }
];

import { useCallback } from 'react';
import { Editor } from 'tldraw';
import { CanvasLayoutConfig } from './getCanvasLayout';
import { getSuccessDefLayout } from './getSuccessDefLayout';
import { useAddShapeCallback } from './useAddShapeCallback';
import { CanvasBounds } from './useCreateCanvasLayout';


export const useSuccessDef = (config: CanvasLayoutConfig): (e: Editor) => CanvasBounds => {
    const getShapes = useCallback(() => {
        return getSuccessDefLayout(config)
    }, [config])
    return useAddShapeCallback(getShapes())
}
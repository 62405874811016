import { Link, MenuItem, Popover, Stack, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { Team } from 'src/API';



interface TenantPopoverProps {
  anchorEl: null | Element;
  onChange?: (tenant: Team) => void;
  onClose?: () => void;
  open?: boolean;
  tenants: TenantTeam[];
}

export interface TenantTeam extends Team {
  role?: string | null
}



export const TenantPopover: FC<TenantPopoverProps> = (props) => {
  const { anchorEl, onChange, onClose, open = false, tenants, ...other } = props;
  const [search, setSearch] = useState('')
  const filteredTenants = tenants?.filter((tenant) => tenant?.name.toLowerCase().includes(search.toLowerCase()));
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  // const userGroups = useGetUserGroups()
  // const userGroups = useSelector(s => s.user.groups)


  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 180 } }}
      {...other}
    >
      <TextField
        placeholder='Search'
        value={search}
        label={"Team count: " + filteredTenants.length}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleSearchChange}
      />
      {<MenuItem
        // onClick={() => window.location.pathname = '/team/create'}
      >
        <Stack>
          <Link
            href='/team/create'
          >New team</Link>
        </Stack>
      </MenuItem>}
      {filteredTenants
        .filter(f => !!f)
        .map((tenant) => (
          <MenuItem
            key={tenant!.id}
            onClick={() => onChange?.(tenant!)}
          >
            <Stack>
              <div>
                {tenant!.name}
              </div>
              <Typography variant="caption">{tenant.role || ''}</Typography>
            </Stack>
          </MenuItem>
        ))}
    </Popover>
  );
};
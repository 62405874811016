import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CanvasTile } from 'src/API';
import { Task } from 'src/pages/canvas/canvas-tile-box';
import { objFromArray } from 'src/utils/obj-from-array';
import { tryParseJsonArray } from 'src/utils/try-parse-json';
import { TileAddPayload, TileUpdate } from '../socket/canvas/useCanvasSocket';
import { Canvas } from '../types/canvas';
import { CrudStatus, crudReducer, crudSlice } from './crud-state-slice';
import { v4 as uuidV4 } from 'uuid';
import { DropResult } from 'react-beautiful-dnd';
import logger from 'src/log/log';

export type TileMoveEvent = {
  id: string,
  x: number,
  y: number,
  w: number,
  h: number,
}

type BaseCanvasTileInput = {
  changeId: string
  added: string
}

export interface UpdateCanvasTileInput extends BaseCanvasTileInput {
  action: 'UPDATE',
  tile: TileUpdate[]
}

export interface DeleteCanvasTileInput extends BaseCanvasTileInput {
  action: 'DELETE',
  tile: { id: string }
}

export interface AddCanvasTileInput extends BaseCanvasTileInput {
  action: 'CREATE',
  tile: TileAddPayload
}

interface CanvasBaseState {
  listCanvasess: Canvas[]
  create: any,
  canvasTiles: {
    byId: { [key: string]: CanvasTile },
    allIds: string[]
  },
  changedTileQueue: (UpdateCanvasTileInput | DeleteCanvasTileInput | AddCanvasTileInput)[],
  sentQueue: string[]
}

type CanvasState = CanvasBaseState & CrudStatus<CanvasBaseState>
type GetCanvasTilesForCanvasAction = PayloadAction<CanvasTile[] | any>
type ListCanvasAction = PayloadAction<Canvas[]>;
type GetCanvasAction = PayloadAction<Canvas>;


type UpdateCanvasTileAction = PayloadAction<TileUpdate>
type DeleteCanvasTileAction = PayloadAction<{ id }>
type CreateCanvasTileAction = PayloadAction<TileAddPayload>

type CreateCanvasTaskAction = PayloadAction<TileUpdate>
type DeleteCanvasTaskAction = PayloadAction<{ taskId: string, tileId }>
type UpdateCanvasTileTasksAction = PayloadAction<TileUpdate>

type ReceiveCanvasTileChangeAction = PayloadAction<{ item: TileUpdate, changeId: string }>
type ReceiveCanvasTileAddAction = PayloadAction<{ item: CanvasTile, changeId: string }>
type ReceiveCanvasTileDeleteAction = PayloadAction<{ item: string, changeId: string }>

type MoveCanvasTaskAction = PayloadAction<DropResult>
type MoveCanvasTileAction = PayloadAction<TileMoveEvent[]>
type AddToSentListAction = PayloadAction<string>

const initialState: CanvasState = {
  ...crudSlice,
  create: {},
  listCanvasess: [],
  canvasTiles: {
    byId: {},
    allIds: []
  },
  changedTileQueue: [],
  sentQueue: []
};



const reducers = {
  ...crudReducer<CanvasState>(),
  getCanvases(state: CanvasState, action: ListCanvasAction): void {
    state.listCanvasess = action.payload
  },
  getCanvas(state: CanvasState, action: GetCanvasAction): void {

    if (!action.payload?.id) return
    const canvasIndex = state.listCanvasess.findIndex(canvas => canvas?.id === action.payload!.id);

    if (canvasIndex !== -1) {
      state.listCanvasess[canvasIndex] = action.payload;
    } else {
      state.listCanvasess.push(action.payload);
    }

  },
  getCanvasTileForCanvas(state: CanvasState, action: GetCanvasTilesForCanvasAction): void {
    if (!action.payload) return
    const canvasTiles = action.payload as CanvasTile[]
    state.canvasTiles.allIds = canvasTiles.map(canvas => canvas.id);
    state.canvasTiles.byId = objFromArray(canvasTiles, 'id');
  },
  updateCanvasTile(state: CanvasState, action: UpdateCanvasTileAction): void {
    if (!action.payload) return;
    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: "UPDATE",
      tile: [action.payload]
    })
  },
  moveTaskBetweenTiles(state: CanvasState, action: MoveCanvasTaskAction): void {
    const payload = action.payload
    const { draggableId, source, destination } = payload;

    // Dropped outside the column
    if (!destination) {
      return;
    }

    // Task has not been moved
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    try {
      const sourceTile = state.canvasTiles.byId[source.droppableId];
      const sourceTasks = tryParseJsonArray(sourceTile.tasks);

      if (source.droppableId !== destination.droppableId) {
        // Moved to another column

        const destTile = state.canvasTiles.byId[destination.droppableId];
        const destTasks = tryParseJsonArray(destTile.tasks);

        const draggedTask = sourceTasks.splice(source.index, 1)[0];
        destTasks.splice(destination.index, 0, draggedTask);

        state.canvasTiles.byId[source.droppableId].tasks = JSON.stringify(sourceTasks);
        state.canvasTiles.byId[destination.droppableId].tasks = JSON.stringify(destTasks);
        const sourceTileUpdated = state.canvasTiles.byId[source.droppableId]
        const destTileUpdated = state.canvasTiles.byId[destination.droppableId]

        const updateSourceTile: TileUpdate = {
          id: sourceTileUpdated.id,
          info: sourceTileUpdated.info || undefined,
          position: sourceTileUpdated.position ? JSON.parse(sourceTileUpdated.position) : undefined,
          title: sourceTileUpdated.title || undefined,
          tasks: sourceTileUpdated.tasks ? JSON.parse(sourceTileUpdated.tasks) : undefined
        }

        const updatedDestTile: TileUpdate = {
          id: destTileUpdated.id,
          info: destTileUpdated.info || undefined,
          position: destTileUpdated.position ? JSON.parse(destTileUpdated.position) : undefined,
          title: destTileUpdated.title || undefined,
          tasks: destTileUpdated.tasks ? JSON.parse(destTileUpdated.tasks) : undefined
        }

        state.changedTileQueue.push({
          changeId: uuidV4(),
          added: new Date().toISOString(),
          action: "UPDATE",
          tile: [updateSourceTile, updatedDestTile]
        })
      } else {
        // Moved to the same column on different position

        // Reorder with new index
        const draggedTask = sourceTasks.splice(source.index, 1)[0];
        sourceTasks.splice(destination.index, 0, draggedTask);
        state.canvasTiles.byId[source.droppableId].tasks = JSON.stringify(sourceTasks);
        const sourceTileUpdated = state.canvasTiles.byId[source.droppableId]
        const updateSourceTile: TileUpdate = {
          id: sourceTileUpdated.id,
          info: sourceTileUpdated.info || undefined,
          position: sourceTileUpdated.position ? JSON.parse(sourceTileUpdated.position) : undefined,
          title: sourceTileUpdated.title || undefined,
          tasks: sourceTileUpdated.tasks ? JSON.parse(sourceTileUpdated.tasks) : undefined
        }
        state.changedTileQueue.push({
          changeId: uuidV4(),
          added: new Date().toISOString(),
          action: "UPDATE",
          tile: [updateSourceTile]
        })
      }
    } catch (error) {
      console.error(error);
    }

  },
  receiveCanvasTileChange(state: CanvasState, action: ReceiveCanvasTileChangeAction): void {
    /**
     * need to get changeId
     */
    removeChangeFromQueue(state, action.payload.changeId)
    const updates = action.payload.item

    if (Array.isArray(updates)) {
      updates.forEach(updated => {
        const tile = state.canvasTiles.byId[updated.id]
        if (updated.position) {
          tile.position = JSON.stringify(updated.position)
        }

        if (updated.title) {
          tile.title = updated.title;
        }

        if (updated.info) {
          tile.info = updated.info;
        }

        if (updated.tasks) {
          tile.tasks = JSON.stringify(updated.tasks)
        }
        state.canvasTiles.byId[updated.id] = tile;
      })
    }
  },
  receiveCanvasTileAdd(state: CanvasState, action: ReceiveCanvasTileAddAction): void {
    const tile = action.payload.item
    if (!state.canvasTiles.allIds.includes(tile.id)) {
      state.canvasTiles.allIds.push(tile.id)
      state.canvasTiles.byId[tile.id] = tile;
    }
    removeChangeFromQueue(state, action.payload.changeId)
  },
  receiveCanvasTileDelete(state: CanvasState, action: ReceiveCanvasTileDeleteAction): void {
    const deleteId = action.payload.item
    if (state.canvasTiles.allIds.includes(deleteId)) {
      state.canvasTiles.allIds.splice(state.canvasTiles.allIds.indexOf(deleteId), 1)
      delete state.canvasTiles.byId[deleteId]
    }
    removeChangeFromQueue(state, action.payload.changeId)
  },
  deleteCanvasTile(state: CanvasState, action: DeleteCanvasTileAction): void {
    if (!action.payload) return;
    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'DELETE',
      tile: { id: action.payload.id }
    })
  },
  createCanvasTile(state: CanvasState, action: CreateCanvasTileAction): void {
    if (!action.payload) return;
    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'CREATE',
      tile: action.payload
    })
  },
  createCanvasTask(state: CanvasState, action: CreateCanvasTaskAction): void {
    if (!action.payload) return;
    const { id: tileId, tasks } = action.payload

    const tile = state.canvasTiles.byId[tileId];
    if (!tile) return;
    const currentTasks: Task[] = tryParseJsonArray(tile.tasks)
    const newTasks = mergeTasksWithAddedTasks(currentTasks, tasks);
    updateTasksForTile(state, { tileId, tasks: newTasks })
    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'UPDATE',
      tile: [
        { id: tileId, tasks: newTasks },
      ]
    })
  },
  moveCanvasTiles(state: CanvasState, action: MoveCanvasTileAction): void {
    if (!action.payload) return;
    const updates: TileUpdate[] = []

    action.payload.forEach(tile => {
      state.canvasTiles.byId[tile.id].position = JSON.stringify({
        x: tile.x,
        y: tile.y,
        w: tile.w,
        h: tile.h
      })
      updates.push({
        id: tile.id,
        position: {
          x: tile.x,
          y: tile.y,
          w: tile.w,
          h: tile.h
        }
      });
    })

    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'UPDATE',
      tile: updates
    })

  },
  updateCanvasTileTask(state: CanvasState, action: UpdateCanvasTileTasksAction): void {
    if (!action.payload) return;
    const { id: tileId, tasks: addedTasks } = action.payload
    const tile = state.canvasTiles.byId[tileId]
    if (!tile) return;
    const tasks: Task[] = tryParseJsonArray(tile.tasks);
    const uniqueTasks = mergeTasksWithAddedTasks(tasks, addedTasks);
    updateTasksForTile(state, { tileId, tasks })
    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'UPDATE',
      tile: [
        { id: tileId, tasks: uniqueTasks },
      ]
    })
  },
  deleteCanvasTask(state: CanvasState, action: DeleteCanvasTaskAction): void {
    if (!action.payload) return;
    const { tileId, taskId } = action.payload;
    const tile = state.canvasTiles.byId[tileId]
    if (!tile) return;
    if (!tile.tasks) return;

    const tasks = tryParseJsonArray(tile.tasks);
    if (tasks.length === 0) return;

    tasks.splice(tasks.indexOf(taskId), 1);
    updateTasksForTile(state, { tileId, tasks })

    state.changedTileQueue.push({
      changeId: uuidV4(),
      added: new Date().toISOString(),
      action: 'UPDATE',
      tile: [{
        id: tileId,
        tasks
      }]
    })
  },
  addToSentList(state: CanvasState, action: AddToSentListAction): void {
    state.sentQueue.push(action.payload)
  },
  updateCanvas(state: CanvasState, action): void {
    const updateCanvas = action.payload.data.updateCanvas
    const index = state.listCanvasess.findIndex(f => f && f.id === updateCanvas.id)
    if (index > -1) {
      state.listCanvasess[index] = updateCanvas
    }
  }
};

export const slice = createSlice({
  name: 'canvas',
  initialState,
  reducers
});

export const { reducer } = slice;

/**
 * The mergeTasksWithAddedTasks function is designed to merge two arrays of tasks, tasks and addedTasks, where addedTasks override the corresponding tasks in tasks based on their unique IDs.
 * @param tasks 
 * @param addedTasks 
 * @returns 
 */
function mergeTasksWithAddedTasks(tasks, addedTasks) {
  if (!addedTasks) return tasks;

  const mergedTasks = [...tasks];

  addedTasks.forEach(addedTask => {
    const existingTaskIndex = mergedTasks.findIndex(task => task.id === addedTask.id);
    if (existingTaskIndex !== -1) {
      mergedTasks[existingTaskIndex] = addedTask;
    } else {
      mergedTasks.push(addedTask);
    }
  });

  return mergedTasks;
}

const updateTasksForTile = (state: CanvasState, { tileId, tasks }: { tileId: string, tasks: Task[] }) => {
  const deleteIds: string[] = []
  // make sure each task has unique id
  tasks.forEach(task => {
    if (deleteIds.indexOf(task.id) > -1) {
      deleteIds.push(task.id)
    }
  })
  deleteIds.forEach(taskId => {
    delete state.canvasTiles.byId[taskId];
  })

  state.canvasTiles.byId[tileId].tasks = JSON.stringify(tasks);
}

const removeChangeFromQueue = (state: CanvasState, changeId: string) => {
  state.changedTileQueue = state.changedTileQueue.filter(f => f.changeId !== changeId)
  state.sentQueue.splice(state.sentQueue.indexOf(changeId), 1)
}
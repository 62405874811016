import { Box, Button, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC, MutableRefObject } from 'react';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import type SimpleBarCore from 'simplebar-core';
// import { chatApi } from 'src/api/chat';
import { toast } from 'react-hot-toast';
import { Message, MessageChannel } from 'src/API';
import { Scrollbar } from 'src/components/scrollbar';
import { useRouter } from 'src/hooks/use-router';
import { useThreadMessages } from 'src/hooks/use-thread-messages';
import logger from 'src/log/log';
import { paths } from 'src/paths';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/chat';
import type { Participant, Thread } from 'src/types/chat';
import { AckMessage, CreateMessageParams, DeleteMessageParams, UpdateMessageParams } from '../../../socket/chat/useChatSocket';
import { ChatMessageAdd } from './chat-message-add';
import { ChatMessageEdit } from './chat-message-edit';
import { ChatMessages } from './chat-messages';
import { ChatThreadToolbar } from './chat-thread-toolbar';

const useParticipants = (threadKey: string): Participant[] => {
  const router = useRouter();
  const [participants, setParticipants] = useState<Participant[]>([]);

  const handleParticipantsGet = useCallback(
    async (): Promise<void> => {
      try {
        const participants = []//await chatApi.getParticipants({ threadKey });
        setParticipants(participants);

      } catch (err) {
        console.error(err);
        router.push(paths.dashboard.chat);
      }
    },
    [router, threadKey]
  );

  useEffect(
    () => {
      handleParticipantsGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadKey]
  );

  return participants;
};

const useThread = (threadKey: string): Thread | undefined => {
  const router = useRouter();
  const dispatch = useDispatch();
  const thread = useSelector((state) => {
    const { threads, currentThreadId } = state.chat;

    return threads.byId[currentThreadId as string];
  });

  const handleThreadGet = useCallback(
    async (): Promise<void> => {
      // If thread key is not a valid key (thread id or contact id)
      // the server throws an error, this means that the user tried a shady route
      // and we redirect them on the home view

      let threadId: string | undefined;

      try {
        threadId = await dispatch(thunks.getThread({
          threadKey
        })) as unknown as string | undefined;
      } catch (err) {
        console.error(err);
        router.push(paths.dashboard.chat);
        return;
      }

      // Set the active thread
      // If the thread exists, then is sets it as active, otherwise it sets is as undefined

      dispatch(thunks.setCurrentThread({
        threadId
      }));

      // Mark the thread as seen only if it exists

      if (threadId) {
        dispatch(thunks.markThreadAsSeen({
          threadId
        }));
      }
    },
    [router, dispatch, threadKey]
  );

  useEffect(
    () => {
      handleThreadGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadKey]
  );

  return thread;
};

export const useMessagesScroll = (thread?: string): { messagesRef: MutableRefObject<SimpleBarCore | null> } => {
  const messagesRef = useRef<SimpleBarCore | null>(null);
  const handleUpdate = useCallback(
    (): void => {
      // Thread does not exist
      if (!thread) {
        return;
      }

      // Ref is not used
      if (!messagesRef.current) {
        return;
      }

      const container = messagesRef.current;
      const scrollElement = container!.getScrollElement();

      if (scrollElement) {
        scrollElement.scrollTop = container.el.scrollHeight;
        if (!scrollElement.onscroll) return
        scrollElement.addEventListener('scroll', (e) => { console.log(e) })

      }
    }, [thread]);


  useEffect(
    () => {
      handleUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thread]
  );

  return {
    messagesRef
  };
};

interface ChatThreadProps {
  threadKey: string;
  createMessage?: (data: CreateMessageParams) => void;
  updateMessage?: (data: UpdateMessageParams) => Promise<AckMessage<null>>;
  deleteMessage?: (data: DeleteMessageParams) => Promise<AckMessage<null>>;
  onLeaveChannel?: (id: string) => Promise<AckMessage<MessageChannel>>;
  showToolbar?: boolean
}




export const ChatThread: FC<ChatThreadProps> = (props) => {
  const { threadKey, createMessage, updateMessage, deleteMessage, onLeaveChannel, showToolbar: st, ...other } = props;
  const showToolbar = typeof st == 'boolean' ? st : true;

  const { messages, getNextPage, canGetMore } = useThreadMessages(threadKey);
  const participants = useParticipants(threadKey);
  const { messagesRef } = useMessagesScroll(threadKey);
  const [editMessage, setEditMessage] = useState<Message | null>(null);
  const dispatch = useDispatch();
  const handleSend = useCallback(
    async (body: string): Promise<void> => {
      if (!threadKey) {
        logger.error('Thread key is not defined');
      }
      createMessage?.({
        content: body,
        channelId: threadKey
      })
    },
    [createMessage, threadKey]
  );

  const onEditMessage = useCallback((message: Message) => {
    setEditMessage(message);
  }, [])
  const onDeleteMessage = useCallback(async (message: Message) => {
    const ack = await deleteMessage?.({ id: message.id })
    if (!ack) return
    if (ack.ok) {
      toast.success(ack.message);
    } else {
      toast.error(ack.message);
    }
  }, [deleteMessage])


  const handleEdit = useCallback(async (content: string) => {
    if (!editMessage) return
    if (content !== editMessage?.content) {
      const ack = await updateMessage?.({
        id: editMessage.id,
        content: content.trim()
      })
      if (ack?.ok) {
        setEditMessage(null)
      }
    }
  }, [editMessage, updateMessage]);

  const handleLeaveChannel = useCallback(async () => {
    const ack = await onLeaveChannel?.(threadKey)
    if (!ack) return
    if (ack.ok) {
      toast.success(ack.message);
    } else {
      toast.error(ack.message);
    }
  }, [onLeaveChannel, threadKey])

  const scrollableNodeRef = createRef<HTMLElement | null>()

  const loadMoreMessages = useCallback(() => {
    getNextPage()
  }, [getNextPage])

  return (
    <Stack
      sx={{
        flexGrow: 1,
        overflow: 'hidden'
      }}
      {...other}
    >
      {showToolbar && <ChatThreadToolbar
        participants={participants}
        onLeaveChannel={handleLeaveChannel}
      />}
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <Scrollbar
          scrollableNodeProps={{ ref: scrollableNodeRef }}
          ref={messagesRef}
          sx={{ maxHeight: '100%' }}
        >
          {canGetMore && <Stack
            direction={'column'}
            alignContent={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box p={2}>
              <Button
                variant='contained'
                color='secondary'
                onClick={loadMoreMessages}>Load more</Button>
            </Box>
          </Stack>}
          <ChatMessages
            onEdit={onEditMessage}
            onDelete={onDeleteMessage}
            messages={messages || []}
            participants={[]}
          />
        </Scrollbar>
      </Box>
      <Divider />
      {!editMessage && <ChatMessageAdd onSend={handleSend} />}
      {editMessage && <ChatMessageEdit message={editMessage}
        onSend={handleEdit} />}
    </Stack>
  );
};

ChatThread.propTypes = {
  threadKey: PropTypes.string.isRequired
};

import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getOverlappingDaysInIntervals } from 'date-fns';
import _ from 'lodash';
import { Task, TaskByProjectQuery } from 'src/API';
import { UpdateTaskParams } from 'src/thunks/kanban';
import type { Board, CheckItem, Checklist, Column, Comment, Member } from 'src/types/kanban';
import { objFromArray } from 'src/utils/obj-from-array';
import $log from 'src/log/log';
import { toast } from 'react-hot-toast';
import { Bucket } from 'src/socket/kanban/useKanbanSocket';
import { v4 as uuidV4 } from 'uuid';
import logger from 'src/log/log';


export type WithRequired<T, K extends keyof T> = { [key in keyof T]?: T[key] } & { [P in K]-?: T[P] }
export const UNCATEGORIZED = 'Uncategorized';

export type ChangeColumnQueue = {
  changeId: string
  action: 'UPDATE' | 'DELETE' | 'ADD'
  column: Bucket[]
}

interface KanbanState {
  isLoaded: boolean;
  columns: {
    byId: Record<string, Column>;
    allIds: string[];
    // tasksById: Record<string, Task[]>
  };
  tasks: {
    // items: Task[]
    // byBucket: { [bucket: string]: Task[] }
    byId: { [id: string]: Task }
    allIds: string[]
    nextToken?: string
  };
  members: {
    byId: Record<string, Member>;
    allIds: string[];
  },
  changedTasksQueue: {
    action: 'UPDATE' | 'DELETE',
    task: WithRequired<Task, 'id'>
    changeId: string
  }[],

  changedColumnsQueue: ChangeColumnQueue[]

}

type GetBoardAction = PayloadAction<Board>;

type CreateColumnAction = PayloadAction<Column>;

type UpdateColumnAction = PayloadAction<Column>;

type ClearColumnAction = PayloadAction<string>;

type DeleteColumnAction = PayloadAction<string>;

type CreateTaskAction = PayloadAction<Task>;

type UpdateTaskAction = PayloadAction<UpdateTaskParams>;
type UpdateTasksAction = PayloadAction<Task[]>;

type MoveTaskAction = PayloadAction<{ taskId: string; position: number; columnId?: string; }>;
type MoveColumnAction = PayloadAction<{ columnId: string; position: number; }>;

type DeleteTaskAction = PayloadAction<string>;

type AddCommentAction = PayloadAction<{ taskId: string; comment: Comment; }>;

type AddChecklistAction = PayloadAction<{ taskId: string; checklist: Checklist; }>;

type UpdateChecklistAction = PayloadAction<{ taskId: string; checklist: Checklist; }>;

type DeleteChecklistAction = PayloadAction<{ taskId: string; checklistId: string; }>;

type AddCheckItemAction = PayloadAction<{ taskId: string; checklistId: string; checkItem: CheckItem; }>;

type UpdateCheckItemAction = PayloadAction<{ taskId: string; checklistId: string; checkItem: CheckItem; }>;

type DeleteCheckItemAction = PayloadAction<{ taskId: string; checklistId: string; checkItemId: string; }>;

const initialState: KanbanState = {
  isLoaded: false,
  columns: {
    byId: {},
    allIds: [],
    // tasksById: {},
  },
  tasks: {
    // items: [],
    // byBucket: {},
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  },
  changedTasksQueue: [],
  changedColumnsQueue: []
};


const tryParseJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}
const sortOnSequence = (a: Task, b: Task): number => {
  return (a.sequence || 0) - (b.sequence || 0)
}

function mergeArrays(columns1: any, columns2: any): any {
  const mergedColumns = {
    byId: { ...columns1, ...columns2 },
    // allIds: [...new Set([...columns1.allIds, ...columns2.allIds])],
  };

  // Remove duplicates from taskIds
  mergedColumns.byId = Object.values(mergedColumns.byId)
    .reduce((result: any, column: any) => {
      const uniqueTaskIds = [...new Set(column.taskIds)];
      result[column.id] = { ...column, taskIds: uniqueTaskIds };
      return result;
    }, {});

  return mergedColumns;
}

const getColumns = (state: KanbanState, tasks: Task[]): Record<string, Column> => {
  const columns: Record<string, Column> = {}
  // set column ids
  state.columns.allIds.forEach((columnId: string) => {
    columns[columnId] = {
      id: columnId,
      name: columnId,
      taskIds: []
    }
  })

  tasks
    .sort(sortOnSequence)
    .forEach(({ bucket = UNCATEGORIZED, id }) => {

      // set uncategorized
      if (!bucket || !state.columns.byId[bucket]) {
        console.log('bucket Uncategorized', bucket)
        bucket = UNCATEGORIZED
      }
      if (!columns[bucket]) {
        columns[bucket] = {
          id: bucket,
          name: bucket,
          taskIds: []
        }
      }

      if (columns[bucket].taskIds.includes(id)) {
        console.debug(`Duplicate task id ${id}`)
        return
      }


      columns[bucket].taskIds.push(id);
    })

  if (columns[UNCATEGORIZED] && columns[UNCATEGORIZED].taskIds.length === 0) {
    delete columns[UNCATEGORIZED]
  }
  // const response = _.merge({}, state.columns.byId, columns)
  // console.log('returning', response)
  const result = mergeArrays(state.columns.byId, columns)
  // console.log('result', result)
  return result.byId
}

const mergeArraysUnique = (a: string[], b: string[]): string[] => {
  return [...new Set([...a, ...b])]
}

const addToUpdatedTasks = (state: KanbanState) => (id: string, index: number) => {
  state.tasks.byId[id].sequence = index
  // const task = state.tasks.byId[id]
  if (state.changedTasksQueue.map(t => t.task.id).includes(id)) {
    const position = state.changedTasksQueue.findIndex(t => t.task.id === id)
    state.changedTasksQueue.splice(position, 1)
    state.changedTasksQueue.push({
      action: 'UPDATE',
      task: {
        id,
        sequence: index,
        title: state.tasks.byId[id].title,
        bucket: state.tasks.byId[id].bucket,
      },
      changeId: uuidV4()
    })
    return
  }
  state.changedTasksQueue.push({
    action: 'UPDATE',
    task: {
      id,
      sequence: index,
      title: state.tasks.byId[id].title,
      bucket: state.tasks.byId[id].bucket,
    } as any,
    changeId: uuidV4()
  })
}

const removeManyFromChangedQueue = (state: KanbanState, taskIds: string[]): void => {
  state.changedTasksQueue = state.changedTasksQueue.filter(i => !taskIds.includes(i.task.id))
}
const removeFromChangedQueue = (state: KanbanState, taskId: string): void => {
  state.changedTasksQueue = state.changedTasksQueue.filter(i => i.task.id !== taskId)
}
const removeFromChangedColumnQueue = (state: KanbanState, columns: string[]): void => {
  console.log('trying to remove', columns)
  state.changedColumnsQueue = []//state.changedColumnsQueue.filter(i =>!i.action )
}


// const uniqueObjects = (tasks: Task[]) => tasks.reduce((lookup, obj) => {
//   const { id, modified }: any = obj;

//   if (!lookup[id] || new Date(modified) > new Date(lookup[id].modified)) {
//     lookup[id] = obj;
//   }

//   return lookup;
// }, {});

const recalculateTasks = (state: KanbanState, tasks: Task[]) => {
  // merge tasks
  state.tasks.allIds = mergeArraysUnique(state.tasks.allIds, tasks.map(t => t.id))
  state.tasks.byId = { ...state.tasks.byId, ...objFromArray(tasks) }
  // create columns
  state.columns.byId = getColumns(state, state.tasks.allIds.map(id => state.tasks.byId[id]));
  const allIds = mergeArraysUnique(state.columns.allIds, Object.keys(state.columns.byId))

  if (allIds.includes(UNCATEGORIZED) && Object.keys(state.columns.byId).includes(UNCATEGORIZED)) { // put Uncategorized as first
    allIds.splice(allIds.indexOf(UNCATEGORIZED), 1)
    allIds.unshift(UNCATEGORIZED)
  }

  if (!Object.keys(state.columns.byId).includes(UNCATEGORIZED)) {
    const idx = allIds.indexOf(UNCATEGORIZED)
    if (idx > -1) allIds.splice(idx, 1)
  }
  state.columns.allIds = allIds
  state.isLoaded = true;

}

const reducers = {

  resetState(state: KanbanState, action: any) {
    state.columns = initialState.columns
    state.members = initialState.members
    state.tasks = initialState.tasks
  },
  removeFromChangedQueue(state: KanbanState, action: PayloadAction<Task>) {
    if (!action.payload.id) {
      logger.error("No id for task", action.payload)
      return
    }
    removeFromChangedQueue(state, action.payload.id)
  },

  getBoard(state: KanbanState, action: PayloadAction<GraphQLResult<GraphQLQuery<TaskByProjectQuery>>>): void {
    const tasks: Task[] = action.payload.data?.taskByProject?.items as Task[]
    console.log('kanbanSlice getBoard', tasks)
    if (!tasks) return
    // if the bucket is not in columns make it uncategorized
    tasks.forEach(t => {
      if (!state.columns.allIds.includes(t?.bucket || '')) {
        t.bucket = UNCATEGORIZED
      }
    })
    recalculateTasks(state, tasks)


  },
  createColumn(state: KanbanState, action: CreateColumnAction): void {
    const column = action.payload;
    state.columns.allIds.push(column.id);

  },
  setColumnIds(state: KanbanState, action: PayloadAction<string | undefined | null>): void {
    if (!action.payload) return;
    try {
      const columnIds: Bucket[] = JSON.parse(action.payload)
      const allIds = [...new Set(columnIds)].filter(f => f)
      state.columns.allIds = allIds.map(b => b.name)
      recalculateTasks(state, state.tasks.allIds.map(id => state.tasks.byId[id]))
    } catch (error) {
      console.error("failed setColumnIds", error)
    }
  },

  updateColumnTasks(state: KanbanState, action: PayloadAction<{ newName: string, oldName: string }>): void {
    const tasks = Object.values(state.tasks.byId)
      .filter(task => task.bucket === action.payload.oldName)
    tasks.forEach(t => {
      t.bucket = action.payload.newName
      state.changedTasksQueue.push({
        action: 'UPDATE',
        task: t,
        changeId: uuidV4()
      })
    })
  },
  deleteColumn(state: KanbanState, action: DeleteColumnAction): void {
    const columnId = action.payload;

    delete state.columns.byId[columnId];
    const deleteIds = state.columns.allIds.filter((_columnId) => _columnId !== columnId)
    state.columns.allIds = deleteIds
    deleteIds.forEach((_columnId) => {
      const task = state.tasks.byId[_columnId]
      if (!task) return
      state.changedTasksQueue.push({
        action: 'DELETE',
        task,
        changeId: uuidV4()
      })
    })
  },
  createTask(state: KanbanState, action: CreateTaskAction): void {
    const task = action.payload
    state.columns.byId[action.payload.bucket!].taskIds.push(action.payload.id)
    state.tasks.allIds.push(action.payload.id)
    state.tasks.byId[task.id] = task
  },
  updateTask(state: KanbanState, action: UpdateTaskAction): void {
    const payload = action.payload;
    state.tasks.byId[payload.taskId] = { ...state.tasks.byId[payload.taskId], ...payload.update }
    if (!payload.taskId) {
      $log.log('No task id provided, cannot update task')
      return
    }
    state.changedTasksQueue.push({
      action: 'UPDATE',
      task: { ...action.payload.update, id: payload.taskId },
      changeId: uuidV4()
    })
  },
  updateTasks(state: KanbanState, action: UpdateTasksAction): void {
    console.log('kanbanSlice updateTasks', action.payload)
    const tasks = action.payload;
    removeManyFromChangedQueue(state, tasks.map(i => i.id))
    recalculateTasks(state, tasks)
  },
  moveTask(state: KanbanState, action: MoveTaskAction): void {
    const { taskId, position, columnId } = action.payload;
    let sourceColumnId = state.tasks.byId[taskId].bucket;
    if (!sourceColumnId) sourceColumnId = UNCATEGORIZED
    if (!state.columns.byId[sourceColumnId]) state.columns.byId[sourceColumnId] = { id: UNCATEGORIZED, name: UNCATEGORIZED, taskIds: [] }
    // Remove task from source column
    state.columns.byId[sourceColumnId].taskIds = (
      state.columns.byId[sourceColumnId].taskIds.filter((_taskId) => _taskId !== taskId)
    );

    // If columnId exists, it means that we have to add the task to the new column
    if (columnId) {
      // Change task's columnId reference
      state.tasks.byId[taskId].bucket = columnId;
      // if it doesn't exist add it
      if (!state.columns.byId[columnId]) {
        state.columns.byId[columnId] = {
          id: columnId,
          name: columnId,
          taskIds: []
        }
      }
      // Push the taskId to the specified position
      state.columns.byId[columnId].taskIds.splice(position, 0, taskId);
      // add change so it's later triggered
      state.columns.byId[columnId].taskIds.map(addToUpdatedTasks(state))
    } else {
      // Push the taskId to the specified position
      state.columns.byId[sourceColumnId].taskIds.splice(position, 0, taskId);
      state.columns.byId[sourceColumnId].taskIds.map(addToUpdatedTasks(state))
    }
  },
  moveColumn(state: KanbanState, action: MoveColumnAction): void {

    const index = state.columns.allIds.indexOf(action.payload.columnId)
    if (index === -1) return;
    state.columns.allIds.splice(index, 1)
    state.columns.allIds.splice(action.payload.position, 0, action.payload.columnId)

    state.changedColumnsQueue.push({
      action: 'UPDATE',
      column: state.columns.allIds.map(c => ({
        name: c
      })),
      changeId: uuidV4()
    })
  },

  updateColumn(state: KanbanState, action: PayloadAction<any>): void {
    console.log('updateColumn', action.payload)
    const columns: Bucket[] = tryParseJSON(action.payload.buckets)
    const columnIds = columns.map(c => c.name)
    console.log('columns', columns)

    removeFromChangedColumnQueue(state, columnIds)
    state.columns.allIds = columnIds
    recalculateTasks(state, state.tasks.allIds.map(i => state.tasks.byId[i]))

  },

  deleteTask(state: KanbanState, action: DeleteTaskAction): void {
    console.log('kanbanSlice deleteTask')
    const taskId = action.payload;
    state.changedTasksQueue.push({
      action: 'DELETE',
      task: state.tasks.byId[taskId],
      changeId: uuidV4()
    })
  },
  onTaskDeleted(state: KanbanState, action: PayloadAction<string>): void {
    const taskId = action.payload;
    const tasks = state.tasks.allIds
      .filter(f => f !== taskId)
      .map(id => state.tasks.byId[id])
    removeFromChangedQueue(state, taskId)
    // recalculateTasks(state, tasks)
    state.tasks.allIds = tasks.map(t => t.id)
    state.tasks.byId = { ...state.tasks.byId, ...objFromArray(tasks) }
    // create columns
    state.columns.byId = getColumns(state, state.tasks.allIds.map(id => state.tasks.byId[id]));
    const allIds = mergeArraysUnique(state.columns.allIds, Object.keys(state.columns.byId))

    if (allIds.includes(UNCATEGORIZED) && Object.keys(state.columns.byId).includes(UNCATEGORIZED)) { // put Uncategorized as first
      allIds.splice(allIds.indexOf(UNCATEGORIZED), 1)
      allIds.unshift(UNCATEGORIZED)
    }

    if (!Object.keys(state.columns.byId).includes(UNCATEGORIZED)) {
      const idx = allIds.indexOf(UNCATEGORIZED)
      if (idx > -1) allIds.splice(idx, 1)
    }
    state.columns.allIds = allIds
    state.isLoaded = true;

  },
  addComment(state: KanbanState, action: AddCommentAction): void {
    console.log('addComment')
    // const { taskId, comment } = action.payload;
    // const task = state.tasks.byId[taskId];

    // task.comments.push(comment);
  },
  addChecklist(state: KanbanState, action: AddChecklistAction): void {
    console.log('addChecklist')
    // const { taskId, checklist } = action.payload;
    // const task = state.tasks.byId[taskId];

    // task.checklists.push(checklist);
  },
  updateChecklist(state: KanbanState, action: UpdateChecklistAction): void {
    console.log('updateChecklist')
    // const { taskId, checklist } = action.payload;
    // const task = state.tasks.byId[taskId];

    // task.checklists = task.checklists.map((_checklist) => {
    //   if (_checklist.id === checklist.id) {
    //     return checklist;
    //   }

    //   return _checklist;
    // });
  },
  deleteChecklist(state: KanbanState, action: DeleteChecklistAction): void {
    console.log('deleteChecklist')
    // const { taskId, checklistId } = action.payload;
    // const task = state.tasks.byId[taskId];

    // task.checklists = task.checklists.filter((checklist) => checklist.id !== checklistId);
  },
  addCheckItem(state: KanbanState, action: AddCheckItemAction): void {
    console.log('addCheckItem')
    // const { taskId, checklistId, checkItem } = action.payload;
    // const task = state.tasks.byId[taskId];
    // const checklist = task.checklists.find((checklist) => checklist.id === checklistId);

    // if (!checklist) {
    //   return;
    // }

    // checklist.checkItems.push(checkItem);
  },
  updateCheckItem(state: KanbanState, action: UpdateCheckItemAction): void {
    console.log('updateCheckItem')
    // const {
    //   taskId,
    //   checklistId,
    //   checkItem
    // } = action.payload;
    // const task = state.tasks.byId[taskId];
    // const checklist = task.checklists.find((checklist) => checklist.id === checklistId);

    // if (!checklist) {
    //   return;
    // }

    // checklist.checkItems = checklist.checkItems.map((_checkItem) => {
    //   if (_checkItem.id === checkItem.id) {
    //     return checkItem;
    //   }

    //   return _checkItem;
    // });
  },
  deleteCheckItem(state: KanbanState, action: DeleteCheckItemAction): void {
    console.log('deleteCheckItem')
    // const { taskId, checklistId, checkItemId } = action.payload;
    // const task = state.tasks.byId[taskId];
    // const checklist = task.checklists.find((_checklist) => _checklist.id === checklistId);

    // if (!checklist) {
    //   return;
    // }

    // checklist.checkItems = (
    //   checklist.checkItems.filter((checkItem) => checkItem.id !== checkItemId)
    // );
  },
  removeChangedTask(state: KanbanState, action: PayloadAction<{ taskId: string }>): void {
    console.log('removeChangedTask')
    removeFromChangedQueue(state, action.payload.taskId)
  },
  removeChangedTasks(state: KanbanState, action: PayloadAction<{ taskIds: string[] }>): void {
    console.log('removeChangedTasks')
    removeManyFromChangedQueue(state, action.payload.taskIds)
  }
}

export const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers,
});

export const { reducer } = slice;
// function setColumns(state: KanbanState, tasks: Task[]) {
//   // state.columns.allIds = [...new Set(tasks.sort(sortOnSequence).map(b => b?.bucket || Uncategorized))];
//   state.columns.byId = getColumns(state,tasks);
// }


import { Box } from '@mui/material';
import type { FC } from 'react';
import { UserAvatar } from 'src/components/user-avatar';
import { usePopover } from 'src/hooks/use-popover';
import { AccountPopover } from './account-popover';

export const AccountButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  return (
    <>
      <Box
        // component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <UserAvatar
          sx={{ height: 32, width: 32 }}
          avatarStyle={{ width: 32, height: 32, marginLeft: 2 }}
          currentUser
        />
        {        //   sx={{
          //     height: 32,
          //     width: 32
          //   }}
          //   src={user?.avatar}
          // >
          //   <SvgIcon>
          //     <User01Icon />
          //   </SvgIcon>
        }

      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};

/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
    debug: LogFn;
}

/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error' | 'debug';

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => { };

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly debug: LogFn;

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {};

        this.error = console.error.bind(console);
        // this.debug = (message?: any, ...optionalParams: any[]) => console.debug('debug:', message, ...optionalParams)
        this.debug = console.debug.bind(console);

        if (level === 'error') {
            this.warn = this.log = this.debug = NO_OP;
            return;
        }

        this.warn = console.warn.bind(console);
        if (level === 'warn') {
            this.log = this.debug = NO_OP;
            return;
        }

        this.log = console.log.bind(console);
        if (level === 'log') {
            this.debug = NO_OP;
            return;
        }
    }
}
const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOG_LEVEL as LogLevel || "log" });
export default logger;
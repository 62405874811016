import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { crudReducer, crudSlice, CrudStatus } from './crud-state-slice';
import { GetUserQuery, Organization, User } from 'src/API';
import { GraphQLQuery } from '@aws-amplify/api';

type UserBaseState = {
    organizations: OrganizationObject[]
    account?: User | null
}

type UserState = UserBaseState & CrudStatus<UserBaseState>

export interface OrganizationObject extends Organization {
    description: string
    id: string
    name: string
    role: string
}

const initialState: UserState = {
    ...crudSlice,
    account: undefined,
    // groups: [],
    organizations: []
}

type SetListAction = PayloadAction<string[]>
type SetListOrganizationAction = PayloadAction<OrganizationObject[]>
type UpdateUserAction = PayloadAction<User>

const reducers = {
    ...crudReducer<UserState>(),
    setGroups(state: UserState, action: SetListAction) {
        // state.groups = action.payload;
    },
    setOrganizations(state: UserState, action: SetListOrganizationAction) {
        state.organizations = action.payload;
    },
    updateAccount(state: UserState, action: UpdateUserAction) {
        state.account = action.payload;
    },
    getAccount(state: UserState, action: PayloadAction<GraphQLQuery<GetUserQuery> | undefined>) {
        if (!action.payload) return
        state.account = action.payload.getUser
    },

}

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers
});

export const { reducer } = slice;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template, ListTemplatesQuery, UpdateTemplateMutation, DeleteTemplateMutation } from '../API';
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api';

interface TemplateState {
    listTemplates: Template[]
    loading: boolean
}
const initialState: TemplateState = {
    listTemplates: [],
    // listTemplates: {
    //     errors: [],
    //     extensions: [],
    //     data: {
    //         listTemplates: {
    //             items: [],
    //             __typename: 'ModelTemplateConnection',
    //             nextToken: null,
    //         }
    //     }
    // },
    loading: false,
}

type GetTemplatesAction = PayloadAction<GraphQLResult<GraphQLQuery<ListTemplatesQuery>> | undefined>

const reducers = {
    getTemplates(state: TemplateState, action: GetTemplatesAction): void {
        console.log("action.payload", action.payload)
        state.listTemplates = action.payload?.data?.listTemplates?.items as Template[] || []
    },
    updateTemplate(state: TemplateState, action: PayloadAction<GraphQLResult<GraphQLQuery<UpdateTemplateMutation>>> | undefined): void {
        console.log("action.payload", action?.payload)
        const index = state.listTemplates?.findIndex(f => f?.id === action?.payload?.data?.updateTemplate?.id)
        if (index && index > -1) {
            state.listTemplates[index] = action?.payload?.data?.updateTemplate as Template
        }
    },
    updateTemplates(state: TemplateState, action: PayloadAction<GraphQLResult<GraphQLQuery<UpdateTemplateMutation>>[]> | undefined): void {
        if (!action?.payload) return
        for (let i = 0; i < action?.payload.length; i++) {
            const index = state.listTemplates?.findIndex(f => f?.id === action?.payload[i]?.data?.updateTemplate?.id)
            if (index && index > -1) {
                state.listTemplates[index] = action?.payload[i]?.data?.updateTemplate as Template
            }
        }
    },
    deleteTemplate(state: TemplateState, action: PayloadAction<GraphQLResult<GraphQLQuery<DeleteTemplateMutation>>>): void {
        if (!action.payload.data?.deleteTemplate?.id) return
        const index = state.listTemplates?.findIndex(f => f?.id === action.payload.data!.deleteTemplate!.id)
        if (index && index > -1) {
            state.listTemplates.splice(index, 1)
        }
    }

}
export const slice = createSlice({
    name: 'Template',
    initialState,
    reducers
});

export const { reducer } = slice;

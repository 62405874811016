import { IconButton, Paper, Popper, Stack } from "@mui/material";

import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import { useState } from 'react';
import { CanvasTile } from "src/API";
import { PermissionGuard } from "src/guards/organization-guard";
import logger from 'src/log/log';
import { useDispatch } from "src/store";
import { slice as canvasSlice } from "../../slices/canvas";
import { Task } from "./canvas-tile-box";

export const useCanvasPopper = () => {
    const [anchor, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const initTileTask: {
        tile?: CanvasTile | null,
        task?: Task | null
    } = { tile: null, task: null }
    const [tileTask, setTileTask] = useState(initTileTask);

    const handleClick = (event, tile, task) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setTileTask({ tile, task });
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setTileTask(initTileTask);
    };

    return {
        anchor,
        open,
        task: tileTask.task,
        tile: tileTask.tile,
        handleClick,
        handleClose,
    };
}

export default function CanvasPopper({ popper, permissions }: { popper: ReturnType<typeof useCanvasPopper>, permissions: PermissionGuard }) {

    const canBeOpen = popper.open && Boolean(popper.anchor);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const dispatch = useDispatch()
    const handleDelete = (e) => {
        if (!popper.task?.id || !popper.tile?.id) {
            logger.warn("TaskPopover.handleDelete: taskId or tile.id not defined");
        }
        dispatch(canvasSlice.actions.deleteCanvasTask({ taskId: popper.task!.id, tileId: popper.tile!.id }))
        popper.handleClose()
    }
    
    return (
        <Popper id={id}
            open={popper.open}
            anchorEl={popper.anchor}
            placement="right-start"
        >

            <Paper
                sx={{
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
                    bgcolor: "background.paper",
                    zIndex: 99999
                }}

            >
                <Stack
                    direction={'row'}
                    spacing={2}
                    padding={1}
                >
                    {/* <IconButton
                        size="small"
                        color='primary'
                        title="Create comment"
                    > 
                     <MessageChatSquareIcon></MessageChatSquareIcon> 
                     </IconButton> */}
                    {permissions.canEdit && (
                        <IconButton
                            size="small"
                            color="error"
                            onClick={handleDelete}
                            title="Delete task"
                        >
                            <Trash02Icon></Trash02Icon>
                        </IconButton>
                    )}
                </Stack>
            </Paper>
        </Popper >
    )
}

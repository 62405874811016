import Auth from '@aws-amplify/auth';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import io, { Socket } from 'socket.io-client';
import { usePermissionGuard } from 'src/guards/organization-guard';
import { useAuth } from 'src/hooks/use-auth';
import { getBackendUrl, getCustomerShortName } from '../config';
import logger from 'src/log/log';
import { thunks } from 'src/thunks/connection-status';
import { ConnectionStatus } from 'src/slices/connection-status';
import { useDispatch } from 'src/store';


export interface QueryInitiator {
    type: "message" | "canvas" | "kanban"
}
export interface QueryInitiatorProject extends QueryInitiator {
    projectId?: string
    type: 'kanban'
    canvasId?: never
}
export interface QueryInitiatorCanvas extends QueryInitiator {
    canvasId?: string
    type: 'canvas'
    projectId?: never
}
export interface QueryInitiatorChat extends QueryInitiator {
    threadId?: string
    teamId?: string
    type: 'message'
    projectId?: never
    entity?: string
}

export type SocketData<T> = {
    changeId: string
    clientTs: number
    clientId: string,
    item: T,
    ts?: number
}

export const useSocket = ({ queryInitiator, }: {
    queryInitiator: QueryInitiatorProject | QueryInitiatorCanvas | QueryInitiatorChat
}) => {

    const [socket, setSocket] = useState<Socket<any, any> | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [connected, setConnected] = useState(false);
    const { user } = useAuth()
    const dispatch = useDispatch()
    const getToken = useCallback(async () => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        return token;
    }, [])

    const refreshSession = useCallback(async () => {
        const session = await Auth.currentSession();
        const refreshToken = session.getRefreshToken()
        const user = await Auth.currentAuthenticatedUser()
        return new Promise((resolve, reject) => {
            user.refreshSession(refreshToken, (err, data) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(data)
                }
            })
        })
    }, [])

    const getSocket = useCallback(() => {
        logger.debug("connecting to server", getBackendUrl())
        const newSocket = io(getBackendUrl(), {
            path: '/socket',
            autoConnect: false,
            auth: { token },
            query: queryInitiator as any,
            extraHeaders: {
                "_cust": getCustomerShortName()
            },
            reconnection: true
        });
        return newSocket
    }, [queryInitiator, token])

    const updateWithNewSocket = useCallback(async () => {
        const r = await refreshSession()
        logger.debug("setting new token")
        setToken(await getToken())
        socket?.disconnect()
        setSocket(getSocket())
    }, [getToken, refreshSession, getSocket, socket])

    useEffect(() => {
        getToken().then(t => {
            if (t && token === t) return
            setToken(t)
        }).catch(err => console.error(err))
        return () => { }
    }, [setToken, token, getToken])

    useEffect(() => {
        logger.debug("connecting new socket")
        socket?.connect()
        return () => {
            logger.debug("disconnecting socket", socket?.id)
            socket?.disconnect()
        }
    }, [socket])

    const hasSameId = useCallback(() => {
        if (queryInitiator.type === "canvas") {
            return (socket as any)?._opts?.query?.canvasId === queryInitiator.canvasId
        } else if (queryInitiator.type === "kanban") {
            return (socket as any)?._opts?.query?.projectId === queryInitiator.projectId
        } else if (queryInitiator.type === "message") {
            return (socket as any)?._opts?.query?.teamId === queryInitiator.teamId
        }
    }, [queryInitiator, socket])

    useEffect(() => {
        if (!token) return
        if (!queryInitiator) return
        const hasSameProjectId = hasSameId()
        if (hasSameProjectId) return
        const newSocket = getSocket()
        logger.debug("newSocket")
        setSocket(newSocket);
        return () => {
        };
    }, [queryInitiator, token, socket, getSocket, hasSameId]);

    useEffect(() => {
        let status: keyof typeof ConnectionStatus = 'disconnected'
        if (connected) {
            status = 'connected'
        } else if (!connected) {
            status = 'disconnected'
        }
        dispatch(thunks.setConnection({ status, sockets: [socket?.id] }))
        return () => {
            dispatch(thunks.removeStatus({ status: 'disconnected', sockets: [socket?.id] }))
        }
    }, [connected, dispatch, socket?.id])



    useEffect(() => {
        if (socket) {
            socket.io.on('reconnect', () => {
                console.log('reconnect')
                updateWithNewSocket()
            })
            socket.on('connect', () => {
                console.log("connected socket", socket.id)
                setConnected(true)
            });
            socket.on('disconnecting', () => {
                console.log('disconnecting')
            });
            socket.on('disconnect', () => {
                setConnected(false)
            })
            // console.log('listening on', `user:${user?.id}`)

            socket.on(`permission`, (e) => {
                toast.error(e.message)
            })
            socket.on('connect_error', (err) => {
                dispatch(thunks.setConnection({
                    status: 'connecting',
                    sockets: []
                }))
                if (err.message === "INVALID_TOKEN") {
                    console.error("invalid token")
                    updateWithNewSocket()
                }
                else {
                    console.error(err)
                    //'ERR_CONNECTION_REFUSED' 
                }
            })
            return () => {
                socket?.off('permission')
                socket.off('connect')
                socket.off('disconnecting')
                socket.off('disconnect')
                socket.off('connect_error')
                socket.off("server:time")
            }
        } else {
            logger.debug("socket is null")
        }
    }, [socket, getToken, updateWithNewSocket, user]);

    const sendData = (channel: string, data, changeId: string, ackCallback?: (data: any) => void) => {
        // if (!permissions.canEdit) {
        //     logger.debug("not allowed to send data")
        //     return
        // }
        if (!socket) {
            logger.error("No socket")
            return
        }
        if (!socket.connected) {
            logger.error("socket not connected")
            return
        }
        const requestData: SocketData<any> = {
            clientTs: new Date().getTime(),
            clientId: socket.id,
            item: data,
            changeId
        }
        logger.debug("sending data ", channel, requestData)
        socket.emit(channel, requestData, (response) => {
            ackCallback?.(response)
        })
    }

    return { socket, sendData }
};
import { Button, Divider, IconButton, MenuItem, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { thunks } from 'src/thunks/team';
import * as Yup from 'yup';
import { ITeamRoleUsers, ITeamUser, teamApi } from '../../../api/team/index';
import { useGetUsers } from '../../../hooks/use-get-users-in-team';
import { useDispatch, useSelector } from '../../../store/index';


type TeamTableProps = {
}


type Values = {
    email: string
    role: string
}
export type UserRecords = Record<string, ITeamUser>

export const getUserRecords = (users: ITeamRoleUsers) => {
    const u = getUserList(users);
    const response: UserRecords = {}
    for (let i = 0; i < u.length; i++) {
        const user = u[i];
        response[user.id] = user
    }
    return response
}


export const getUserList = (users: ITeamRoleUsers): ITeamUser[] => {
    let { admins, editors, invited, viewers, managers } = users
    const userList: any[] = []
    admins.forEach((u: any) => {
        userList.push({ ...u, displayType: "admin", role: "admin" })
    })
    editors.forEach((u: any) => {
        userList.push({ ...u, displayType: "editor", role: "editor" })
    })
    invited.forEach((u: any) => {
        userList.push({ ...u, displayType: "invited", role: "invited" })
    })
    viewers.forEach((u: any) => {
        userList.push({ ...u, displayType: "viewer", role: "viewer" })
    })
    managers.forEach((u: any) => {
        userList.push({ ...u, displayType: "manager", role: "manager" })
    })
    return userList

}

type UserUpdateType = {
    users: {
        updated: { [id: string]: any },
        deleted: { [id: string]: any },
    }
    teamId?: string
}

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    role: Yup.string().oneOf(["admin", "editor", "viewer"]).required(),
})

export const TeamTable: FC<TeamTableProps> = (props) => {
    const users = useGetUsers()
    const teamId = useSelector(s => s.team?.current?.id)
    const dispatch = useDispatch()
    const [newUser, setNewUser] = useState({ email: "", role: "viewer" })
    const [saveDisabled, setsaveDisabled] = useState(true)

    const [userUpdate, setUserUpdate] = useState<UserUpdateType>({
        users: {
            updated: {},
            deleted: {}
        },
        teamId
    })

    const handleDeleteUsers = (user) => {
        if (user && teamId)
            teamApi.removeUserFromTeam(teamId, user.id).then(() => {
                toast.success('User deleted successfully')
            }).catch(err => {
                console.error(err)
                toast.error("Failed to remove user from team")
            }).finally(() => {
                dispatch(thunks.getTeamUsers({ teamId }))
            })
    }

    const initialValues: Values = {
        email: newUser.email,
        role: newUser.role,
    }

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(false);
            if (teamId) {
                teamApi.addUserToTeam(teamId, values.email, values.role).then(() => {
                    toast.success('User added successfully')
                    formik.resetForm()
                }).catch(err => {
                    console.error(err)
                    toast.error("Failed to add user to team")
                }).finally(() => {
                    dispatch(thunks.getTeamUsers({ teamId }))
                })
            }
        }
    })

    const handleRoleChangeOfUser = (user, role: string) => {
        setUserUpdate(s => ({
            ...s,
            users: {
                ...s.users,
                updated: {
                    ...s.users.updated,
                    [user.id]: {
                        ...user,
                        role
                    }
                }
            }
        }))
    }
    useEffect(() => {
        setUserUpdate(s => ({ ...s, teamId }))
        return () => {

        }
    }, [teamId])


    const updateRoleForUser = () => {
        teamApi.updateTeamPermissions(userUpdate).then(() => {
            toast.success('Role updated successfully')
        }).catch(err => {
            console.error(err)
            toast.error("Failed to update role")
        })
    }
    return (
        <>

            <form
                onSubmit={formik.handleSubmit}
                {...props}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{ my: 2 }}
                >
                    <TextField
                        name="email"
                        label="Email"
                        style={{ flexGrow: 1 }}
                        value={formik.values.email}
                        error={!!(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    ></TextField>

                    <TextField
                        variant='outlined'
                        select
                        name='role'
                        label='Role'
                        defaultValue={'viewer'}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        helperText={formik.touched.role && formik.errors.role}
                    >
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'editor'}>Editor</MenuItem>
                        <MenuItem value={'viewer'}>Viewer</MenuItem>
                    </TextField>
                    <div>
                        <Button
                            // onClick={handleAddUser}
                            variant='contained'
                            type='submit'
                        >Add</Button>
                    </div>
                </Stack>
            </form>
            <Divider></Divider>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getUserList(users)
                        .filter(f=>f.type !== 'MANAGER')
                        .map(user => {
                            return (
                                <TableRow key={user.id}>
                                    <TableCell>{user.email}</TableCell>
                                    {/* <TableCell>{user.role}</TableCell> */}
                                    <TableCell>
                                        <TextField
                                            variant='outlined'
                                            select
                                            defaultValue={user.role}
                                            onChange={(e) => handleRoleChangeOfUser(user, e.target.value)}
                                        >
                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                            <MenuItem value={'editor'}>Editor</MenuItem>
                                            <MenuItem value={'viewer'}>Viewer</MenuItem>
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color='error'
                                            onClick={() => handleDeleteUsers(user)}
                                        >
                                            <SvgIcon>
                                                <Trash01></Trash01>
                                            </SvgIcon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Stack
                spacing={2}
                sx={{
                    mx: 4,
                    my: 2
                }}
                alignItems="end"
            >
                <div>
                    <Button
                        variant='contained'
                        disabled={Object.keys(userUpdate.users.updated).length === 0}
                        onClick={updateRoleForUser}
                    >Save</Button>
                </div>
            </Stack>

        </>
    )
}
import RGL from "react-grid-layout";
// import { setEditmode } from '../../actions/formActions';
import { useEffect, useState } from "react";
import { CanvasTile } from '../../API';
import "../../css/react-grid.css";
import "../../css/react-resizable.css";

const ReactGridLayout = RGL//WidthProvider(RGL);

const setMinZero = (number) => {
    if (!number || typeof number != "number") return 0
    return number
}

const tryParseJSON = (json) => {
    if (typeof json != "string") {
        return json
    }
    return JSON.parse(json)
}

const getLayout = (items: CanvasTile[] = [], isEditMode = false) => {
    const layout = items.map(v => {
        const { x, y, w, h } = tryParseJSON(v.position)
        const { id: i } = v
        return {
            i,
            x: setMinZero(x),
            y: setMinZero(y),
            w: setMinZero(w),
            h: setMinZero(h),
            minW: 2,
            minH: 2,
            isDraggable: isEditMode
            // "static": 
        }
    })
    return layout
}

function CanvasGridLayout({
    items = [],
    isEditMode,
    renderComponent,
    onChange,
    size
}: {
    items: CanvasTile[],
    isEditMode: boolean,
    onChange: (items) => any,
    renderComponent: (component, index) => any,
    size: { width, height }
}) {
    const { width, height } = size
    const [w, setW] = useState(width || 1200)
    useEffect(() => {
        if (width > 0) {
            setW(width)
        }
    }, [width])
    const onUpdatedItems = (newLayout) => {
        let updateItems: any[] = []
        for (let i = 0; i < newLayout.length; i++) {
            const current = newLayout[i];
            const [previous] = getLayout(items, isEditMode).filter(f => f.i === current.i)
            if (previous) {
                const { i, x, y, w, h } = current
                const { x: x2, y: y2, w: w2, h: h2 } = previous
                if (x !== x2 || y !== y2 || w !== w2 || h !== h2) {
                    updateItems.push({ id: i, x, y, w, h, })
                }
            }
        }
        if (typeof onChange == "function") {
            onChange(updateItems)
        }
    }

    const layout = getLayout(items, isEditMode)
    return (
        <ReactGridLayout
            margin={[5, 5]}
            className="layout"
            layout={layout}
            compactType={"vertical"}
            cols={12}
            rowHeight={30}
            width={w}
            onDragStop={onUpdatedItems}
            onResizeStop={onUpdatedItems}
        >
            {items.map((v, i) => {
                return <div key={v.id}>{renderComponent(v, i)}</div>
            })}
        </ReactGridLayout>
    )
}
export default CanvasGridLayout
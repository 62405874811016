import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import { FC, ReactNode, createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { amplifyConfig } from 'src/config';
import { useDispatch as rDispatch, useSelector as rSelector } from 'src/store';
import { thunks as userThunk } from 'src/thunks/user';
import type { User } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import { User as DbUser } from '../../API';

Auth.configure({
  userPoolId: amplifyConfig.aws_user_pools_id,
  userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
  region: amplifyConfig.aws_cognito_region
});


type UserGroupType = {
  groups?: string[]
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserGroupType & User | null;

}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  REFRESH_GROUPS = 'REFRESH_GROUPS',
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: UserGroupType & User | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: UserGroupType & User;
  };
};

type RefreshGroupsAction = {
  type: ActionType.REFRESH_GROUPS;
  payload: {
    user: UserGroupType & User;
    groups: string[];
  };
}

type SignOutAction = {
  type: ActionType.SIGN_OUT;
}

type Action =
  | InitializeAction
  | SignInAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REFRESH_GROUPS: (state: State, action: RefreshGroupsAction): State => {
    const { groups } = action.payload;
    return {
      ...state,
      user: {
        ...state.user as any,
        groups
      }
    }
  }
};


const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface AuthContextType extends State {
  issuer: Issuer.Amplify;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  resendSignUp: (username: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<void>;
  refreshUser: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.Amplify,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  confirmSignUp: () => Promise.resolve(),
  resendSignUp: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  refreshUser: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}


const useUserAccount = () => {
  const account = rSelector(s => s.user.account)
  const [acc, setAcc] = useState<DbUser | undefined | null>(account)
  const dispatch = rDispatch()
  const getAccount = useCallback(
    async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        dispatch(userThunk.getAccount(user.attributes.sub))
      } catch (error) {
        setAcc(null)
        console.error(error)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    getAccount()
  }, [getAccount])


  useEffect(() => {
    setAcc(account)
  }, [account])
  return acc
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const account = useUserAccount()


  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const initialize = useCallback(
    async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
        // const accountResult = await userApi.getUser({ id: user.attributes.sub })
        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.


        // @ts-ignore
        if (window.HelpCrunch) {
          var helpCrunchUser = {
            email: user.attributes.email,
            name: account?.displayName || user.attributes.email,
            user_id: user.attributes.sub,
            phone: '',
          };
          // @ts-ignore
          window.HelpCrunch('updateUser', helpCrunchUser);
        }

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              avatar: account?.avatar || undefined,
              email: user.attributes.email,
              name: account?.displayName || undefined,
              groups: groups || [],
              account: account
            },
          }
        });
      } catch (error) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    },
    [dispatch, account]
  );

  const refreshUser = useCallback(async () => {
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken()
    const user = await Auth.currentAuthenticatedUser()
    return new Promise((resolve, reject) => {
      user.refreshSession(refreshToken, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    }).then((d => {
      return initialize()
    }))
  }, [initialize])

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      await Auth.signOut();
      dispatch({
        type: ActionType.SIGN_OUT
      });
    },
    [dispatch]
  );

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const user = await Auth.signIn(email, password);

      if (user.challengeName) {
        console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
        return;
      }

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user: {
            id: user.attributes.sub,
            avatar: '/assets/avatars/avatar-anika-visser.png',
            email: user.attributes.email,
            name: 'USER NAME',
            plan: 'Premium'
          }
        }
      });
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (email: string, password: string): Promise<void> => {
      await Auth.signUp({
        username: email,
        password,
        attributes: { email }
      });
    },
    []
  );

  const confirmSignUp = useCallback(
    async (username: string, code: string): Promise<void> => {
      await Auth.confirmSignUp(username, code);
    },
    []
  );

  const resendSignUp = useCallback(
    async (username: string): Promise<void> => {
      await Auth.resendSignUp(username);
    },
    []
  );

  const forgotPassword = useCallback(
    async (username: string): Promise<void> => {
      await Auth.forgotPassword(username);
    },
    []
  );

  const forgotPasswordSubmit = useCallback(
    async (username: string, code: string, newPassword: string): Promise<void> => {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        refreshUser,
        issuer: Issuer.Amplify,
        signIn,
        signUp,
        confirmSignUp,
        resendSignUp,
        forgotPassword,
        forgotPasswordSubmit,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;

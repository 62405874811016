
import { DefaultColorThemePalette, Geometry2d, HTMLContainer, Rectangle2d, ShapeUtil, TLBaseShape } from "tldraw";


export type ICanvasBoxShape = TLBaseShape<
    'canvas-box',
    {
        w: number
        h: number
        border: string
        text: string
        bgColor: string
        color?: string
        // font: string
    }
>

export default class CanvasBoxShapeUtil extends ShapeUtil<ICanvasBoxShape> {
    static override type = 'box' as any
    // static override props: ShapeProps<ICanvasBoxShape> = {
    //     bgColor: T.string,
    //     border: T.string
    // }

    override getGeometry(shape: ICanvasBoxShape): Geometry2d {
        return new Rectangle2d({
            width: shape.props.w,
            height: shape.props.h,
            isFilled: true,
        });
    }

    indicator(shape: ICanvasBoxShape) {
        return {
            x: shape.x,
            y: shape.y,
            w: shape.props.w,
            h: shape.props.h,
        }
    }
    override getDefaultProps(): ICanvasBoxShape['props'] {
        return {
            w: 100,
            h: 100,
            border: '1px solid black',
            text: 'Box',
            bgColor: 'white'
        }
    }

    canBind: () => boolean = () => true
    canSnap: () => boolean = () => true
    canResize: () => boolean = () => true
    canCrop: () => boolean = () => true
    canEdit: () => boolean = () => true
    component(shape: ICanvasBoxShape): JSX.Element {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // const theme = useDefaultColorTheme()
        let background = (DefaultColorThemePalette.lightMode[shape.props.bgColor] as any).highlight.srgb
        if (shape.props.bgColor === 'black') background = '#fefefe'
        return (
            <HTMLContainer>
                <div
                    style={{
                        width: shape.props.w,
                        height: shape.props.h,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                        flexDirection: 'column',
                        border: '1px solid black',
                        backgroundColor: background
                    }}
                >
                    <h3 style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'start',
                        paddingLeft: 10,
                        color: "black",
                        // font: shape.props.font
                    }}>{shape.props.text}</h3>


                </div>
            </HTMLContainer>
        )
    }
}
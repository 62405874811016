import { useCallback, useEffect, useState } from 'react';
import { Organization } from "src/API";
import { useAllOrganizations } from "./use-all-organizations";
import { useAuth } from "./use-auth";
import { OrganizationObject } from 'src/slices/user';


export const useUserOrganizations = () => {
    const { user } = useAuth()
    const { organizations } = useAllOrganizations()
    const [userOrganizations, setUserOrganizations] = useState<OrganizationObject[]>([])
    const getUserOrganizations = useCallback(
        () => {
            return organizations.filter(f => user?.groups?.includes(f.role))
        },
        [organizations, user],
    )

    useEffect(() => {
        if (user) {
            const orgs = getUserOrganizations()
            setUserOrganizations(orgs)
        }
    }, [user, getUserOrganizations])

    return userOrganizations
}
import { Box, Card, CardMedia, Icon, IconButton, Link, Stack, Typography } from '@mui/material';
import DotsHorizontal from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import { FC, useCallback } from 'react';
import { UserAvatar } from 'src/components/user-avatar';

interface ChatMessageProps {
  authorAvatar?: string | null;
  authorName?: string;
  body: string;
  contentType?: string;
  createdAt?: string | null;
  position?: 'left' | 'right';
  isUser: boolean
  onMenuOpen: (e) => void
  updatedAt?: string | null
}

export const ChatMessage: FC<ChatMessageProps> = (props) => {
  const { authorAvatar, authorName, body, contentType, createdAt, updatedAt, position, isUser, onMenuOpen, ...other } = props;
  const ago = formatDistanceToNowStrict(new Date(updatedAt || createdAt!).getTime());
  const handleMoreButtonRef = useCallback((e) => {
    if (!e) return
    onMenuOpen?.(e)
  }, [onMenuOpen])
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: position === 'right' ? 'flex-end' : 'flex-start'
      }}
      {...other}
    >
      <Stack
        alignItems="flex-start"
        direction={position === 'right' ? 'row-reverse' : 'row'}
        spacing={2}
        sx={{
          maxWidth: 500,
          ml: position === 'right' ? 'auto' : 0,
          mr: position === 'left' ? 'auto' : 0
        }}
      >
        {/* <Avatar
          src={authorAvatar || undefined}
          sx={{
            height: 32,
            width: 32
          }}
        /> */}

        <Stack
          direction={'column'}
        >
          {
            isUser && (
              <IconButton
                onClick={handleMoreButtonRef}
              >
                <Icon>
                  <DotsHorizontal></DotsHorizontal>
                </Icon>
              </IconButton>
            )
          }
          <UserAvatar config={authorAvatar}></UserAvatar>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
              color: position === 'right' ? 'primary.contrastText' : 'text.primary',
              px: 2,
              py: 1
            }}
          >
            <Box sx={{ mb: 1 }}>
              <Link
                color="inherit"
                sx={{ cursor: 'pointer' }}
                variant="subtitle2"
              >
                {authorName}
              </Link>
            </Box>
            {contentType === 'image' && (
              <CardMedia
                onClick={(): void => { }}
                image={body}
                sx={{
                  height: 200,
                  width: 200
                }}
              />
            )}
            {contentType === 'text' && (
              <Typography
                color="inherit"
                variant="body1"
              >
                {body}
              </Typography>
            )}
          </Card>
          <Box
            sx={{
              display: 'flex',
              justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
              mt: 1,
              px: 2
            }}
          >
            <Typography
              color="text.secondary"
              noWrap
              variant="caption"
            >
              {updatedAt !== createdAt ? 'edited' : ''}
              {' '}
              {ago}
              {' '}
              ago
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

ChatMessage.propTypes = {
  authorAvatar: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right'])
};

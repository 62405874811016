import { ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Edit01 from '@untitled-ui/icons-react/build/esm/Edit01';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import PropTypes from 'prop-types';
import { FC, useCallback, useMemo, useState } from 'react';
import { Message } from 'src/API';
import ConfirmPopover from 'src/components/confirm-popover';
import { useAuth } from 'src/hooks/use-auth';
import { useGetUsers } from 'src/hooks/use-get-users-in-team';
import type { Participant } from 'src/types/chat';
import { UserRecords, getUserRecords } from '../team/team-table';
import { ChatMessage } from './chat-message';
import ChatNotification from './chat-notification';

// const getAuthor = (message: Message, participants: Participant[], user: User) => {
//   const participant = participants.find((participant) => participant.id === message.authorId);

//   // This should never happen
//   if (!participant) {
//     return {
//       name: 'Unknown',
//       avatar: '',
//       isUser: false
//     };
//   }

//   // Since chat mock db is not synced with external auth providers
//   // we set the user details from user auth state instead of thread participants
//   if (message.authorId === user.id) {
//     return {
//       name: 'Me',
//       avatar: user.avatar,
//       isUser: true
//     };
//   }

//   return {
//     avatar: participant!.avatar,
//     name: participant!.name,
//     isUser: false
//   };
// };


const getAuthor = (users: UserRecords, message: Message, user: any) => {
  const unknownUser = {
    name: 'Unknown',
    avatar: '',
    isUser: false
  }

  if (!message) return unknownUser;
  if (!message.owner) return unknownUser;
  const author = users[message?.owner]
  if (!author) return unknownUser;
  const isUser = author.id === user.id
  const name = isUser ? "Me" : author.account?.displayName || author.email
  return {
    ...author,
    isUser,
    name,
    avatar: author.account?.avatar || ''
  }

}

export const useChatPopper = () => {
  const [anchor, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const initMessage: Message | undefined = undefined
  const [message, setMessage] = useState<Message | undefined>(initMessage);

  const handleClick = (event, message: Message) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setMessage(message);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setMessage(initMessage);
  };

  return {
    anchor,
    open,
    message,
    handleClick,
    handleClose,
  };
}

interface ChatMessagesProps {
  messages: Message[];
  participants: Participant[];
  onEdit: (message: Message) => void
  onDelete: (message: Message) => void
}

export const ChatMessages: FC<ChatMessagesProps> = (props) => {
  const { messages, participants, onEdit, onDelete, ...other } = props;
  const { user } = useAuth()
  const teamUsers = useGetUsers()
  const users = useMemo(() => getUserRecords(teamUsers), [teamUsers])
  const popover = useChatPopper()

  const handleEditMessage = useCallback(() => {
    if (popover.message) {
      onEdit(popover.message)
    }
    popover.handleClose()
  }, [popover, onEdit])

  const handleDeleteMessage = useCallback(() => {
    if (popover.message) {
      onDelete(popover.message)
    }
    popover.handleClose()
  }, [popover, onDelete])

  return (
    <Stack
      spacing={2}
      sx={{ p: 3 }}
      {...other}
    >
      {messages.map((message) => {
        if (!message) return null
        if (message.type !== 'message') return (
          <ChatNotification key={message.id}
            message={message} />)
        const author = getAuthor(users, message, user)
        // console.log("author,users,message,user", author, users, message, user)
        return (
          <ChatMessage
            authorAvatar={author.avatar}
            authorName={author?.name}
            body={message.content}
            contentType={'text'}
            createdAt={message?.createdAt}
            updatedAt={message?.updatedAt}
            key={message.id + message.updatedAt}
            position={author.isUser ? 'right' : 'left'}
            isUser={author.isUser}
            onMenuOpen={(e) => popover.handleClick(e, message)}
          />
        );
      })}
      <Menu
        style={{ zIndex: 999999999 }}
        anchorEl={popover.anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 200
          }
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <MenuItem onClick={handleEditMessage}>
          <ListItemIcon>
            <Edit01></Edit01>
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        {/* <MenuItem > */}
        <ConfirmPopover
          title=''
          onConfirm={handleDeleteMessage}
          onCancel={() => {
            popover.handleClose()
          }}
          component={(handleClick) => (
            <MenuItem onClick={handleClick}>
              <ListItemIcon>
                <Trash01></Trash01>
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          )}
          content={<Typography>This will delete the <b>message</b>. This cannot be undone.</Typography>}
        />
        {/* </MenuItem> */}
      </Menu>
    </Stack>
  );
};

ChatMessages.propTypes = {
  // @ts-ignore
  messages: PropTypes.array,
  // @ts-ignore
  participants: PropTypes.array
};

import { Organization, UpdateOrganizationInput } from 'src/API';
import PlanvasBaseApi from '../api';
import { API, graphqlOperation } from 'aws-amplify';
import { updateOrganization } from 'src/graphql/mutations';

class OrganizationApi extends PlanvasBaseApi {
    organizations = "/organizations"
    organization = "/organization"

    async getOrganizations() {
        return super.get(this.organizations)
    }

    async updateOrganization(request: UpdateOrganizationInput) {
        return API.graphql(graphqlOperation(updateOrganization, { input: request }))
    }

    uploadIcon(file: FormData, organizationId: string) {
        return super.post(this.organization + '/icon/' + organizationId, file, {
            'Content-Type': 'multipart/form-data'
        })
    }
}

export const organizationApi = new OrganizationApi();

import React, { useCallback } from 'react';
import { OrganizationObject } from 'src/slices/user';
import { useDispatch, useSelector } from 'src/store';
import { thunks as userThunk } from 'src/thunks/user';


export const useAllOrganizations = () => {
    const userState = useSelector(s => s.user)
    const [organizations, setOrganizations] = React.useState<OrganizationObject[]>(userState.organizations);
    const dispatch = useDispatch();
    const fetchOrganizations = useCallback(async () => {
        dispatch(userThunk.getOrganizations())
    }, [dispatch])

    React.useEffect(() => {
        if (!userState._status.organizations?.hasFetched) {
            fetchOrganizations();
        }
        setOrganizations(userState.organizations);
    }, [userState, fetchOrganizations]);
    return {
        organizations,
    }
}
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { CreateUserMutation, GetUserQuery, ListUsersQuery, UpdateUserInput, UpdateUserMutation, User } from '../../API';
import { getUser, listUsers } from '../../graphql/queries';
import PlanvasBaseApi from '../api';
import { createUser, updateUser } from 'src/graphql/mutations';
import { UpdateType } from '../project';
import $log from 'src/log/log';

type GetUserRequest = { id: string };

export type CreateUserRequest = UpdateType<Omit<User, "id">>

export type UpdateUserRequest = UpdateUserInput

type QueryResult<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>

class UserApi extends PlanvasBaseApi {

    async getUser(request?: GetUserRequest): QueryResult<GetUserQuery> {
        if (!request?.id) {
            console.error("No user id provided");
            return
        }
        const response = await API.graphql<GraphQLQuery<ListUsersQuery>>(graphqlOperation(getUser, { id: request?.id }));
        return response
    }

    async createUser(request: CreateUserRequest): Promise<{ ok: boolean, result: any }> {
        return this.post('/user/create', request)
    }

    async updateUser(request: UpdateUserRequest): QueryResult<UpdateUserMutation> {
        const response = await API.graphql<GraphQLQuery<UpdateUserRequest>>(graphqlOperation(updateUser, {
            input: request,
        }));
        return response
    }

}

export const userApi = new UserApi();

import { Box, Divider } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { ChatContact } from 'src/hooks/use-contacts';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { CreateThreadParams, EntityType } from 'src/socket/chat/useChatSocket';
import { useDispatch } from 'src/store';
import { ChatComposerRecipients } from './chat-composer-recipients';
import { ChatMessageAdd } from './chat-message-add';

const useRecipients = () => {
  const [recipients, setRecipients] = useState<ChatContact[]>([]);

  const handleRecipientAdd = useCallback(
    (recipient: ChatContact): void => {
      setRecipients((prevState) => {
        const found = prevState.find((_recipient) => _recipient.id === recipient.id);

        if (found) {
          return prevState;
        }

        return [...prevState, recipient];
      });
    },
    []
  );

  const handleRecipientRemove = useCallback(
    (recipientId: string): void => {
      setRecipients((prevState) => {
        return prevState.filter((recipient) => recipient.id !== recipientId);
      });
    },
    []
  );

  return {
    handleRecipientAdd,
    handleRecipientRemove,
    recipients
  };
};

interface ChatComposerProps {
  createChannel?: (payload: CreateThreadParams) => Promise<string>
}

export const ChatComposer: FC<ChatComposerProps> = ({
  createChannel
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { teamId } = useParams()
  const { handleRecipientAdd, handleRecipientRemove, recipients } = useRecipients();

  const handleSend = useCallback(
    async (body: string): Promise<void> => {
      const recipientIds = recipients.map((recipient) => recipient.id);

      let threadId: string;
      try {
        // Handle send message and redirect to the new thread
        const channelId = await createChannel?.({
          name: undefined,
          members: recipients.filter(f => f.contactType === 'USER').map(f => f.id),
          organizations: recipients.filter(f => f.contactType === 'ORGANIZATION').map(f => f.role),
          message: {
            content: body
          },
          entity: {
            type: EntityType.chatMessage
          }
        })
        router.push(paths.chat.index(teamId!) + `?threadKey=${channelId}`);
      } catch (err) {
        console.error(err);
        return;
      }
    },
    [recipients, createChannel, router, teamId]
  );

  const canAddMessage = recipients.length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}
    >
      <ChatComposerRecipients
        onRecipientAdd={handleRecipientAdd}
        onRecipientRemove={handleRecipientRemove}
        recipients={recipients}
      />
      <Divider />
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <ChatMessageAdd
        disabled={!canAddMessage}
        onSend={handleSend}
      />
    </Box>
  );
};

import {
  Avatar,
  AvatarGroup,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import ConfirmPopover from 'src/components/confirm-popover';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { usePopover } from 'src/hooks/use-popover';
import type { Participant } from 'src/types/chat';

const getRecipients = (participants: Participant[], userId: string): Participant[] => {
  return participants.filter((participant) => participant.id !== userId);
};

const getDisplayName = (recipients: Participant[]): string => {
  return recipients
    .map((participant) => participant.name)
    .join(', ');
};

const getLastActive = (recipients: Participant[]): string | null => {
  const hasLastActive = recipients.length === 1 && recipients[0].lastActivity;

  if (hasLastActive) {
    return formatDistanceToNowStrict(recipients[0].lastActivity!, { addSuffix: true });
  }

  return null;
};

interface ChatThreadToolbarProps {
  participants?: Participant[];
  onLeaveChannel: () => void
}

export const ChatThreadToolbar: FC<ChatThreadToolbarProps> = (props) => {
  const { participants = [], onLeaveChannel, ...other } = props;
  const user = useMockedUser();
  const popover = usePopover<HTMLButtonElement>();

  // Maybe use memo for these values

  const recipients = getRecipients(participants, user.id);
  const displayName = getDisplayName(recipients);
  const lastActive = getLastActive(recipients);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          flexShrink: 0,
          minHeight: 64,
          px: 2,
          py: 1
        }}
        {...other}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <AvatarGroup
            max={2}
            sx={{
              ...(recipients.length > 1 && {
                '& .MuiAvatar-root': {
                  height: 30,
                  width: 30,
                  '&:nth-of-type(2)': {
                    mt: '10px'
                  }
                }
              })
            }}
          >
            {recipients.map((recipient) => (
              <Avatar
                key={recipient.id}
                src={recipient.avatar || undefined}
              />
            ))}
          </AvatarGroup>
          <div>
            <Typography variant="subtitle2">
              {displayName}
            </Typography>
            {lastActive && (
              <Typography
                color="text.secondary"
                variant="caption"
              >
                Last active
                {' '}
                {lastActive}
              </Typography>
            )}
          </div>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          {/* <IconButton>
            <SvgIcon>
              <PhoneIcon />
            </SvgIcon>
          </IconButton>
          <IconButton>
            <SvgIcon>
              <Camera01Icon />
            </SvgIcon>
          </IconButton> */}
          <Tooltip title="More options">
            <IconButton
              onClick={popover.handleOpen}
              ref={popover.anchorRef}
            >
              <SvgIcon>
                <DotsHorizontalIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Menu
        anchorEl={popover.anchorRef.current}
        keepMounted
        onClose={popover.handleClose}
        open={popover.open}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <SvgIcon>
              <SlashCircle01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Block" />
        </MenuItem> */}

        <ConfirmPopover
          title=''
          onConfirm={onLeaveChannel}
          onCancel={() => {
            popover.handleClose()
          }}
          component={(handleClick) => (
            <MenuItem onClick={handleClick}>
              <ListItemIcon>
                <SvgIcon>
                  <Trash02Icon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Leave channel" />
            </MenuItem>
          )}
          content={<Typography>Are you sure you want to leave?</Typography>}
        />

        {/* <MenuItem>
          <ListItemIcon>
            <SvgIcon>
              <ArchiveIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Archive" />
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemIcon>
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Mute" />
        </MenuItem> */}
      </Menu>
    </>
  );
};

ChatThreadToolbar.propTypes = {
  // @ts-ignore
  participants: PropTypes.array
};

import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { ListTeamsQuery, Team, UpdateTeamMutation, User } from '../../API';
import { customListTeams } from '../../graphql/customQueries';
import PlanvasBaseApi from '../api';
import { UpdateType } from '../project';
import { updateTeam } from 'src/graphql/mutations';

type GetTeamRequest = { nextToken?: string };
// type GetTeamResponse = Promise<Team[]>;
type ListResponse<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>
export interface ManagementRole {
    id: string
    name: string;
    access?: boolean
    description: string
}

type ApiResponse<T> = Promise<{
    errors?: string[]
    result: T
    ok: boolean
}>
type QueryResult<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>


export interface TeamInput {
    name: string
    teamSize?: number | null,
    phoneNumber?: string | null,
    valueProposition?: string | null,
    description?: string | null,
    managerGroups?: string[] | null
}

class TeamApi extends PlanvasBaseApi {

    async getTeams(request?: GetTeamRequest): ListResponse<ListTeamsQuery> {
        const response = await API.graphql<GraphQLQuery<ListTeamsQuery>>(graphqlOperation(customListTeams, {
            nextToken: request?.nextToken
        }));
        if (response.data?.listTeams?.nextToken) {
            const nextResponse = await this.getTeams({ nextToken: response.data.listTeams.nextToken });
            response.data.listTeams.items = response.data.listTeams.items.concat(nextResponse?.data?.listTeams?.items || []);
        }
        return response
    }
    teamEndpoint = "/permission/team"
    //   teamUsersEndpoint = "/permission/team/teamUsers"
    teamUserEndpoint = "/permission/team/user"
    invitedUserEndpoint = "/permission/team/user/invited"
    managementrole = "/permission/team/managementrole"

    /**
     * Gets team and users + invited
     */

    async updateTeam(request: UpdateType<Team>): QueryResult<UpdateTeamMutation> {
        const response = await API.graphql<GraphQLQuery<UpdateType<Team>>>(graphqlOperation(updateTeam, {
            input: request,
        }));
        return response
    }

    async getTeamUsers(teamId: string): Promise<ITeamUserResponse> {
        return super.get(this.teamEndpoint, { teamId })
    }

    async create(input: TeamInput) {
        return super.post(this.teamEndpoint, input)
    }
    async delete(teamId: string) {
        return super.delete(this.teamEndpoint, { teamId })
    }
    async addUserToTeam(teamId, email, role) {
        return super.post(this.teamUserEndpoint, { teamId, email, role })
    }
    async removeUserFromTeam(teamId, userId) {
        return super.delete(this.teamUserEndpoint, { teamId, userId })
    }
    async removeInvitedUser(invitedId) {
        return super.delete(this.invitedUserEndpoint, { invitedId })
    }
    async updateTeamPermissions(payload) {
        return super.put(this.teamUserEndpoint, payload)
    }

    async getManagementGroups(teamId: string): ApiResponse<{ groups: ManagementRole[] }> {
        return super.get(`${this.managementrole}/${teamId}`)
    }

    async addManagementGroupForTeam(payload: { teamId: string, role: string }) {
        return super.post(this.managementrole, payload)
    }

    async removeManagementForGroupTeam(payload: { teamId: string, role: string }) {
        return super.delete(this.managementrole, payload)
    }


}

export const teamApi = new TeamApi();



export interface ITeamUser {
    email: string,
    type: string,
    id: string
    role: string
    account?: User
}

export interface ITeamRoleUsers {
    admins: ITeamUser[],
    editors: ITeamUser[],
    viewers: ITeamUser[]
    invited: ITeamUser[]
    managers: ITeamUser[]
}


export interface ITeamUserResponse {
    ok: boolean,
    result: {
        users: ITeamRoleUsers
        accounts: User[]
    }
}

export interface IOrganizationUserResponse {
    ok: boolean,
    result: {
        users: ITeamRoleUsers,
        accounts: User[]
    }
}
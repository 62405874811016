import type { RouteObject } from 'react-router';
// import { Outlet } from 'react-router-dom';
// import HomePage from 'src/pages';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import CheckoutPage from 'src/pages/checkout';
import ContactPage from 'src/pages/contact';
// import PricingPage from 'src/pages/pricing';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
// import { componentsRoutes } from './components';
import { dashboardRoutes } from './dashboard';
import { legalRoutes } from './legal';
import { onboardingRoutes } from './onboarding';
import { organizationRoutes } from './organization';
import { teamRoutes } from './team';
import { trackRoutes } from './track';
import { userRoutes } from './user';

// const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));

export const routes: RouteObject[] = [
  // ...projectRoutes,
  ...teamRoutes,
  ...trackRoutes,
  // ...canvasRoutes,
  // ...settingRoutes,
  ...userRoutes,
  ...onboardingRoutes,
  ...organizationRoutes,
  ...legalRoutes,
  // {
  //   element: (
  //     <MarketingLayout>
  //       <Outlet />
  //     </MarketingLayout>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       element: <HomePage />
  //     },
  //     {
  //       path: 'pricing',
  //       element: <PricingPage />
  //     },
  //     ...componentsRoutes,
  //   ]
  // },
  // {
  //   index: true,
  //   path: '/auth/amplify/login',
  //   element: <AmplifyLoginPage />
  // },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  {
    path: 'checkout',
    element: <CheckoutPage />
  },
  {
    path: 'contact',
    element: <ContactPage />
  },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];


import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Seo } from 'src/components/seo';
import { useAllOrganizations } from 'src/hooks/use-all-organizations';
import { usePageView } from 'src/hooks/use-page-view';
import { OrganizationContainer } from 'src/sections/organization-settings/organization-container';
import OrganizationListCanvases from 'src/sections/organization-settings/organization-list-canvases';
import OrganizationListTeams from 'src/sections/organization-settings/organization-list-teams';
import OrganizationProfile from 'src/sections/organization-settings/organization-profile';
import OrganizationSidebar, { OrganizationSettingsCategories } from 'src/sections/organization-settings/organization-sidebar';
import { OrganizationObject } from 'src/slices/user';
import OrganizationListTemplates from '../../sections/organization-settings/organization-list-templates';
import { useSidebar } from '../dashboard/chat';
type OrganizationSettingProps = {
}

export const useOrganization = () => {
    const { organizationId } = useParams()
    const { organizations } = useAllOrganizations()
    const [organization, setOrganization] = useState<OrganizationObject | undefined>()
    useEffect(() => {
        const o = organizations?.find(o => o.id === organizationId)
        setOrganization(o)
    }, [organizationId, setOrganization, organizations])
    return organization

}


const OrganizationSetting: FC<OrganizationSettingProps> = (props) => {
    const organization = useOrganization()
    const rootRef = useRef<HTMLDivElement | null>(null);
    const sidebar = useSidebar();
    usePageView();
    const [searchParams, setSearchParams] = useSearchParams()
    const view = searchParams.get('category')
    if (!view) {
        setSearchParams({ category: OrganizationSettingsCategories.templates })
    }

    return (
        <>
            <Seo title={organization?.name} />
            <Divider></Divider>
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.paper',
                    flex: '1 1 auto',
                    overflow: 'hidden',
                    position: 'relative'
                }}
            >
                <Box
                    ref={rootRef}
                    sx={{
                        bottom: 0,
                        display: 'flex',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0
                    }}
                >
                    <OrganizationSidebar
                        container={rootRef.current}
                        open={sidebar.open}
                        onClose={sidebar.handleClose}
                    ></OrganizationSidebar>

                    <OrganizationContainer>
                        {view === OrganizationSettingsCategories.canvases && <OrganizationListCanvases></OrganizationListCanvases>}
                        {view === OrganizationSettingsCategories.templates && <OrganizationListTemplates></OrganizationListTemplates>}
                        {view === OrganizationSettingsCategories.teams && <OrganizationListTeams></OrganizationListTeams>}
                        {view === OrganizationSettingsCategories.profile && <OrganizationProfile></OrganizationProfile>}
                    </OrganizationContainer>
                </Box>
            </Box>
        </>
    )
}
export default OrganizationSetting;
import { Button, Card, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import PropTypes from 'prop-types';
import { FC, useCallback, useRef } from 'react';
import TeamInformation, { TeamSubmitData } from 'src/pages/settings/team-information';

interface TeamStepProps {
    onNext?: (data: TeamSubmitData) => void;
    onBack?: () => void;
    team?: TeamSubmitData
    // ref: any
}

export const TeamStep: FC<TeamStepProps> = (props) => {
    const { onBack, onNext, team, ...other } = props;
    const handleCreateTeam = useCallback((data: TeamSubmitData) => {
        onNext?.(data)
    }, [onNext])
    const ref = useRef<HTMLFormElement | null>()

    return (
        <Stack
            spacing={3}
            {...other}
        >
            <Stack spacing={2}>
                <Card
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        p: 2,
                    }}
                >
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">Team information</Typography>
                        <Typography>
                            To provide you with the best possible help, we kindly request some information. <br /><br />
                            Though not all are required, these details can potentially connect you with support organizations and investors. <br /><br />
                            Let's work together to pave the way to success!</Typography>
                        <Divider></Divider>
                        <TeamInformation
                            forwardRef={ref}
                            team={team}
                            onSubmit={handleCreateTeam}
                        ></TeamInformation>
                    </Stack>
                </Card>
            </Stack>
            <Stack spacing={2}
                direction={'row'}>
                <Button
                    endIcon={(
                        <SvgIcon>
                            <ArrowLeftIcon />
                        </SvgIcon>
                    )}
                    onClick={onBack}
                    variant="contained"
                >
                    Back
                </Button>
                <Button
                    // disabled={!hasSubmitted}
                    endIcon={(
                        <SvgIcon>
                            <ArrowRightIcon />
                        </SvgIcon>
                    )}
                    onClick={() => {
                        ref.current?.requestSubmit()
                    }}
                    variant="contained"
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
};

TeamStep.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Email, Label } from 'src/types/mail';
import { objFromArray } from 'src/utils/obj-from-array';

export enum ConnectionStatus {
    'connected' = 'connected',
    'connecting' = 'connecting',
    'disconnected' = 'disconnected'
}
export interface RemoveStatus {
    status: keyof typeof ConnectionStatus,
    sockets: (string | undefined)[]
}
export interface ConnectionState {
    status: keyof typeof ConnectionStatus
    sockets: (string | undefined)[]
}
const initialState: ConnectionState = {
    status: 'disconnected',
    sockets: []
};

const reducers = {
    setConnectionStatus: (state: ConnectionState, action: PayloadAction<ConnectionState>) => {
        state.status = action.payload.status;
        state.sockets = action.payload.sockets.filter(f => f)
    },
    removeStatus: (state: ConnectionState, action: PayloadAction<RemoveStatus>) => {
        state.status = action.payload.status;
        if(action.payload.sockets.length === 0){
            state.sockets = []
        }
        state.sockets = state.sockets.filter(f => {
            if (!f) return false
            return !action.payload.sockets.includes(f)
        })
        console.log(action.payload)
    }
};

export const slice = createSlice({
    name: 'connection',
    initialState,
    reducers
});

export const { reducer } = slice;
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { DeleteTemplateInput, DeleteTemplateMutation, GetTemplateQuery, ListTemplatesQuery, Template, UpdateTemplateInput, UpdateTemplateMutation } from '../../API';
import { listTemplates } from '../../graphql/queries';
import PlanvasBaseApi from '../api';
import { WithRequired } from 'src/slices/kanban';
import { deleteTemplate, updateTemplate } from 'src/graphql/mutations';

type GetCanvasRequest = {};
type CloneCanvasRequest = { canvasId: string }
type DeleteCanvasRequest = { canvasId: string }
export type CreateTemplateRequest = { name: string, canvasId: string, description: string }
// type GetCanvasResponse = Promise<Canvas[]>;
type ListResponse<T> = Promise<GraphQLResult<GraphQLQuery<T>> | undefined>
class TemplateApi extends PlanvasBaseApi {
    templateEndpoint = "/canvas/template"
    async getTemplates(request?: GetTemplateQuery): ListResponse<ListTemplatesQuery> {
        const fn = (options) => API.graphql<GraphQLQuery<ListTemplatesQuery>>(graphqlOperation(listTemplates, options));
        const response = this.recursiveGet('listTemplates', fn, request)
        return response
    }

    // async updateTemplate(request: WithRequired<Template, 'id'>) {
    async updateTemplate(request: UpdateTemplateInput) {
        return API.graphql<GraphQLQuery<UpdateTemplateMutation>>(graphqlOperation(updateTemplate, {
            input: request
        }))
    }
    async updateTemplates(request: UpdateTemplateInput[]) {
        return Promise.all(request.map(d => this.updateTemplate(d)))
    }

    async deleteTemplate(request: DeleteTemplateInput) {
        return API.graphql<GraphQLQuery<DeleteTemplateMutation>>(graphqlOperation(deleteTemplate, {
            input: request
        }))
    }

    async createTemplate(request: CreateTemplateRequest) {
        return this.post(this.templateEndpoint, request)
    }

    // async cloneCanvas(request: CloneCanvasRequest): Promise<any> {
    //     return super.post(this.canvasEndpoint + "/clone", request)
    // }

    // async deleteCanvas(request: DeleteCanvasRequest): Promise<any> {
    //     return super.delete(this.canvasEndpoint, request)
    // }
}

export const templateApi = new TemplateApi();

import { ConnectionState, RemoveStatus, slice } from 'src/slices/connection-status';
import type { AppThunk } from 'src/store';

const setConnection = (state: ConnectionState): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnectionStatus(state));
};

const removeStatus = (state: RemoveStatus): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.removeStatus(state));
};

export const thunks = {
  setConnection,
  removeStatus
};

import { Dialog, DialogContent, DialogContentText, DialogTitle, Icon, Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';
import Disc01 from '@untitled-ui/icons-react/build/esm/ZapOff';
import { useState } from 'react';
import { useSelector } from 'src/store';

enum StatusIndicatorColors {
    "Critical" = "#FF3838",
    "Serious" = "#FFB302",
    "Caution" = "#FCE83A",
    "Normal" = "#56F000",
    "Standby" = "#2DCCFF",
    "Off" = "#A4ABB6",
}


const StyledContainer = styled('div')(({ theme }) => ({
    position: 'fixed', top: 0, right: 0, zIndex: 10000,
    padding: 3
}))


type StyledIndicatorProps = {
    color: keyof typeof StatusIndicatorColors;
}

const Indicator = styled('div')<StyledIndicatorProps>(({ color }) => ({
    width: 10,
    height: 10,
    background: StatusIndicatorColors[color],
    borderRadius: '50%',
}));

const StyledIndicator: React.FC = () => {
    const connection = useSelector(state => state.connection)
    const { status, sockets } = connection
    const [dialogOpen, setDialogOpen] = useState(false)

    // useEffect(() => {
    //     if (status !== 'connecting') return
    //     if (dialogOpen === true) return
    //     setDialogOpen(true)
    // }, [status, dialogOpen])

    let indicator = <Indicator color="Critical"></Indicator>
    if (status === 'connected') {
        indicator = <Indicator color={'Normal'}></Indicator>
    }
    if (status === 'disconnected') {
        indicator = <Indicator color={'Off'}></Indicator>
    }
    if (status === 'connecting') {
        indicator = <Indicator color={'Critical'}></Indicator>
    }
    if (sockets.length === 0 && status != 'connecting') return null

    return (
        <StyledContainer title={sockets.join(", ")}>
            {indicator}
            <Dialog open={status === 'connecting'}>
                <DialogTitle>Connection problem</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack
                            direction={'column'}
                            alignItems={'center'}
                        >
                            <Icon style={{ width: 100, height: 100 }}>
                                <Disc01
                                    style={{
                                        transform: 'scale(2)',
                                        translate: "0px 30px",
                                        color: StatusIndicatorColors.Critical
                                    }}
                                ></Disc01>
                            </Icon>
                            <Typography>We have trouble connecting you.</Typography>
                            <Typography>The indicator in the top right turns green when you are connected </Typography>
                        </Stack>
                    </DialogContentText>
                    {/* <DialogActions>
                        <Button autoFocus
                            onClick={() => setDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions> */}
                </DialogContent>
            </Dialog>
        </StyledContainer>
    );
};

export default StyledIndicator;

import type { Theme } from '@mui/material';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import PropTypes from 'prop-types';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { MessageChannel } from 'src/API';
import { Scrollbar } from 'src/components/scrollbar';
import { useGetUsers } from 'src/hooks/use-get-users-in-team';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useSelector } from 'src/store';
import type { Thread } from 'src/types/chat';
import { useAllOrganizations } from '../../../hooks/use-all-organizations';
import { getUserRecords } from '../team/team-table';
import { ChatSidebarSearch } from './chat-sidebar-search';
import { ChatThreadItem } from './chat-thread-item';

const useThreads = (): { byId: Record<string, Thread>, allIds: string[] } => {
  return useSelector((state) => state.chat.threads);
};

const useCurrentThreadId = (): string | undefined => {
  return useSelector((state) => state.chat.currentThreadId);
};

interface ChatSidebarProps {
  container?: HTMLDivElement | null;
  onClose?: () => void;
  open?: boolean;
}




export const ChatSidebar: FC<ChatSidebarProps> = (props) => {
  const { container, onClose, open, ...other } = props;
  const { organizations } = useAllOrganizations()
  const { teamId } = useParams()
  const users = useGetUsers({ teamId })
  const userRecords = getUserRecords(users)
  const router = useRouter();
  const threads = useThreads();
  const currentThreadId = useCurrentThreadId();
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<MessageChannel[]>([]);

  const getSearchResult = useCallback((threads, query: string): MessageChannel[] => {
    const channels: MessageChannel[] = threads.allIds.map((threadId) => threads.byId[threadId])
    const contacts: MessageChannel[] = channels.filter(f => {

      const orgs = organizations.filter(o => f.organizations?.includes(o.role))
        .filter(f => f.description?.toLowerCase().includes(query.toLowerCase()) || f.name.toLowerCase().includes(query.toLowerCase()))

      const members = f.members?.map(u => u ? userRecords[u] : undefined).filter(f => f)
        .filter(f => f?.account?.displayName?.includes(query.toLowerCase()) || f?.email.includes(query.toLowerCase()))

      return orgs.length > 0 || (members && members.length > 0)
    })
    return contacts
  }, [userRecords, organizations])



  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleCompose = useCallback(
    (): void => {
      router.push(paths.chat.index(teamId!) + '?compose=true');
    },
    [router, teamId]
  );

  useEffect(() => {

    const result = getSearchResult(threads, searchQuery)
    setSearchResults(result)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, threads, setSearchResults])

  const handleSearchChange = useCallback(event => {
    // setSearch(event.target.value)
    setSearchQuery(event.target.value)
  }, [setSearchQuery]);

  const handleSearchClickAway = useCallback(
    (): void => {
      if (searchFocused) {
        setSearchFocused(false);
        setSearchQuery('');
      }
    },
    [searchFocused]
  );

  const handleSearchFocus = useCallback(
    (): void => {
      setSearchFocused(true);
    },
    []
  );

  const handleSearchSelect = useCallback(
    (contact: MessageChannel): void => {
      // We use the contact ID as a thread key
      const threadKey = contact.id

      setSearchFocused(false);
      setSearchQuery('');

      router.push(paths.chat.index(teamId!) + `?threadKey=${threadKey}`);
    },
    [router, teamId]
  );

  const handleThreadSelect = useCallback(
    (threadId: string): void => {
      if (!threadId) {
        router.push(paths.chat.index(teamId!));
      } else {
        router.push(paths.chat.index(teamId!) + `?threadKey=${threadId}`);
      }
    },
    [router, teamId]
  );
  const content = (
    <div>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ p: 2 }}
      >
        <Typography
          variant="h5"
          sx={{ flexGrow: 1 }}
        >
          Chats
        </Typography>
        <Button
          onClick={handleCompose}
          startIcon={(
            <SvgIcon>
              <PlusIcon />
            </SvgIcon>
          )}
          variant="contained"
        >
          Group
        </Button>
        {!mdUp && (
          <IconButton onClick={onClose}>
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>
      <ChatSidebarSearch
        isFocused={searchFocused}
        onChange={handleSearchChange}
        onClickAway={handleSearchClickAway}
        onFocus={handleSearchFocus}
        onSelect={handleSearchSelect}
        query={searchQuery}
        results={searchResults}
        userRecords={userRecords}
        organizations={organizations}
      />
      <Box sx={{ display: searchFocused ? 'none' : 'block' }}>
        <Scrollbar>
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              m: 0,
              p: 2
            }}
          >
            {threads.allIds.map((threadId) => (
              <ChatThreadItem
                userRecords={userRecords}
                organizations={organizations}
                active={currentThreadId === threadId}
                key={threadId}
                onSelect={(): void => handleThreadSelect(threadId)}
                thread={threads.byId[threadId]}
              />
            ))}
          </Stack>
        </Scrollbar>
      </Box>
    </div>
  );

  if (mdUp) {
    return (
      <Drawer
        anchor="left"
        open={open}
        PaperProps={{
          sx: {
            position: 'relative',
            width: 380
          }
        }}
        SlideProps={{ container }}
        variant="persistent"
        {...other}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      hideBackdrop
      ModalProps={{
        container,
        sx: {
          pointerEvents: 'none',
          position: 'absolute'
        }
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: 380,
          pointerEvents: 'auto',
          position: 'absolute'
        }
      }}
      SlideProps={{ container }}
      variant="temporary"
      {...other}
    >
      {content}
    </Drawer>
  );
};

ChatSidebar.propTypes = {
  container: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

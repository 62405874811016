
import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
const InnovationReadiness = lazy(() => import('src/pages/track/innovation-readiness'));

export const trackRoutes: RouteObject[] = [
    {
        path: "track",
        children: [
            {
                path: "innovation-readiness",
                element: <DashboardLayout><InnovationReadiness /></DashboardLayout>,
            },
        ],
    },
]

import { useDispatch, useSelector } from 'src/store';
import { QueryInitiator, QueryInitiatorCanvas, useSocket } from '../useSocket';
import { useEffect } from 'react';
import { Task } from 'src/pages/canvas/canvas-tile-box';
import { AddCanvasTileInput, UpdateCanvasTileInput, DeleteCanvasTileInput } from '../../slices/canvas';
import { slice as canvasSlice } from '../../slices/canvas';

export type TileAddPayload = {
    canvasId: any;
    teamId: any;
}

// export interface TileUpdatePositionPayload {
//     id: string
//     position: {
//         x: number;
//         y: number;
//         w: number;
//         h: number;
//     },
// }

export interface TileUpdate {
    id: string
    position?: {
        x: number;
        y: number;
        w: number;
        h: number;
    },
    title?: string,
    info?: string,
    tasks?: Task[]
}

enum CanvasEvents {
    TileChange = "tile:change",
    TileAdd = "tile:add",
    TileDelete = "tile:remove",
    CanvasTileTaskDelete = "canvastile:task:remove",
    CanvasTileTaskAdd = 'tile:task:create',
    CanvasTileTaskUpdate = 'canvastile:task:change',

    ReceiveTileChange = "receive:tile:change",
    ReceiveTileAdd = "receive:tile:add",
    ReceiveTileDelete = "receive:tile:remove",
}

const useCanvasListenForChange = (addTiles, updateTiles, deleteTiles) => {
    const changedColumns = useSelector(s => s.canvas.changedTileQueue);
    useEffect(() => {
        if (changedColumns.length > 0) {
            const add = changedColumns.filter(c => c.action === 'CREATE');
            const update = changedColumns.filter(c => c.action === 'UPDATE');
            const delete_ = changedColumns.filter(c => c.action === 'DELETE');
            addTiles(add)
            updateTiles(update)
            deleteTiles(delete_)
        }
        return () => { }
    }, [changedColumns, addTiles, updateTiles, deleteTiles]);

}


export const useCanvasSocket = (props: { queryInitiator: QueryInitiatorCanvas }) => {
    const { socket, sendData } = useSocket(props);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!socket) return

        socket.on(CanvasEvents.ReceiveTileChange, (e) => {
            console.log("ReceiveTileChange", e)
            dispatch(canvasSlice.actions.receiveCanvasTileChange({ item: e.item, changeId: e.changeId }))
        })
        socket.on(CanvasEvents.ReceiveTileAdd, (e) => {
            console.log("ReceiveTileAdd", e)
            dispatch(canvasSlice.actions.receiveCanvasTileAdd({ item: e.item, changeId: e.changeId }))
        })
        socket.on(CanvasEvents.ReceiveTileDelete, (e) => {
            dispatch(canvasSlice.actions.receiveCanvasTileDelete({ item: e.item, changeId: e.changeId }))
        })
        return () => {
            socket.off(CanvasEvents.ReceiveTileChange)
            socket.off(CanvasEvents.ReceiveTileAdd)
            socket.off(CanvasEvents.ReceiveTileDelete)
        }
    })

    useCanvasListenForChange(
        (tiles: AddCanvasTileInput[]) => {
            tiles.forEach(t => {
                onTileAdd(t.tile, t.changeId)
            })
        },
        (tiles: UpdateCanvasTileInput[]) => {
            tiles.forEach(t => {
                onTileUpdate(t.tile, t.changeId)
            })
        },
        (tiles: DeleteCanvasTileInput[]) => {
            tiles.forEach(t => {
                onTileDelete(t.tile.id, t.changeId)
            })
        },
    )

    const onTileUpdate = (tile: TileUpdate[], changeId: string) => {
        if (!tile) return
        addToSentList(changeId) && sendData(CanvasEvents.TileChange, tile, changeId)
    }
    const onTileAdd = (tile: TileAddPayload, changeId: string) => {
        if (!tile) return
        addToSentList(changeId) && sendData(CanvasEvents.TileAdd, tile, changeId)
    }
    const onTileDelete = (id: string, changeId: string) => {
        if (!id) return
        addToSentList(changeId) && sendData(CanvasEvents.TileDelete, { id }, changeId)
    }

    const sentList = useSelector(state => state.canvas.sentQueue)
    /**
     * do not send data to server if it is already sent
     * @param changeId 
     * @returns 
     */
    const addToSentList = (changeId: string) => {
        if (sentList.indexOf(changeId) === -1) {
            dispatch(canvasSlice.actions.addToSentList(changeId))
            return true
        }
        return false
    }
}
import { CloseSharp } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { ChatThread } from 'src/sections/dashboard/chat/chat-thread';
import { CreateMessageParams, DeleteMessageParams, UpdateMessageParams } from 'src/socket/chat/useChatSocket';

type SideMenuProps = {
    onClose: () => void,
    open: boolean,
    channel: string
    onCreateMessage: (data: CreateMessageParams) => any
    onDeleteMessage: (data: DeleteMessageParams) => any
    onUpdateMessage: (data: UpdateMessageParams) => any
}

export default function SideMenu({ onClose, open, channel, onCreateMessage, onDeleteMessage, onUpdateMessage }: SideMenuProps) {
    const handleSend = useCallback(async (body: CreateMessageParams): Promise<void> => {
        onCreateMessage(body)
    }, [onCreateMessage]);

    return (
        <Drawer
            open={open}

            ModalProps={{
                BackdropProps: {
                    invisible: true,
                },
                onClose: (event, reason) => {
                    onClose()
                },
                sx: { zIndex: 1400 }
            }}
            PaperProps={{
                elevation: 24,
                sx: {
                    maxWidth: '100%',
                    width: 440
                }
            }}
            anchor="right"

        >
            <Box
                flexDirection={'row-reverse'}
                alignItems={'flex-end'}
            >
                <IconButton onClick={onClose}>
                    <CloseSharp></CloseSharp>
                </IconButton>
            </Box>
            <ChatThread
                threadKey={channel}
                showToolbar={false}
                createMessage={handleSend}
                deleteMessage={onDeleteMessage}
                updateMessage={onUpdateMessage}

            ></ChatThread>
        </Drawer>
    )
}

import { useCallback, useEffect, ChangeEvent } from 'react';
import { thunks } from 'src/thunks/team';
import { useDispatch, useSelector } from '../store/index';
import { useState } from 'react';
import { getUserList } from 'src/sections/dashboard/team/team-table';
import { ITeamUser } from 'src/api/team';
import { Organization } from 'src/API';
import { useGetUsers } from './use-get-users-in-team';
import { useAllOrganizations } from 'src/hooks/use-all-organizations';
import { useParams } from 'react-router';


export interface ChatITeamUser extends ITeamUser {
    contactType: 'USER'
}
export interface ChatOrganization extends Organization {
    contactType: 'ORGANIZATION'
}

export type ChatContact = ChatITeamUser | ChatOrganization;

export const useGetContacts = () => {
    const [contacts, setContacts] = useState<ChatContact[]>([])
    const [searchResults, setSearchResults] = useState<ChatContact[]>([])
    const { teamId } = useParams()
    const userResponse = useGetUsers({ teamId })
    const [search, setSearch] = useState<string>('')
    const { organizations } = useAllOrganizations()

    const handleGetContacts = useCallback(() => {
        const users: ChatITeamUser[] = getUserList(userResponse).map((user: any) => {
            return {
                ...user,
                contactType: 'USER'
            }
        })

        const chatOrganizations: ChatOrganization[] = [...organizations].map((organization: any) => {
            return {
                ...organization,
                contactType: 'ORGANIZATION'
            }
        })

        setContacts([...users, ...chatOrganizations])

    }, [organizations, userResponse, setContacts]);


    useEffect(() => {
        if (!search) {
            setSearchResults(contacts);
            return;
        }

        try {
            const hits = contacts.filter(u => {
                if (u.contactType === 'ORGANIZATION') {
                    return (u as ChatOrganization).description?.toLowerCase().includes(search.toLowerCase())
                } else if (u.contactType === 'USER') {
                    return u.email.toLowerCase().includes(search.toLowerCase()) || u.account?.displayName?.toLowerCase().includes(search.toLowerCase())
                }
                console.log('u', u)
                return false
            })
            setSearchResults(hits);
        } catch (err) {
            console.error(err);
        }
    }, [search, contacts])

    useEffect(() => {
        handleGetContacts();
        return () => {
            // optional cleanup function here
        };
    }, [handleGetContacts]);

    return { searchResults, setSearch }
};


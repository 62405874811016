import { useCallback, useEffect } from 'react';
import { useAuth } from 'src/hooks/use-auth';
import { RootState, useDispatch, useSelector } from 'src/store';
import { thunks as userThunk } from 'src/thunks/user';
import { useState } from 'react';

export const useAccount = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.user.account);
    const { user } = useAuth()
    const [hasFetched, setHasFetched] = useState(false);
    const handleGetAccount = useCallback(() => {
        if (!user?.id) return;
        if (account) return
        dispatch(userThunk.getAccount(user!.id))
        setTimeout(() => setHasFetched(true), 200)
    }, [dispatch, user, account]);

    useEffect(() => {
        handleGetAccount();
        return () => {
            // optional cleanup function here
        };
    }, [dispatch, handleGetAccount, account]);

    return { hasFetched, account: account, email: user?.email, id: user?.id }
};
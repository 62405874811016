import { MenuItem, TextField } from '@mui/material';
import { Stack } from '@mui/system';

import { useState } from 'react';
import { SwatchesPicker } from 'react-color';
type Option = {
    name: string;
    displayName: string;
    options?: { name: string; displayName: string }[];
    default: string;
    value?: string
    type: "select" | "color";
};

type Options = {
    sex: Option;
    faceColor: Option;
    earSize: Option;
    hairColor: Option;
    hairStyle: Option;
    hatColor: Option;
    hatStyle: Option;
    eyeStyle: Option;
    glassesStyle: Option;
    noseStyle: Option;
    mouthStyle: Option;
    shirtStyle: Option;
    shirtColor: Option;
    bgColor: Option;
};

const options: Option[] = [
    // {
    //     name: "sex",
    //     displayName: "Sex",
    //     options: [
    //         { name: "man", displayName: "Man" },
    //         { name: "woman", displayName: "Woman" },
    //     ],
    //     default: "man",
    //     type: "select",
    // },
    {
        name: "faceColor",
        displayName: "Face Color",
        default: "red",
        type: "color",
    },
    {
        name: "earSize",
        displayName: "Ear Size",
        options: [
            { name: "small", displayName: "Small" },
            { name: "big", displayName: "Big" },
        ],
        default: "small",
        type: "select",
    },
    {
        name: "hairColor",
        displayName: "Hair Color",
        default: "black",
        type: "color",
    },
    {
        name: "hairStyle",
        displayName: "Hair Style",
        options: [
            { name: "normal", displayName: "Normal" },
            { name: "thick", displayName: "Thick" },
            { name: "mohawk", displayName: "Mohawk" },
            { name: "womanLong", displayName: "Long Hair" },
            { name: "womanShort", displayName: "Short Hair" },
        ],
        default: "normal",
        type: "select",
    },
    {
        name: "hatColor",
        displayName: "Hat Color",
        default: "black",
        type: "color",
    },
    {
        name: "hatStyle",
        displayName: "Hat Style",
        options: [
            { name: "none", displayName: "No Hat" },
            { name: "beanie", displayName: "Beanie" },
            { name: "turban", displayName: "Turban" },
        ],
        default: "none",
        type: "select",
    },
    {
        name: "eyeStyle",
        displayName: "Eye Style",
        options: [
            { name: "circle", displayName: "Circle" },
            { name: "oval", displayName: "Oval" },
            { name: "smile", displayName: "Smile" },
        ],
        default: "oval",
        type: "select",
    },
    {
        name: "glassesStyle",
        displayName: "Glasses Style",
        options: [
            { name: "none", displayName: "No Glasses" },
            { name: "round", displayName: "Round Glasses" },
            { name: "square", displayName: "Square Glasses" },
        ],
        default: "none",
        type: "select",
    },
    {
        name: "noseStyle",
        displayName: "Nose Style",
        options: [
            { name: "short", displayName: "Short" },
            { name: "long", displayName: "Long" },
            { name: "round", displayName: "Round" },
        ],
        default: "short",
        type: "select",
    },
    {
        name: "mouthStyle",
        displayName: "Mouth Style",
        options: [
            { name: "laugh", displayName: "Laugh" },
            { name: "smile", displayName: "Smile" },
            { name: "peace", displayName: "Peace" },
        ],
        default: "laugh",
        type: "select",
    },
    {
        name: "shirtStyle",
        displayName: "Shirt Style",
        options: [
            { name: "hoody", displayName: "Hoodie" },
            { name: "short", displayName: "Short-Sleeve" },
            { name: "polo", displayName: "Polo" },
        ],
        default: "short",
        type: "select",
    },
    {
        name: "shirtColor",
        displayName: "Shirt Color",
        default: "black",
        type: "color",
    },
    {
        name: "bgColor",
        displayName: "Background Color",
        default: "white",
        type: "color",
    },
];




const ColorProperty = ({ option, onChangeColor }: { option: Option, onChangeColor: (color: String) => void }) => {
    const [Color, setColor] = useState(option.default as any)
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(s => !s)
    };

    const handleClose = (c) => {
        setOpen(false)
        setColor(c)
        onChangeColor(c.hex)
    };

    return (
        <>
            <div style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            }}
                onClick={handleClick}
            >
                <div style={{
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: Color?.hex || option.value,
                }} />
            </div>
            {
                open ? (
                    <SwatchesPicker
                        onChange={handleClose}
                    ></SwatchesPicker>
                ) : undefined
            }
        </>)
}

const OptionSelector = ({ option, onChange }: { option: Option, onChange: (key, value) => void }) => {
    return (
        <>
            <TextField
                select
                onChange={(e) => {
                    onChange(option.name, e.target.value)
                }}
                value={option.value}
                style={{ width: 140 }}
            >
                {
                    option.options?.map(value => (
                        <MenuItem key={option.name + value.name}
                            value={value.name || ''}
                        >
                            {value.displayName}
                        </MenuItem>
                    ))
                }
            </TextField>

        </>)
}


function AvatarOptions({
    onChange,
    config
}) {


    options.forEach(option => {
        option.value = config[option.name]
    })

    const handleChange = (key, value) => {
        onChange(key, value);
    };

    return (
        <div style={{ height: 400, overflow: "scroll" }}>
            {options.map((o: Option) => (
                <Stack
                    key={o.name}
                    direction="row"
                    spacing={3}
                    sx={{ p: 1 }}
                    justifyContent={"space-between"}
                >
                    <div>{o.displayName}</div>
                    <div>
                        {
                            o.type === 'select' ? (
                                <OptionSelector
                                    option={o}
                                    onChange={handleChange}
                                />)
                                : <ColorProperty
                                    option={o}
                                    onChangeColor={(color) => onChange(o.name, color)}
                                ></ColorProperty>
                        }
                    </div>
                </Stack>

            ))
            }
        </div >
    );
}

export default AvatarOptions;
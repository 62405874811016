import { slice } from 'src/slices/team';
import { slice as kanbanSlice } from 'src/slices/kanban';

import type { AppThunk } from 'src/store';
import { teamApi, TeamInput } from '../api/team/index';
import { Team } from 'src/API';
import { thunks as projectThunks } from './project';
import { UpdateType } from 'src/api/project';
import { useParams } from 'react-router';

type GetTeamParams = {
    request?: {}
}

type GetTeamUsersParams = {
    teamId: string
}



type DeleteTeamParams = {
    teamId: string
}

type AddUserToTeamParams = {
    teamId: string
    email: string
    role: string
}

type RemoveUserFromTeamParams = {
    teamId: string
    userId: string
}

type RemoveInvitedUserParams = {
    invitedId: string
}

type UpdateTeamPermissionsParams = {
    payload: any
}

type GetManagementGroupsParams = {
    teamId: string
}

type AddManagementGroupForTeamParams = {
    teamId: string
    role: string
}

type RemoveManagementForGroupTeamParams = {
    teamId: string
    role: string
}

type SetCurrentTeamParams = { team: Team }

const setTeam = (params: SetCurrentTeamParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setTeam(params.team));
};

const getTeams = (payload?: { teamId?: string }): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.getTeams()
    const teams = response?.data?.listTeams?.items
    dispatch(slice.actions.getTeams({ teamId: payload?.teamId, teams: teams as Team[] || [] }));
    dispatch(projectThunks.setInitialProjectFromTeams(teams as Team[] || []));
};

const updateTeam = (params: UpdateType<Team>): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.updateTeam(params)
    dispatch(slice.actions.updateTeam(response));
}
const getTeamUsers = (params: GetTeamUsersParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.getTeamUsers(params.teamId)
    dispatch(slice.actions.getTeamUsers(response));
};

const createTeam = (params: TeamInput): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.create(params)
    dispatch(slice.actions.createTeam(response));
};

const deleteTeam = (params: DeleteTeamParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.delete(params.teamId)
    dispatch(slice.actions.deleteTeam(response));
};

const addUserToTeam = (params: AddUserToTeamParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.addUserToTeam(params.teamId, params.email, params.role)
    dispatch(slice.actions.addUserToTeam(response));
};

const removeUserFromTeam = (params: RemoveUserFromTeamParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.removeUserFromTeam(params.teamId, params.userId)
    dispatch(slice.actions.removeUserFromTeam(response));
};

const removeInvitedUser = (params: RemoveInvitedUserParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.removeInvitedUser(params.invitedId)
    dispatch(slice.actions.removeInvitedUser(response));
};

const updateTeamPermissions = (params: UpdateTeamPermissionsParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.updateTeamPermissions(params.payload)
    dispatch(slice.actions.updateTeamPermissions(response));
};

const getManagementGroups = (params: GetManagementGroupsParams): AppThunk => async (dispatch): Promise<void> => {
    const response = await teamApi.getManagementGroups(params.teamId)
    dispatch(slice.actions.getManagementGroups({ managementRoles: response?.result?.groups }));
    dispatch(slice.actions.setSuccess({
        attribute: 'teamManagementGroups',
        message: 'Team management groups fetched successfully'
    }))
};

const addManagementGroupForTeam = (payload: { teamId: string, role: string }): AppThunk => async (dispatch): Promise<void> => {
    try {
        const response = await teamApi.addManagementGroupForTeam(payload)
        if (response.status >= 200 && response.status < 300) {
            // dispatch(thunks.getManagementGroups({ teamId: payload.teamId }));
            dispatch(thunks.getTeams());

        } else {
            dispatch(slice.actions.addManagementGroupForTeamFailure(response.data.errors))
        }
    } catch (error) {
        dispatch(slice.actions.addManagementGroupForTeamFailure(error.message))
    }
}

const removeManagementForGroupTeam = (payload: { teamId: string, role: string }): AppThunk => async (dispatch): Promise<void> => {
    try {
        const response = await teamApi.removeManagementForGroupTeam(payload)
        dispatch(slice.actions.removeManagementForGroupTeamSuccess(response.data))
        dispatch(thunks.getTeams());

    } catch (error) {
        dispatch(slice.actions.removeManagementForGroupTeamFailure(error.message))
    }
}



export const thunks = {
    setTeam,
    updateTeam,
    getTeams,
    getTeamUsers,
    createTeam,
    deleteTeam,
    addUserToTeam,
    removeUserFromTeam,
    removeInvitedUser,
    updateTeamPermissions,
    getManagementGroups,
    addManagementGroupForTeam,
    removeManagementForGroupTeam,
};
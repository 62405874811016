import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Team } from 'src/API';
import { getCustomerName } from 'src/config';
import { usePopover } from 'src/hooks/use-popover';
import { useRouter } from 'src/hooks/use-router';
import { thunks } from 'src/thunks/team';
import { useGetTeams } from '../../../hooks/use-get-teams';
import { useGetUserGroups } from '../../../hooks/use-get-user-groups';
import { useDispatch } from '../../../store/index';
import { TenantPopover } from './tenant-popover';

interface TenantSwitchProps {
  sx?: SxProps;
}

type TeamTenantRole = {
  [tenantId: string]: string | undefined
}

const userHasRole = (groups: any, roleGroups?: (string | null)[] | null | undefined) => {
  if (!roleGroups) return false
  if (!groups) return false
  return groups.some((group) => {
    return roleGroups.includes(group);
  });
}


export const TenantSwitch: FC<TenantSwitchProps> = (props) => {
  const popover = usePopover<HTMLButtonElement>();
  const dispatch = useDispatch()
  const router = useRouter();
  const handleOnChange = useCallback((team: Team) => {
    dispatch(thunks.setTeam({
      team
    }))
    router.push(`/t/${team.id}`)
    popover.handleClose()
  }, [dispatch, popover, router])
  const { team } = useGetTeams();
  const userGroups = useGetUserGroups()

  const getRoleForTeam = useMemo(() => {
    const tenantRoleMap: TeamTenantRole = {}
    team.listTeams.forEach((tenant) => {
      const isAdmin = userHasRole(userGroups, tenant?.adminGroups)
      const isEditor = userHasRole(userGroups, tenant?.editorGroups)
      const isViewer = userHasRole(userGroups, tenant?.viewerGroups)
      const isManager = userHasRole(userGroups, tenant?.managerGroups)
      const role = isAdmin ? 'Admin' : isEditor ? 'Editor' : isManager ? 'Manager' : isViewer ? 'Viewer' : '';

      tenantRoleMap[tenant!.id!] = role
    })

    return tenantRoleMap
  }, [userGroups, team.listTeams])

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            variant="h6"
          >
            {getCustomerName()}
          </Typography>
          <Typography
            variant="body2"
          >
            {team?.current?.name || "No selected team"}
            {" ("}
            {
              team?.current?.id ? (getRoleForTeam[team?.current?.id]) : ''
            }
            {")"}
          </Typography>
        </Box>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16, color: "var(--nav-item-color)" }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={handleOnChange}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={team.listTeams.map(t => ({ ...t, role: getRoleForTeam[t!.id] })) || []}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  // @ts-ignore
  sx: PropTypes.object
};

import { Suspense } from "react";
import { Outlet, RouteObject } from "react-router";
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import OrganizationSetting from "src/pages/settings/organization";


export const organizationRoutes: RouteObject[] = [
    {
        path: 'o',
        element: (
            <DashboardLayout>
                <Suspense>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
        ),
        children: [
            {
                path: ':organizationId',
                children: [
                    {
                        path: 'settings',
                        element: (<OrganizationSetting></OrganizationSetting>)
                    }
                ]

            }
        ]
    }
]
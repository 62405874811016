import {
    Button,
    Stack,
    TextField, Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { Team } from 'src/API';
import * as Yup from 'yup';
import { QuillEditor } from '../../components/quill-editor';
import { htmlToPlainText } from '../canvas/create-ai-canvas/html-to-plain-text';


const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ErrorCaption = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}))

const validationSchema = Yup.object({
    name: Yup.string().min(1).max(255).required(),
    teamSize: Yup.number().min(1).max(1000).required(),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    description: Yup.string().min(10).max(1000).required(),
});

type Values = {
    name?: string,
    address?: string | null
    phoneNumber?: string | null
    description?: string | null
    submit: null
    teamSize?: number | null
    valueProposition?: string | null
}


export type TeamSubmitData = {
    name: string,
    address?: string | null,
    phoneNumber?: string | null,
    description?: string | null,
    teamSize?: number | null,
    valueProposition?: string | null,
    id?: string | null
}
type Props = {
    team?: Team | TeamSubmitData | null,
    onSubmit: (data: TeamSubmitData) => void
    onHasError?: (hasError: boolean) => void
    forwardRef?: any
}

export default function TeamInformation({ team, onSubmit, forwardRef: ref }: Props) {
    const getInitialValues = useMemo((): Values | TeamSubmitData => {
        return {
            name: team?.name || "",
            address: team?.address || "",
            phoneNumber: team?.phoneNumber || "",
            description: team?.description || "",
            teamSize: team?.teamSize || 1,
            valueProposition: team?.valueProposition || "",
            submit: null
        }
    }, [team])

    const formik = useFormik({
        initialValues: getInitialValues,
        validationSchema,
        // innerRef: (instance) => { ref = instance },
        enableReinitialize: true,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                const {
                    name,
                    address,
                    phoneNumber,
                    description,
                    teamSize,
                    valueProposition,
                } = { ...getInitialValues, ...values }

                if (!name) return formik.setErrors({ name: 'Name is required.' })
                if (htmlToPlainText(description).length < 10) {
                    toast.error("There form does not seem to be complete.")

                    return formik.setErrors({ description: "Description about your team does not seem to be informative." })
                }
                onSubmit({
                    name,
                    address,
                    phoneNumber,
                    description,
                    teamSize,
                    valueProposition,
                    id: team?.id
                })

                helpers.setSubmitting(false);
                toast.success('Saved team information.');

            } catch (err) {
                console.error(err, values);
                toast.error('Something went wrong!');
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <form
            ref={ref}
            onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
                <TextField
                    autoFocus
                    error={!!(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label="Team name"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}

                ></TextField>
                <div>
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="subtitle2"
                    >
                        Team Description
                    </Typography>
                    <QuillEditor
                        onChange={(value: string): void => {
                            formik.setFieldValue('description', value);
                            formik.validateField('description');
                        }}
                        placeholder="Write a description of your team and product"
                        sx={{ height: 400 }}
                        value={formik.values.description || undefined}
                        onBlur={() => {
                            formik.setFieldTouched('description', true);
                            formik.validateField('description');

                        }}

                    />
                    {
                        formik.touched.description &&
                        formik.errors.description &&
                        <ErrorCaption variant='caption'>
                            {formik.errors.description}
                        </ErrorCaption>}
                </div>
                <div>
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="subtitle2"
                    >
                        Phone number
                    </Typography>
                    <TextField
                        error={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                        fullWidth
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        label="Phone number"
                        name="phoneNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}

                    ></TextField>
                </div>
                <div>
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="subtitle2"
                    >
                        Address
                    </Typography>
                    <TextField
                        error={!!(formik.touched.address && formik.errors.address)}
                        fullWidth
                        helperText={formik.touched.address && formik.errors.address}
                        label="Address"
                        name="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.address}

                    ></TextField>
                </div>
                <div>
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="subtitle2"
                    >
                        How many team members?
                    </Typography>
                    <TextField
                        error={!!(formik.touched.teamSize && formik.errors.teamSize)}
                        fullWidth
                        helperText={formik.touched.teamSize && formik.errors.teamSize}
                        label="Team size"
                        name="teamSize"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.teamSize}

                    ></TextField>
                </div>
                <div>
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="subtitle2"
                    >
                        Unique value proposition
                    </Typography>
                    <QuillEditor
                        onChange={(value: string): void => {
                            formik.setFieldValue('valueProposition', value);
                        }}
                        placeholder="Write your unique value proposition"
                        sx={{ height: 400 }}
                        value={formik.values.valueProposition || undefined}
                    />
                </div>
            </Stack>
            {!ref && (<Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 4 }}
            >
                <Button
                    type="submit"
                    variant="contained"
                >
                    Save
                </Button>
            </Stack>)}
        </form>
    )
}

import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Input, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { Stack, styled } from "@mui/system";
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import InfoCircle from "@untitled-ui/icons-react/build/esm/InfoCircle";
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { CanvasTile } from "src/API";
import ConfirmPopover from 'src/components/confirm-popover';
import { usePermissionGuard } from "src/guards/organization-guard";
import { useDialog } from "src/hooks/use-dialog";
import { slice as canvasSlice } from '../../slices/canvas';

type InfoDialog = {
    info?: string | null,
}

const StyledInfoIcon = styled(InfoCircle)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

export default function CanvasTileBoxHeader({ canvasTile }: { canvasTile: CanvasTile }) {

    const permissions = usePermissionGuard({ permissions: ['admin', 'editor'] })
    const dialog = useDialog<InfoDialog>()
    const dispatch = useDispatch()
    const [info, setInfo] = useState(canvasTile.info || '')
    const [title, setTitle] = useState(canvasTile.title || '')

    useEffect(() => {
        setTitle(canvasTile.title || '')
        setInfo(canvasTile.info || '')
    }, [canvasTile])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleInfoOpen = useCallback(() => {
        dialog.handleOpen({ info: canvasTile.info || "" })
    }, [canvasTile.info, dialog])

    const handleInfoSave = useCallback(() => {
        if (info === canvasTile.info) return
        dispatch(canvasSlice.actions.updateCanvasTile({
            id: canvasTile.id,
            info: info
        }))
        dialog.handleClose()
    }, [dialog, info, canvasTile.id, canvasTile.info, dispatch])

    const handleInfoChange = useCallback((event) => {
        setInfo(event.target.value)
    }, [])

    const handleTitleChange = useCallback((event) => {
        setTitle(event.target.value)
    }, [])

    const handleTitleSave = useCallback(() => {
        if (title === canvasTile.title) return
        dispatch(canvasSlice.actions.updateCanvasTile({
            id: canvasTile.id,
            title: title
        }))
        dialog.handleClose()
    }, [dispatch, dialog, canvasTile.id, canvasTile.title, title])

    const handleTileDelete = useCallback(() => {
        dispatch(canvasSlice.actions.deleteCanvasTile({ id: canvasTile.id }))
    }, [dispatch, canvasTile.id])

    const handleOpenMenu = useCallback((event) => {
        setAnchorEl(event.currentTarget)
    }, [])

    return (
        <>
            <Stack
                direction='row'
                alignItems='center'
            >
                <IconButton
                    onClick={handleInfoOpen}
                >
                    <StyledInfoIcon ></StyledInfoIcon>
                </IconButton>
                <Input
                    disableUnderline
                    fullWidth
                    disabled={!permissions.canEdit}
                    onBlur={handleTitleSave}
                    onChange={handleTitleChange}
                    title={title}
                    placeholder="Tile Name"
                    sx={{
                        '& .MuiInputBase-input': {
                            borderRadius: 1.5,
                            fontWeight: 500,
                            overflow: 'hidden',
                            px: 2,
                            py: 1,
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            '&:hover, &:focus': {
                                backgroundColor: (theme) => theme.palette.mode === 'dark'
                                    ? 'neutral.800'
                                    : 'neutral.100'
                            }
                        }
                    }}
                    value={title}
                />
                {
                    permissions.canEdit &&
                    <IconButton onClick={handleOpenMenu}>
                        <DotsHorizontalIcon />
                    </IconButton>
                }

            </Stack>
            <Divider></Divider>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem>
                    <ConfirmPopover
                        title=''
                        onConfirm={handleTileDelete}
                        onCancel={() => {
                            setAnchorEl(null)
                        }}
                        component={(handleClick) => <IconButton
                            size="small"
                            color="error"
                            onClick={e => handleClick(e)}
                            title='Delete tile'
                        >
                            <Trash02Icon></Trash02Icon>
                        </IconButton>}
                        content={<Typography>This will delete the <b>tile</b> and all it's information</Typography>}
                    />
                </MenuItem>
            </Menu>
            <Dialog open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth
            >
                <DialogContent>
                    <Typography variant='h6' >Information</Typography>
                    <TextField
                        sx={{ marginTop: 1 }}
                        value={info}
                        onChange={handleInfoChange}
                        multiline
                        rows={10}
                        fullWidth
                    ></TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialog.handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained'
                        onClick={handleInfoSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

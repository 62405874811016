import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAccount } from 'src/hooks/use-account';
import { useGetTeams } from 'src/hooks/use-get-teams';
import { paths } from 'src/paths';

export const useAccountCheck = () => {
    const acc = useAccount();
    const [navigateToCreateAccount, setNavigateToCreateAccount] = useState(false);
    // const [haveTeam, setHaveTeam] = useState(false);
    const [params] = useSearchParams();
    // const { teamId } = useParams();
    const location = useLocation();
    const { team: teams, hasFetched, isLoading } = useGetTeams();
    // const team = useSelector(s => s.team._status.listTeams);

    useEffect(() => {
        const haveAccount = Boolean(acc.account);
        const isOnAccountPageWithNewAccount = location.pathname.includes(paths.user.profile) && params.get('new') !== 'true';
        const shouldNavigate = !haveAccount && !isOnAccountPageWithNewAccount;
        if (acc.hasFetched && navigateToCreateAccount !== shouldNavigate) {
            setNavigateToCreateAccount(shouldNavigate);
        }
    }, [acc, location, params]);

    return { haveTeam: teams.listTeams.length > 0, navigateToCreateAccount, hasFetched, isLoading };
};
import { Column } from "src/types/kanban";

export const objFromArray = (arr: any[], key = 'id'): {} => arr.reduce((
  accumulator,
  current
) => {
  accumulator[current[key]] = current;
  return accumulator;
}, {});

export const objArrayFromArray = (arr: any[], key = 'id'): {} => arr.reduce((
  accumulator,
  current
) => {
  if (!accumulator[current[key]]) accumulator[current[key]] = []
  accumulator[current[key]].push(current)
  return accumulator;
}, {});
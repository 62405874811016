import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { useSetTeam } from 'src/hooks/use-set-team';
import { useSettings } from 'src/hooks/use-settings';
import { useSections } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';

interface LayoutProps {
  children?: ReactNode;
}

// sets the current project to the first project when team has changed
// const useSetProject = () => {
//   const { teamId } = useParams()
//   const team = useSelector(s => s.team.listTeams.find(t => t.id === teamId))
//   const projectId = useSelector(s => s.project.currentId)
//   const dispatch = useDispatch()
//   useEffect(() => {
//     if (team) {
//       const firstProject = team.projects?.items[0]
//       if (!firstProject) return
//       if (projectId === firstProject.id) return
//       dispatch(thunks.setCurrentProject({
//         project: firstProject,
//       }))
//     }
//   }, [teamId, dispatch, team, projectId])
// }

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  useSetTeam()
  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node
};

import { Divider, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { Template } from 'src/API';
import { useTemplates } from 'src/hooks/use-templates';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/template';
import TemplateAccordion from './template-accordion';



const getNumber = (n?: string | number | null): number => typeof n !== 'number' ? 1000 : parseInt(n.toString())
export default function OrganizationListTemplates() {
    const temps: Template[] = useTemplates()
    const templates: Template[] = useMemo(() => [...temps].sort((a, b) => {
        return getNumber(a.sequence) - getNumber(b.sequence)
    }), [temps])

    const dispatch = useDispatch()
    const orderItems = useCallback((template: Template, direction: 'up' | 'down') => {

        const currentIndex = templates.findIndex(i => i.id === template.id);
        if (currentIndex === -1) {
            return; // Item not found in the list
        }

        const currentItem = templates[currentIndex];
        const newPosition = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newPosition < 0 || newPosition >= templates.length) {
            return; // New position is out of bounds
        }

        // Update local sequence and send request to update backend
        const shiftedItem = { ...templates[newPosition] };
        Promise.all([
            dispatch(thunks.updateTemplates([{
                id: currentItem.id,
                sequence: newPosition
            },
            {
                id: shiftedItem.id,
                sequence: currentIndex
            }]))
        ])
    }, [dispatch, templates])
    return (
        <>
            <Stack
                direction={'column'}
                spacing={2}
                p={2}
                overflow={'auto'}
            >
                <Box sx={{ paddingTop: 2 }}>
                    <Typography variant='h4'>Templates</Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                    <Typography variant='body1'>
                        Below, you'll find customizable templates that you can easily arrange in your preferred order using the up and down buttons. Feel free to extend these templates and modify both the description and name to align with your preferences.
                    </Typography>
                </Box>
                <Divider></Divider>
                <Box>
                    {
                        templates.map((template, index) => {
                            return (
                                <TemplateAccordion key={template.id}
                                    orderItems={orderItems}
                                    firstItem={index === 0}
                                    lastItem={index === templates.length - 1}
                                    template={template}></TemplateAccordion>
                            )
                        })
                    }
                </Box>
            </Stack>
        </>
    )
}

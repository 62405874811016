export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  termsAndConditions: '/legal/document/terms-and-conditions',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register'
    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      classic: '/auth-demo/forgot-password/classic',
      modern: '/auth-demo/forgot-password/modern'
    },
    login: {
      classic: '/auth-demo/login/classic',
      modern: '/auth-demo/login/modern'
    },
    register: {
      classic: '/auth-demo/register/classic',
      modern: '/auth-demo/register/modern'
    },
    resetPassword: {
      classic: '/auth-demo/reset-password/classic',
      modern: '/auth-demo/reset-password/modern'
    },
    verifyCode: {
      classic: '/auth-demo/verify-code/classic',
      modern: '/auth-demo/verify-code/modern'
    }
  },
  dashboard: {
    index: '/dashboard',
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/customers',
      details: '/dashboard/customers/:customerId',
      edit: '/dashboard/customers/:customerId/edit'
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    },
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },
  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  projects: {
    root: (teamId: string) => `/t/${teamId}/p/`,
    index: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}`,
    project: '/projects',
    create: (teamId: string) => `/t/${teamId}/p/new`,

  },
  user: {
    profile: '/user/profile',
  },
  kanban: {
    index: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}/kanban`
  },
  canvas: {
    index: (teamId: string, projectId: string, canvasId) => `/t/${teamId}/p/${projectId}/c/${canvasId}`,
    overview: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}/canvases`,
    create: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}/canvas/create`,
    aiNewCanvas: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}/canvas/create-ai-canvas`
  },
  settings: {
    team: (teamId: string) => `/t/${teamId}/settings`,
    organization: (organizationId) => `/o/${organizationId}/settings`
  },
  team: {
    create: '/team/create',
    overview: (teamId: string) => `/t/${teamId}`,
  },
  track: {
    innovationReadiness: (teamId: string, projectId: string) => `/t/${teamId}/p/${projectId}/innovation-readiness`,
  },
  files: {
    index: (teamId: string) => `/t/${teamId}/files`
  },
  chat: {
    index: (teamId: string) => `/t/${teamId}/chat`
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  401: '/401',
  404: '/404',
  500: '/500'
};

import { FC } from 'react';
import Avatar from 'react-nice-avatar';
import { useUserAvatar } from 'src/hooks/use-user-avatar';

type UserAvatarProps = {
    avatarStyle?
    sx?: any
    currentUser?: boolean
    config?: any
    title?: string | null
}

const tryParseJson = (json) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return json;
    }
}

const noConfig: any = { "sex": "man", "faceColor": "#000000", "earSize": "big", "eyeStyle": "oval", "noseStyle": "long", "mouthStyle": "smile", "shirtStyle": "short", "glassesStyle": "none", "hairColor": "#000000", "hairStyle": "normal", "hatStyle": "none", "hatColor": "#ffffff", "eyeBrowStyle": "up", "shirtColor": "#000000", "bgColor": "#d9d9d9" }

export const UserAvatar: FC<UserAvatarProps> = (props) => {
    const { currentUser, ...other } = props
    let { avatarStyle, config } = props
    const initialStyle = { width: 32, height: 32 }
    avatarStyle = { ...initialStyle, ...avatarStyle }
    const myConfig = useUserAvatar()
    if (typeof config === 'string') {
        config = tryParseJson(config)
    }
    if (!config && currentUser) {
        config = myConfig
    }

    config = { ...noConfig, ...config }
    return (
        <div title={other?.title || undefined}>
            {config ? <Avatar
                shape='circle'
                style={avatarStyle}
                {...config}
            ></Avatar>
                :
                <Avatar
                    shape='circle'
                    style={avatarStyle}
                    {...noConfig}
                ></Avatar>
            }
        </div>
    )
}
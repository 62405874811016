import Cookies from 'universal-cookie';
const cookies = new Cookies()

class BaseCookieHandler {
    path = "/"
    cookieName = ""
    constructor(cookieName) {
        this.cookieName = cookieName
    }

    set(value) {
        cookies.set(this.cookieName, value, { path: "/" })
    }
    get() {
        return cookies.get(this.cookieName)
    }
}

export class StateCookie extends BaseCookieHandler {
    keys = {
        teamId: "teamId"
    }

    constructor() {
        super("stateCookie")
    }

    getLastTeamId(): string | null {
        const value = this.get()
        if (!value) return null
        return value[this.keys.teamId]
    }
    setLastTeamId(teamId?: string) {
        if (!teamId) return
        let value = this.get()
        if (!value) value = {}
        value[this.keys.teamId] = teamId
        this.set(value)
    }
}

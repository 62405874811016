import { useDispatch } from "src/store";
import { QueryInitiatorCanvas, QueryInitiatorChat, SocketData, useSocket } from "../useSocket";
import { useEffect } from 'react';
import { slice as chatSlice } from '../../slices/chat'
import { Message, MessageChannel } from "src/API";
import { data } from "src/api/calendar/data";


enum ChatEvents {
    'deleteChannel' = 'channel:delete',
    'updateChannel' = 'channel:update',
    'createChannel' = 'message:channel:create',
    'receiveChannelCreated' = 'receive:message:channel:create',
    createOrGetCanvasChannel = "message:channel:canvas:create",
    'createMessage' = 'message:add',
    'updateMessage' = 'message:change',
    'deleteMessage' = 'message:remove',
    'receiveMessage' = 'receive:message:add',
    'recieveMessageUpdated' = 'receive:message:change',
    'receiveMessageRemove' = 'receive:message:remove',
    'leaveChannel' = 'message:channel:leave',
    'receiveLeaveChannel' = 'receive:message:channel:leave',
}

export enum EntityType {
    canvas = 'canvas',
    chatMessage = 'chatMessage'
}

type Entity = {
    type: EntityType
    id?: string
}
export interface CreateThreadParams {
    name?: string,
    entity?: Entity
    members?: string[]
    organizations?: string[]
    message: {
        content: string
    }
}

export interface CreateChannelThreadParams {
    id: string
    entity: Entity
}

export type CreateMessageParams = {
    content: string,
    channelId: string
}

export type UpdateMessageParams = {
    id: string,
    content: string,
}

export type DeleteMessageParams = {
    id: string
}

export type AckMessage<T> = {
    ok: boolean,
    message: string,
    item?: T
}
export const useChatSocket = (props: { queryInitiator: QueryInitiatorChat }) => {
    const { socket, sendData } = useSocket(props);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!socket) return () => { }

        socket.on(ChatEvents.deleteChannel, (channel: string) => {
            // dispatch(deleteChannel(channel))
        })

        socket.on(ChatEvents.updateChannel, (channel: string) => {
            // dispatch(updateChannel(channel))
        })

        socket.on(ChatEvents.createChannel, (channel: string) => {
            console.log('reply', channel)
            // dispatch(createChannel(channel))
        })

        socket.on(ChatEvents.receiveMessage, (message: SocketData<Message>) => {
            console.log("received message", message)
            dispatch(chatSlice.actions.addMessage(message))
        })

        socket.on(ChatEvents.receiveChannelCreated, (data: SocketData<MessageChannel>) => {
            console.log("created thread", data)
            dispatch(chatSlice.actions.createThread(data))
        })

        socket.on(ChatEvents.recieveMessageUpdated, (data) => {
            console.log('recieveMessageUpdated', data)
            dispatch(chatSlice.actions.updateMessage(data))
        })

        socket.on(ChatEvents.receiveMessageRemove, (data) => {
            console.log('recieveMessageRemove', data)
            dispatch(chatSlice.actions.deleteMessage(data))
        })

        socket.on(ChatEvents.receiveLeaveChannel, (data: SocketData<MessageChannel>) => {
            dispatch(chatSlice.actions.leaveChannel(data))
        })
        return () => {
            Object.keys(ChatEvents).forEach(listener => {
                socket?.off(listener)
            })
        }
    }, [socket, dispatch])

    const createChannel = async (data: CreateThreadParams): Promise<string> => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.createChannel, data, 'createChannel', (data) => {
                resolve(data)
            })
        })
    }

    const leaveChannel = async (id: string): Promise<AckMessage<MessageChannel>> => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.leaveChannel, { id }, 'leaveChannel', (data: AckMessage<MessageChannel>) => {
                if (data.item?.id) {
                    dispatch(chatSlice.actions.deleteThread(data.item.id))
                }
                resolve(data)
            })
        })
    }

    const createMessage = async (data: CreateMessageParams) => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.createMessage, data, 'createMessage', (data) => {
                resolve(data)
            })
        })
    }

    const updateMessage = async (data: UpdateMessageParams): Promise<AckMessage<null>> => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.updateMessage, data, 'updateMessage', (data: AckMessage<null>) => {
                resolve(data)
            })
        })
    }

    const deleteMessage = async (data: DeleteMessageParams): Promise<AckMessage<null>> => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.deleteMessage, data, 'deleteMessage', (data: AckMessage<null>) => {
                resolve(data)
            })
        })
    }

    const createOrGetCanvasChannel = async (data: CreateChannelThreadParams): Promise<AckMessage<string>> => {
        return new Promise((resolve, reject) => {
            sendData(ChatEvents.createOrGetCanvasChannel, data, 'createCanvasChannel', (data) => {
                resolve(data)
            })
        })
    }
    return { createChannel, createMessage, updateMessage, deleteMessage, leaveChannel, createOrGetCanvasChannel }
}


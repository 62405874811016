import { useCallback, useEffect, useState } from 'react';
import { thunks } from 'src/thunks/team';
import { RootState, useDispatch, useSelector } from '../store/index';
import { useParams } from 'react-router';

export const useGetUsers = (props?: { teamId?: string }) => {
    const dispatch = useDispatch();
    const team = useSelector((state: RootState) => state.team);
    let { teamId } = useParams()
    if (props?.teamId) teamId = props?.teamId
    const handleGetUsers = useCallback(() => {
        if (!teamId) {
            console.error("no teamId to get users")
            return
        }
        dispatch(thunks.getTeamUsers({ teamId }));
    }, [dispatch, teamId]);

    useEffect(() => {
        handleGetUsers();
        return () => {
            // optional cleanup function here
        };
    }, [dispatch, handleGetUsers]);
    return team.users
};
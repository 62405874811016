import { genConfig } from 'react-nice-avatar';
import { useAuth } from './use-auth';

export const useUserAvatar = () => {
    const { user } = useAuth()
    let config
    if (!user?.avatar) {
        config = genConfig(user?.email)
    } else {
        try {
            config = JSON.parse(user?.avatar)
        } catch (error) {
            config = genConfig(user?.email)

        }
    }
    return config
}

import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

export const userRoutes: RouteObject[] = [
    {
        path: "user",
        children: [
            {
                path: 'profile',
                element: <DashboardLayout><AccountPage /></DashboardLayout>
            },
        ],
    },
]

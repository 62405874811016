import toast from "react-hot-toast";
import { UpdateOrganizationInput } from "src/API";
import { organizationApi } from "src/api/organizations";
import { AppThunk } from "src/store";

const updateOrganization = (request: UpdateOrganizationInput): AppThunk => async (dispatch) => {
    try {
        const response = await organizationApi.updateOrganization(request);
        toast.success('Organization successfully updated');
    } catch (error) {
        toast.error("Failed to update organization")
    }
}



export const thunks = {
    updateOrganization
};

import { StateCookie } from '../cookie/cookie';
import { Project } from '../API';
import { Team } from 'src/API';


export const getSelectedTeam = (teams: Team[], teamId?: string | null): Team | undefined => {
    if (!teamId) {
        teamId = new StateCookie().getLastTeamId()
    } 
    const lastTeam = teams.filter(f => !!f).find(t => t!.id === teamId);
    if (lastTeam) {
        return lastTeam
    } else if (teams.length > 0) {
        return teams[0]
    }
}

export const getInitialProjectFromTeams = (teams: Team[]): Project | undefined | null => {
    const selectedTeam = getSelectedTeam(teams);
    return selectedTeam?.projects?.items ? selectedTeam.projects?.items[0] : undefined;
}

export const getProjectInTeam = (team: Team, projectId: string): Project | undefined | null => {
    return team?.projects?.items?.find(p => p!.id === projectId);
}
import { FC, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useAccountCheck } from 'src/hooks/use-account-check';
import AccountPage from "src/pages/dashboard/account";
import { OnboardingCreateForm } from "src/sections/onboarding/onboarding-create-form";

type TeamAccountGuardProps = {
    children: ReactNode;
}
export const TeamAccountGuard: FC<TeamAccountGuardProps> = (props) => {
    const [isMounted, setIsMounted] = useState(false);
    const [URLSearchParams, setURLSearchParams] = useSearchParams();
    const { isLoading, haveTeam, navigateToCreateAccount, hasFetched } = useAccountCheck();

    useEffect(() => {
        setIsMounted(true);

        // Handle URL search parameter updates here
        if (navigateToCreateAccount && URLSearchParams.get('new') !== 'true') {
            setURLSearchParams({ "new": "true" });
        }

        return () => setIsMounted(false);
    }, [navigateToCreateAccount, URLSearchParams, setURLSearchParams]);

    if (!isMounted) {
        return null;
    }

    if (!haveTeam && hasFetched && !isLoading) {
        return <OnboardingCreateForm />;
    }

    if (navigateToCreateAccount && !isLoading) {
        return <AccountPage />;
    }
    return <>{props.children}</>;
};
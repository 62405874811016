import { tokens } from '../tokens';

export const sv = {
    [tokens.common.languageChanged]: 'Språk ändrat',
    [tokens.nav.academy]: 'Akademi',
    [tokens.nav.account]: 'Konto',
    [tokens.nav.analytics]: 'Analyser',
    [tokens.nav.auth]: 'Autentisering',
    [tokens.nav.blog]: 'Blogg',
    [tokens.nav.browse]: 'Bläddra',
    [tokens.nav.calendar]: 'Kalender',
    [tokens.nav.chat]: 'Chatt',
    [tokens.nav.checkout]: 'Kassa',
    [tokens.nav.concepts]: 'Koncept',
    [tokens.nav.contact]: 'Kontakt',
    [tokens.nav.course]: 'Kurs',
    [tokens.nav.create]: 'Skapa',
    [tokens.nav.crypto]: 'Krypto',
    [tokens.nav.customers]: 'Kunder',
    [tokens.nav.dashboard]: 'Instrumentpanel',
    [tokens.nav.details]: 'Detaljer',
    [tokens.nav.ecommerce]: 'E-handel',
    [tokens.nav.edit]: 'Redigera',
    [tokens.nav.error]: 'Fel',
    [tokens.nav.feed]: 'Flöde',
    [tokens.nav.fileManager]: 'Filhanterare',
    [tokens.nav.finance]: 'Finans',
    [tokens.nav.fleet]: 'Flotta',
    [tokens.nav.forgotPassword]: 'Glömt lösenord',
    [tokens.nav.invoiceList]: 'Fakturor',
    [tokens.nav.jobList]: 'Jobblistor',
    [tokens.nav.kanban]: 'Kanban',
    [tokens.nav.list]: 'Lista',
    [tokens.nav.login]: 'Logga in',
    [tokens.nav.logistics]: 'Logistik',
    [tokens.nav.mail]: 'Mail',
    [tokens.nav.management]: 'Hantering',
    [tokens.nav.orderList]: 'Beställningar',
    [tokens.nav.overview]: 'Översikt',
    [tokens.nav.pages]: 'Sidor',
    [tokens.nav.postCreate]: 'Skapa inlägg',
    [tokens.nav.postDetails]: 'Inläggsdetaljer',
    [tokens.nav.postList]: 'Inläggslista',
    [tokens.nav.pricing]: 'Prissättning',
    [tokens.nav.productList]: 'Produkter',
    [tokens.nav.profile]: 'Profil',
    [tokens.nav.register]: 'Registrera',
    [tokens.nav.resetPassword]: 'Återställ lösenord',
    [tokens.nav.socialMedia]: 'Sociala medier',
    [tokens.nav.verifyCode]: 'Verifiera kod',
    [tokens.common.save]: 'Spara'
};
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/chat';

export const useThreadMessages = (threadKey: string) => {
  const dispatch = useDispatch();
  const { messages, nextToken } = useSelector((state) => {
    const { messagesByChannel } = state.chat;
    return { messages: messagesByChannel.byId[threadKey], nextToken: state.chat.messagesByChannel.nextToken }
  });

  const getMessages = useCallback(
    async (nextToken?): Promise<void> => {
      try {
        const messages = await dispatch(thunks.getMessagesByThread(threadKey, nextToken));
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch, threadKey]
  );

  useEffect(
    () => {
      getMessages();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadKey]
  );
  const getNextPage = useCallback(() => {
    if (!nextToken) return;
    getMessages(nextToken);
  }, [getMessages, nextToken])

  const sorted = useMemo(() => [...messages || []]?.sort((a, b) => {
    if (!a.createdAt) return -1;
    if (!b.createdAt) return 1;
    return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1
  }), [
    messages
  ])

  return { messages: sorted, getNextPage, canGetMore: Boolean(nextToken) };
}
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Message } from 'src/API';

export type ChatNotificationParams = {
    message: Message
}

export default function ChatNotification(props: ChatNotificationParams) {
    const { message } = props
    return (
        <>
            <Stack direction={'row'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant='caption'>
                    {message.content}
                </Typography>
            </Stack>
        </>
    )
}

import { useCallback } from 'react';
import { Editor, TLGeoShape } from "tldraw";
import { IShape } from "./getCanvasLayout";


export const useAddShapeCallback = (shapes: IShape[]) => {
    return useCallback((editor: Editor) => {

        const xMin = shapes.reduce((acc, shape) => Math.min(acc, shape.x), 0)
        const yMin = shapes.reduce((acc, shape) => Math.min(acc, shape.y), 0)
        const xMax = shapes.reduce((acc, shape) => Math.max(acc, shape.x + shape.w), 0)
        const yMax = shapes.reduce((acc, shape) => Math.max(acc, shape.y + shape.h), 0)
        // setRect({ minX: xMin, minY: yMin, maxX: xMax, maxY: yMax })

        for (const shape of shapes) {
            editor?.createShape<TLGeoShape>({
                type: 'box' as any,
                x: shape.x,
                y: shape.y,
                meta: {
                },
                props: {
                    "w": shape.w,
                    "h": shape.h,
                    "growY": 0,
                    "text": shape.text,
                    dash: 'dotted' as any,
                    size: "s" as any,
                    color: shape.color as any || 'black'
                }
            })
        }
        return { minX: xMin, minY: yMin, maxX: xMax, maxY: yMax }
    }, [shapes])
}
import { useCallback, useEffect } from 'react';
import { thunks } from 'src/thunks/user';
import { useDispatch, useSelector, RootState } from '../store/index';
import { useAuth } from './use-auth';

export const useGetUserGroups = () => {
    const { user } = useAuth()
    const groups = user?.groups

    return groups
};
import { useCallback } from 'react';
import { Editor, TLGeoShape } from 'tldraw';
import { CanvasLayoutConfig, getCanvasLayout } from "./getCanvasLayout";
import { getMetricsTableLayout } from './getMetricsTableLayout';
import { useAddShapeCallback } from './useAddShapeCallback';


export interface CanvasBounds {
    minX: number
    minY: number
    maxX: number
    maxY: number
}

export const useMetricsLayout = (config: CanvasLayoutConfig): (e: Editor) => CanvasBounds => {
    const getShapes = useCallback(() => {
        return getMetricsTableLayout(config)
    }, [config])
    return useAddShapeCallback(getShapes())
}
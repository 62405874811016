import { Editor, TLGeoShape } from "tldraw"
import { CanvasLayoutConfig, IShape } from "./getCanvasLayout"


export interface MetricsTableLayoutConfig {
    title: string
    columns: { [key: string]: any }[]

}

export const getMetricsTableLayout = (layout: CanvasLayoutConfig): IShape[] => {

    const config: MetricsTableLayoutConfig = {
        columns: [{
            header: "Sales and Marketing",
        }, {
            header: "Production",
        }, {
            header: "Resources",
        }],
        title: "Metrics"
    }

    layout.margin = 0
    const headerHight = 40
    const shapes: Omit<IShape, 'isFilled'>[] = [
        {
            h: headerHight,
            text: config.title,
            w: layout.width * 3 + 2 * layout.margin,
            x: layout.initX,
            y: layout.initY,
        }, {
            h: 2 * layout.h,
            text: config.columns[0].header,
            w: layout.width,
            x: layout.initX,
            y: layout.initY + headerHight + layout.margin,
        }, {
            h: 2 * layout.h,
            text: config.columns[1].header,
            w: layout.width,
            x: layout.initX + layout.width + layout.margin,
            y: layout.initY + headerHight + layout.margin,
        }, {
            h: 2 * layout.h,
            text: config.columns[2].header,
            w: layout.width,
            x: layout.initX + 2 * layout.width + 2 * layout.margin,
            y: layout.initY + headerHight + layout.margin,
        }
    ]


    return shapes.map(shape => {
        return {
            ...shape,
            isFilled: true
        }
    })
}
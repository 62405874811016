import { useEffect, useState, useCallback } from 'react';
import { thunks } from 'src/thunks/template';
import { useDispatch, useSelector } from '../store/index';
import { Template } from 'src/API';

// export const useTemplates = () => {
//     const [templates, setTemplates] = useState<Template[] | any>([]);
//     const dispatch = useDispatch();
//     const templateState = useSelector(state => state.template.listTemplates);
//     const [hasFetched, sethasFetched] = useState(false)
//     const getTemplates = useCallback(
//         () => {
//             if (!hasFetched) {
//                 dispatch(thunks.GetTemplate({}));
//                 sethasFetched(true)
//             }
//         },
//         [dispatch, hasFetched],
//     )

//     useEffect(() => {
//         getTemplates()
//     }, [templateState, dispatch, getTemplates]);

//     useEffect(() => {
//         setTemplates(templateState);
//     }, [templateState]);

//     return templates || [];
// }
export const useTemplates = () => {

    const dispatch = useDispatch();
    const templates = useSelector(state => state.template.listTemplates);
    const getTemplates = useCallback(
        () => {
            dispatch(thunks.GetTemplate({}));
        },
        [dispatch],
    )

    useEffect(() => {
        getTemplates()
    }, [dispatch, getTemplates]);


    return [...templates].sort((a, b) => (a.sequence || 1) - (b.sequence || 1)) || [];
}
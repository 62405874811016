import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC, useState } from 'react';
import { genConfig } from 'react-nice-avatar';
import { UserAvatar } from 'src/components/user-avatar';
import AvatarOptions from './avatar-options';

type AvatarGeneratorProps = {
    onClose?: () => void;
    onSave: (config) => void
    open?: boolean;
    config: string | any // json string
}

export const AvatarGenerator: FC<AvatarGeneratorProps> = (props) => {
    const { onClose, onSave, open = false, ...other } = props;
    let { config } = props

    if (!config) {
        config = genConfig("")
    } else {
        config = JSON.parse(config)
    }
    const [avatarConfig, setAvatarConfig] = useState(config)
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            open={open}
            {...other}
        >
            <DialogContent>
                <UserAvatar
                    avatarStyle={{ width: '8rem', height: '8rem' }}
                    currentUser={true}
                    config={avatarConfig}
                ></UserAvatar>
                {/* <Avatar
                    shape='circle'
                    style={{ width: '8rem', height: '8rem' }}
                    {...avatarConfig} /> */}
                <AvatarOptions
                    onChange={(key, value) => {
                        setAvatarConfig(c => ({ ...c, [key]: value }))
                    }}
                    config={avatarConfig}
                ></AvatarOptions>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onSave(avatarConfig)}
                >
                    Save
                </Button>
                <Button onClick={onClose}
                    color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}


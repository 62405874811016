import { Backdrop, CircularProgress, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';
import { SizeMe } from 'react-sizeme';
import { Canvas, CanvasTile } from 'src/API';
import { canvasApi } from 'src/api/canvas';
import { usePermissionGuard } from 'src/guards/organization-guard';
import { useDialog } from 'src/hooks/use-dialog';
import { TileMoveEvent } from 'src/slices/canvas';
import { useCanvasSocket } from 'src/socket/canvas/useCanvasSocket';
import { EntityType, useChatSocket } from 'src/socket/chat/useChatSocket';
import { useDispatch, useSelector } from 'src/store';
import { thunks as canvasThunks } from 'src/thunks/canvas';
import { slice as canvasSlice } from '../../slices/canvas';
import { useSidebar } from '../dashboard/chat';
import SideMenu from './canvas-chat/side-menu';
import CanvasGridLayout from './canvas-grid-layout';
import CanvasMenu from './canvas-menu';
import CanvasPopper, { useCanvasPopper } from './canvas-popper';
import CanvasTemplateDialog from './canvas-template-dialog';
import CanvasTileBox from './canvas-tile-box';

const useCanvas = (canvasId) => {

    const dispatch = useDispatch();
    const [hasFetched, setHasFetched] = useState(false);
    const [canvas, setCanvas] = useState<Canvas | null>(null);
    const [canvasTiles, setCanvasTiles] = useState<{
        byId: { [id: string]: CanvasTile },
        allIds: string[],
    } | null>(null);

    const canvasState = useSelector((state) => state.canvas);
    // const params = useParams()
    useCanvasSocket({
        queryInitiator: {
            type: "canvas",
            canvasId: canvasId,
        }
    })

    useEffect(() => {
        const fetchCanvas = async () => {
            if (hasFetched) return
            setHasFetched(true);
            dispatch(canvasThunks.getCanvas(canvasId));
        };

        const existingCanvas = canvasState.listCanvasess.find(c => c?.id === canvasId);
        const canvasTiles = canvasState.canvasTiles

        if (existingCanvas && existingCanvas.canvasTiles) {
            setCanvas(existingCanvas as Canvas);
            setCanvasTiles(canvasTiles);
        } else {
            fetchCanvas();
        }

        return () => {
            // Clean up any necessary resources here
        };
    }, [canvasId, canvasState.listCanvasess, dispatch, hasFetched, canvasState.canvasTiles]);

    return { canvas, canvasTiles };
};

export type CanvasTemplateDialogData = {
    canvas: Canvas
}

export default function CanvasPage() {
    const { canvasId } = useParams();
    const { canvas, canvasTiles } = useCanvas(canvasId);
    const permissions = usePermissionGuard({ permissions: ['admin', 'editor', 'organization'] })
    const [locked, setLocked] = useState(true);
    const dispatch = useDispatch()
    const popper = useCanvasPopper()
    const templateDialog = useDialog<CanvasTemplateDialogData>()
    const sidebar = useSidebar();
    const { teamId } = useParams()
    const [chatChannel, setChatChannel] = useState<string | undefined | null>(canvas?.channelId)
    const [gettingChannel, setGettingChannel] = useState<boolean>(false)
    const { createOrGetCanvasChannel, createMessage, deleteMessage, updateMessage } = useChatSocket({
        queryInitiator: {
            type: 'message',
            teamId: teamId,
            entity: canvasId
        }
    })

    useEffect(() => {
        if (canvas) {
            setChatChannel(canvas.channelId)
        }
    }, [canvas])

    useEffect(() => {
        if (!canvas) return
        if (chatChannel) return
        if (gettingChannel) return
        setGettingChannel(true)
        createOrGetCanvasChannel({
            id: canvas.id,
            entity: {
                type: EntityType.canvas
            }
        }).then((ack) => {
            setGettingChannel(false)
            sidebar.handleToggle()
            setChatChannel(ack.item)
        })
        return () => {
            if (gettingChannel) {
                setGettingChannel(false)
            }
        }
    }, [canvas, chatChannel, setChatChannel, createOrGetCanvasChannel, sidebar, gettingChannel])

    if (!canvasTiles || !canvas) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleLockClick = (event) => {
        setLocked(s => {
            toast.success(s ? 'You can now move tiles' : 'Tiles are locked');
            return !s
        })
    }

    const handleDownloadClick = (event) => {
        if (!canvasId) {
            toast.error('Canvas not found, failed to download');
            return;
        }
        toast.success('Preparing download...');
        canvasApi.downloadCanvas(canvasId).then(response => {
            const url = window.URL.createObjectURL(new Blob([response], { type: "application/octet-stream" }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${canvas.name}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(console.error)
    }

    const handleTileMoveEvent = (changes: TileMoveEvent[]) => {
        if (!canvasId) {
            toast.error('Canvas not found, failed to move tiles');
            return;
        }
        dispatch(canvasSlice.actions.moveCanvasTiles(changes));
    }

    const handleDragDrop = (result?: DropResult | null) => {

        if (!result) return
        if (!canvasId) {
            toast.error('Canvas not found, failed to move task');
            return;
        }
        const { source, destination } = result;

        if (!source) return
        if (!destination) return

        if (!source.droppableId || !destination.droppableId) {
            return;
        }
        dispatch(canvasSlice.actions.moveTaskBetweenTiles(result))
    }

    return (
        <>
            <Divider></Divider>
            <CanvasMenu
                handleDownloadClick={handleDownloadClick}
                handleLockClick={handleLockClick}
                locked={locked}
                permissions={permissions}
                canvas={canvas}
                dialog={templateDialog}
                onChatIconClick={sidebar.handleToggle}
            ></CanvasMenu>
            <Divider></Divider>
            <SizeMe
                monitorWidth={true}
            >{({ size }) => (<DragDropContext
                onDragEnd={(e) => handleDragDrop(e)}
            >
                <CanvasGridLayout
                    size={size}
                    isEditMode={permissions.canEdit && !locked}
                    items={canvasTiles.allIds.map(id => canvasTiles.byId[id])}
                    renderComponent={(item, index) => <CanvasTileBox
                        popper={popper}
                        permissions={permissions}
                        canvasTileId={item.id}></CanvasTileBox>}
                    onChange={handleTileMoveEvent}
                ></CanvasGridLayout>
            </DragDropContext>)
                }</SizeMe>
            <CanvasPopper
                permissions={permissions}
                popper={popper}
            ></CanvasPopper>
            {Boolean(chatChannel) && <SideMenu
                onClose={sidebar.handleClose}
                open={sidebar.open}
                channel={chatChannel!}
                onCreateMessage={createMessage}
                onDeleteMessage={deleteMessage}
                onUpdateMessage={updateMessage}
            ></SideMenu>}

            <CanvasTemplateDialog dialog={templateDialog}></CanvasTemplateDialog>
        </>
    )
}

import { GraphQLQuery } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { CanvasTilesByCanvasQuery, GetCanvasQuery, UpdateCanvasInput } from 'src/API';
import { canvasTilesByCanvas, getCanvas as getCanvasQuery } from 'src/graphql/queries';
import { slice } from 'src/slices/canvas';
import type { AppThunk } from 'src/store';
import { canvasApi } from '../api/canvas';
import { CreateCanvasRequest } from '../api/canvas/index';
import { thunks as projectThunks } from './project';
import toast from 'react-hot-toast';
import { listCanvass } from '../graphql/queries';

const getCanvases = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await canvasApi.getCanvases({});
    dispatch(slice.actions.getCanvases(response?.data?.listCanvass?.items || []));

  } catch (error) {
    console.error(error)
    if (error.data?.listCanvass) {
      dispatch(slice.actions.getCanvases(error?.data?.listCanvass?.items || []));
    }
  }
};

const getCanvas = (canvasId: string): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await API.graphql<GraphQLQuery<GetCanvasQuery>>(graphqlOperation(getCanvasQuery, { id: canvasId }));
    const responseTiles = await API.graphql<GraphQLQuery<CanvasTilesByCanvasQuery>>(graphqlOperation(canvasTilesByCanvas, { canvasId: canvasId }));
    await dispatch(slice.actions.getCanvas(response?.data?.getCanvas));
    await dispatch(slice.actions.getCanvasTileForCanvas(responseTiles.data?.canvasTilesByCanvas?.items));

  } catch (error) {
    const errors = error?.errors
    if (Array.isArray(errors) && errors.length > 0) {
      errors.filter(e => {
        e.errorType === 'Unauthorized' || e.message.includes('Unauthorized')
        window.location.href = '/404';
      })
    }

  }
}

const cloneCanvasAndReloadProject = (canvasId: string, projectId: string): AppThunk => async (dispatch): Promise<void> => {
  const response = await canvasApi.cloneCanvas({ canvasId });
  if (!response.ok) return
  dispatch(projectThunks.getProject({ id: projectId }))
}

// const deleteCanvas = (canvasId: string): AppThunk => async (dispatch): Promise<void> => {
//   const response = await canvasApi.deleteCanvas({ canvasId });
//   console.log('deleteCanvas response', response)
//   dispatch(slice.actions.deleteCanvas({ canvasId }))
// }

const create = (request: CreateCanvasRequest): AppThunk => async (dispatch): Promise<void> => {
  const response = await canvasApi.create(request);
  if (response.ok) {
    dispatch(slice.actions.setSuccess({
      attribute: 'create',
      message: 'Canvas successfully created',
    }));
  } else {
    dispatch(slice.actions.setError({
      attribute: 'create',
      message: 'Canvas creation failed',
    }));
  }
}

const updateCanvas = (input: UpdateCanvasInput): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await canvasApi.updateCanvas(input);
    dispatch(slice.actions.updateCanvas(response))
    toast.success('Canvas successfully updated');
  } catch (error) {
    toast.error("Failed to update canvas")
  }
}


export const thunks = {
  getCanvases,
  getCanvas,
  cloneCanvasAndReloadProject,
  create,
  updateCanvas
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCanvas = /* GraphQL */ `
  mutation CreateCanvas(
    $input: CreateCanvasInput!
    $condition: ModelCanvasConditionInput
  ) {
    createCanvas(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      canvasTiles {
        items {
          id
          canvasId
          tasks
          teamId
          message
          position
          title
          createdAt
          updatedAt
          updatedBy
          info
          owner
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateCanvas = /* GraphQL */ `
  mutation UpdateCanvas(
    $input: UpdateCanvasInput!
    $condition: ModelCanvasConditionInput
  ) {
    updateCanvas(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      canvasTiles {
        items {
          id
          canvasId
          tasks
          teamId
          message
          position
          title
          createdAt
          updatedAt
          updatedBy
          info
          owner
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteCanvas = /* GraphQL */ `
  mutation DeleteCanvas(
    $input: DeleteCanvasInput!
    $condition: ModelCanvasConditionInput
  ) {
    deleteCanvas(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      canvasTiles {
        items {
          id
          canvasId
          tasks
          teamId
          message
          position
          title
          createdAt
          updatedAt
          updatedBy
          info
          owner
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      createdAt
      updatedAt
      owner
      channelId
      properties
      ownerEmail
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createCanvasTile = /* GraphQL */ `
  mutation CreateCanvasTile(
    $input: CreateCanvasTileInput!
    $condition: ModelCanvasTileConditionInput
  ) {
    createCanvasTile(input: $input, condition: $condition) {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateCanvasTile = /* GraphQL */ `
  mutation UpdateCanvasTile(
    $input: UpdateCanvasTileInput!
    $condition: ModelCanvasTileConditionInput
  ) {
    updateCanvasTile(input: $input, condition: $condition) {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteCanvasTile = /* GraphQL */ `
  mutation DeleteCanvasTile(
    $input: DeleteCanvasTileInput!
    $condition: ModelCanvasTileConditionInput
  ) {
    deleteCanvasTile(input: $input, condition: $condition) {
      id
      canvasId
      tasks
      teamId
      message
      position
      title
      createdAt
      updatedAt
      updatedBy
      info
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      description
      tiles
      sequence
      createdAt
      aiEnabled
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      description
      tiles
      sequence
      createdAt
      aiEnabled
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      name
      description
      tiles
      sequence
      createdAt
      aiEnabled
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      projectId
      project {
        id
        name
        lowercaseName
        teamId
        team {
          id
          name
          createdAt
          fileUrl
          updatedAt
          owner
          teamFlags
          description
          teamSize
          phoneNumber
          valueProposition
          address
          color
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        canvases {
          nextToken
          __typename
        }
        readiness
        createdAt
        updatedAt
        buckets
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      experiment
      sequence
      teamId
      bucket
      progress
      priority
      startDate
      dueDate
      notes
      checkList
      attachments
      comments
      completed
      assigned
      updatedBy
      createdBy
      createdAt
      updatedAt
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          name
          lowercaseName
          teamId
          readiness
          createdAt
          updatedAt
          buckets
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      fileUrl
      updatedAt
      owner
      teamFlags
      description
      teamSize
      phoneNumber
      valueProposition
      address
      color
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createTeamGroup = /* GraphQL */ `
  mutation CreateTeamGroup(
    $input: CreateTeamGroupInput!
    $condition: ModelTeamGroupConditionInput
  ) {
    createTeamGroup(input: $input, condition: $condition) {
      id
      name
      teamIds
      managerGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeamGroup = /* GraphQL */ `
  mutation UpdateTeamGroup(
    $input: UpdateTeamGroupInput!
    $condition: ModelTeamGroupConditionInput
  ) {
    updateTeamGroup(input: $input, condition: $condition) {
      id
      name
      teamIds
      managerGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeamGroup = /* GraphQL */ `
  mutation DeleteTeamGroup(
    $input: DeleteTeamGroupInput!
    $condition: ModelTeamGroupConditionInput
  ) {
    deleteTeamGroup(input: $input, condition: $condition) {
      id
      name
      teamIds
      managerGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      team {
        id
        name
        projects {
          nextToken
          __typename
        }
        createdAt
        fileUrl
        updatedAt
        owner
        teamFlags
        description
        teamSize
        phoneNumber
        valueProposition
        address
        color
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      canvases {
        items {
          id
          name
          lowercaseName
          teamId
          projectId
          createdAt
          updatedAt
          owner
          channelId
          properties
          ownerEmail
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      team {
        id
        name
        projects {
          nextToken
          __typename
        }
        createdAt
        fileUrl
        updatedAt
        owner
        teamFlags
        description
        teamSize
        phoneNumber
        valueProposition
        address
        color
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      canvases {
        items {
          id
          name
          lowercaseName
          teamId
          projectId
          createdAt
          updatedAt
          owner
          channelId
          properties
          ownerEmail
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      lowercaseName
      teamId
      team {
        id
        name
        projects {
          nextToken
          __typename
        }
        createdAt
        fileUrl
        updatedAt
        owner
        teamFlags
        description
        teamSize
        phoneNumber
        valueProposition
        address
        color
        adminGroups
        editorGroups
        viewerGroups
        managerGroups
        __typename
      }
      canvases {
        items {
          id
          name
          lowercaseName
          teamId
          projectId
          createdAt
          updatedAt
          owner
          channelId
          properties
          ownerEmail
          adminGroups
          editorGroups
          viewerGroups
          managerGroups
          __typename
        }
        nextToken
        __typename
      }
      readiness
      createdAt
      updatedAt
      buckets
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createInvitedUser = /* GraphQL */ `
  mutation CreateInvitedUser(
    $input: CreateInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    createInvitedUser(input: $input, condition: $condition) {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvitedUser = /* GraphQL */ `
  mutation UpdateInvitedUser(
    $input: UpdateInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    updateInvitedUser(input: $input, condition: $condition) {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvitedUser = /* GraphQL */ `
  mutation DeleteInvitedUser(
    $input: DeleteInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    deleteInvitedUser(input: $input, condition: $condition) {
      id
      email
      teamId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      type
      channelId
      content
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      members
      organizations
      __typename
    }
  }
`;
export const createMessageChannel = /* GraphQL */ `
  mutation CreateMessageChannel(
    $input: CreateMessageChannelInput!
    $condition: ModelMessageChannelConditionInput
  ) {
    createMessageChannel(input: $input, condition: $condition) {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      messages {
        items {
          id
          type
          channelId
          content
          createdBy
          createdAt
          updatedAt
          updatedBy
          owner
          members
          organizations
          __typename
        }
        nextToken
        __typename
      }
      owner
      permissionHash
      __typename
    }
  }
`;
export const updateMessageChannel = /* GraphQL */ `
  mutation UpdateMessageChannel(
    $input: UpdateMessageChannelInput!
    $condition: ModelMessageChannelConditionInput
  ) {
    updateMessageChannel(input: $input, condition: $condition) {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      messages {
        items {
          id
          type
          channelId
          content
          createdBy
          createdAt
          updatedAt
          updatedBy
          owner
          members
          organizations
          __typename
        }
        nextToken
        __typename
      }
      owner
      permissionHash
      __typename
    }
  }
`;
export const deleteMessageChannel = /* GraphQL */ `
  mutation DeleteMessageChannel(
    $input: DeleteMessageChannelInput!
    $condition: ModelMessageChannelConditionInput
  ) {
    deleteMessageChannel(input: $input, condition: $condition) {
      id
      name
      members
      organizations
      entity
      entityType
      createdAt
      updatedAt
      messages {
        items {
          id
          type
          channelId
          content
          createdBy
          createdAt
          updatedAt
          updatedBy
          owner
          members
          organizations
          __typename
        }
        nextToken
        __typename
      }
      owner
      permissionHash
      __typename
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      key
      name
      size
      type
      teamId
      parentFolders
      searchKey
      shareSearchKey
      relativePath
      organizationId
      treeId
      createdBy
      createdAt
      updatedAt
      updatedBy
      owner
      adminGroups
      editorGroups
      viewerGroups
      managerGroups
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      role
      description
      ownerRole
      iconUrl
      properties
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      role
      description
      ownerRole
      iconUrl
      properties
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      role
      description
      ownerRole
      iconUrl
      properties
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      displayName
      avatar
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      displayName
      avatar
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      displayName
      avatar
      createdAt
      updatedAt
      __typename
    }
  }
`;

import { Avatar, Grid, Step, StepContent, StepIconProps, StepLabel, Stepper, SvgIcon, Typography } from '@mui/material';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { TeamSubmitData } from 'src/pages/settings/team-information';
import { TeamTableDetachedValues } from '../dashboard/team/team-table-detached';
import OnboardingCreationStep from './onboarding-creation-step';
import { MembersStep } from './onboarding-members-step';
import { ShareWithOrganizationStep } from './onboarding-share-organization-step';
import { TeamStep } from './onboarding-team-step';
import { WelcomeStep } from './onboarding-welcome-step';
// import { JobCategoryStep } from './job-category-step';
// import { JobDescriptionStep } from './job-description-step';
// import { JobDetailsStep } from './job-details-step';
// import { JobPreview } from './job-preview';

const StepIcon: FC<StepIconProps> = (props) => {
    const { active, completed, icon } = props;

    const highlight = active || completed;

    return (
        <Avatar
            sx={{
                height: 40,
                width: 40,
                ...(highlight && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText'
                })
            }}
            variant="rounded"
        >
            {
                completed
                    ? (
                        <SvgIcon>
                            <CheckIcon />
                        </SvgIcon>
                    )
                    : icon
            }
        </Avatar>
    );
};

export type CreationState = {
    teamInformation?: TeamSubmitData,
    members: TeamTableDetachedValues[],
    share: string[]
}


export const OnboardingCreateForm: FC = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [teamState, setTeamState] = useState<CreationState>({
        teamInformation: undefined,
        members: [],
        share: []
    });


    const handleNext = useCallback(
        () => {
            setActiveStep((prevState) => prevState + 1);
        },
        []
    );

    const handleBack = useCallback(
        () => {
            setActiveStep((prevState) => prevState - 1);
        },
        []
    );

    const handleComplete = useCallback(
        () => {
            setIsComplete(true);
        },
        []
    );

    const steps = useMemo(
        () => {
            return [
                {
                    label: 'Welcome to Planvas',
                    content: (
                        <WelcomeStep
                            onBack={handleBack}
                            onNext={handleNext}
                        />
                    )
                }, {
                    label: 'The team',
                    content: (
                        <TeamStep
                            onBack={handleBack}
                            onNext={(data) => {
                                setTeamState(s => ({ ...s, teamInformation: data }));
                                handleNext()
                            }}
                            team={teamState.teamInformation}
                        />
                    )
                },
                {
                    label: 'Members',
                    content: (
                        <MembersStep
                            users={teamState.members}
                            onBack={handleBack}
                            onUserChange={(users) => {
                                console.log('users', users)
                                setTeamState(s => ({ ...s, members: users }))
                            }}
                            onNext={handleNext}
                        />
                    )
                },
                {
                    label: 'Share',
                    content: (
                        <ShareWithOrganizationStep
                            onRoleChange={(roles) => {
                                setTeamState(s => ({ ...s, share: roles }))
                            }}
                            roles={teamState.share}
                            onBack={handleBack}
                            onNext={handleComplete}
                        />
                    )
                }
            ];
        },
        [handleBack, handleNext, handleComplete, teamState]
    );

    if (isComplete) {
        return <OnboardingCreationStep data={teamState}></OnboardingCreationStep>
    }

    return (
        <Grid container
            spacing={3}>
            <Grid item
                xs={1}></Grid>
            <Grid item
                xs={8}>
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{
                        '& .MuiStepConnector-line': {
                            borderLeftColor: 'divider',
                            borderLeftWidth: 2,
                            ml: 1
                        }
                    }}
                >
                    {steps.map((step, index) => {
                        const isCurrentStep = activeStep === index;

                        return (
                            <Step key={step.label}>
                                <StepLabel StepIconComponent={StepIcon}>
                                    <Typography
                                        sx={{ ml: 2 }}
                                        variant="overline"
                                    >
                                        {step.label}
                                    </Typography>
                                </StepLabel>
                                <StepContent
                                    sx={{
                                        borderLeftColor: 'divider',
                                        borderLeftWidth: 2,
                                        ml: '20px',
                                        ...(isCurrentStep && {
                                            py: 4
                                        })
                                    }}
                                >
                                    {step.content}
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item
                xs={3}></Grid>
        </Grid>
    );
};

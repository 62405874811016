import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { format } from 'date-fns';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Canvas, Team } from 'src/API';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/canvas';
import { thunks as teamThunk } from 'src/thunks/team';



const useCanvases = () => {
    // const canvases = useState([])
    const canvases = useSelector((state) => state.canvas.listCanvasess)
    const dispatch = useDispatch()
    const fetchCanvases = useCallback(() => {
        dispatch(thunks.getCanvases())
    }, [dispatch])

    useEffect(() => {
        fetchCanvases()
    }, [dispatch, fetchCanvases])
    return canvases.filter(f => f) as Canvas[]
}

const useTeams = () => {
    const teams = useSelector((state) => state.team.listTeams)
    const dispatch = useDispatch()
    const { teamId } = useParams()
    const fetchTeams = useCallback(() => {
        dispatch(teamThunk.getTeams({ teamId }))
    }, [dispatch, teamId])

    useEffect(() => {
        fetchTeams()
    }, [dispatch, fetchTeams])
    return teams.filter(f => f) as Team[]
}

export default function OrganizationListCanvases() {
    const canvases = useCanvases()
    const teams = useTeams()

    const teamMap = useMemo(() => {
        const map = new Map<string, Team>()
        teams.forEach(t => map.set(t.id, t))
        return map
    }, [teams])
    const columns = useMemo<MRT_ColumnDef<Canvas>[]>(
        () => [
            {
                accessorKey: 'name', //access nested data with dot notation
                header: 'Name',
                size: 150,
                accessorFn: d => {
                    if (!teamMap.get(d.teamId)) return 'Not found'
                    return <a href={`/t/${d.teamId}/p/${d.projectId}/c/${d.id}`}>{d.name}</a>
                },
                filterFn: (row, id, filterValue) => {
                    const canvas = canvases.find(f => row.original.id === f.id)
                    if (!canvas) return false
                    return canvas.name.toLowerCase().includes(filterValue.toLowerCase())
                }
            },
            {
                accessorKey: 'teamId',
                accessorFn: d => {
                    const team = teamMap.get(d.teamId)
                    if (team) {
                        return <a href={`/t/${team.id}`}>{team.name}</a>
                    }
                    return "Not found"
                },
                filterFn: (row, id, filterValue) => {
                    const team = teamMap.get(row.original.teamId)

                    if (team) {
                        return team.name.toLowerCase().includes(filterValue.toLowerCase())
                    }
                    return false
                },
                header: 'Team',
                size: 150,
            },
            {
                accessorKey: 'updatedAt', //normal accessorKey
                header: 'Modified',
                size: 200,
                accessorFn: d => format(new Date(d.updatedAt as any), 'yyyy-MM-dd HH:mm'),
            }
        ],
        [canvases, teamMap],
    );
    return (
        <Stack
            sx={{
                flexGrow: 1,
                overflow: 'auto',
                // alignItems: 'center',
                // justifyContent: 'center'
            }}
        >
            <Divider></Divider>
            <MaterialReactTable

                enableGlobalFilter={false}
                enableDensityToggle={false}
                columns={columns}
                data={canvases} />
        </Stack>)

    // return (
    //     <>
    //         <div>organization-list-canvases</div>
    //         <Table>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>Name</TableCell>
    //                     <TableCell>Description</TableCell>
    //                     <TableCell>Modified</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {canvases.map((canvas) => (
    //                     <TableRow key={canvas.id}>
    //                         <TableCell component="th"
    //                             scope="row">
    //                             {canvas.name}
    //                         </TableCell>
    //                         <TableCell>{canvas.teamId}</TableCell>
    //                         <TableCell>{canvas.updatedAt}</TableCell>
    //                         <TableCell>{canvas.projectId}</TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //             {/* {canvases.filter(f => f).map((canvas: Canvas) => (
    //                 <li key={canvas.id}>{canvas.name}</li>
    //             ))} */}
    //         </Table>
    //     </>

    // )
}

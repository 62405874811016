import { avatarClasses, AvatarGroup, Box, Stack, Typography } from '@mui/material';
import { formatDistanceStrict } from 'date-fns';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { Message } from 'src/API';
import { useAuth } from 'src/hooks/use-auth';
import { OrganizationObject } from 'src/slices/user';
import { useSelector } from 'src/store';
import type { Participant, Thread } from 'src/types/chat';
import { customLocale } from 'src/utils/date-locale';
import { UserRecords } from '../team/team-table';



const getLastMessage = (messages: Message[]): Message | undefined | null => {
  if (!messages) return
  return messages.sort((a, b) => new Date(b.createdAt || -1).valueOf() - new Date(a.createdAt || -1).valueOf())[0] || undefined;

};

const getRecipients = (participants: (Participant | null | undefined)[], userId: string): (Participant | null | undefined)[] => {
  return participants.filter((participant) => participant && participant.id !== userId);
};

export const getDisplayName = (thread: Thread, recipients: UserRecords, organizations: OrganizationObject[]): string | undefined => {
  if (thread.name) return thread.name;

  const users = thread.members?.map((participant) => recipients[participant!])
  const orgs = organizations.filter(organization => thread.organizations?.some((o) => o === organization.role))

  let orgNames: string | undefined
  let userNames: string | undefined

  if (users && users?.length > 0) {
    userNames = users?.map(d => d?.account?.displayName || d?.email).join(', ')
  }
  if (orgs.length > 0) {
    orgNames = orgs.map(d => d.name).join(', ')
  }
  if (orgNames && userNames) {
    return `${orgNames} • ${userNames}`
  }
  if (orgNames) {
    return orgNames
  }
  return userNames || "Unknown"

};

const getDisplayContent = (userId?: string | null, lastMessage?: Message | null): string => {
  if (!lastMessage) {
    return '';
  }

  const author = lastMessage.owner === userId ? 'Me: ' : '';
  const message = lastMessage.content
  // lastMessage.contentType === 'image'
  //   ? 'Sent a photo'
  //   : lastMessage.body;

  return `${author}${message}`;
};

const getLastActivity = (lastMessage?: Message | null): string | null => {
  if (!lastMessage) {
    return null;
  }

  return formatDistanceStrict(
    new Date(lastMessage.createdAt!),
    new Date(),
    {
      addSuffix: false,
      locale: customLocale
    });
};

interface ChatThreadItemProps {
  active?: boolean;
  onSelect?: () => void;
  thread: Thread;
  organizations: OrganizationObject[]
  userRecords: UserRecords;
}

export const ChatThreadItem: FC<ChatThreadItemProps> = (props) => {
  const { active, thread, onSelect, organizations, userRecords, ...other } = props;
  const { user } = useAuth();
  const messages = useSelector(state => state.chat.messagesByChannel)

  const recipients = []// getRecipients(thread.members || [], user.id);

  let m: Message[] = []
  if (Array.isArray(messages.byId[thread.id])) {
    m = [...messages.byId[thread.id]]
  }
  const lastMessage = getLastMessage(m);
  const lastActivity = getLastActivity(lastMessage);
  const displayName = getDisplayName(thread, userRecords, organizations);
  const displayContent = getDisplayContent((user as any).id, lastMessage);
  const groupThread = recipients.length > 1;
  const isUnread = true//!!(thread.unreadCount && thread.unreadCount > 0);

  return (
    <Stack
      component="li"
      direction="row"
      onClick={onSelect}
      spacing={2}
      sx={{
        borderRadius: 2.5,
        cursor: 'pointer',
        px: 3,
        py: 2,
        '&:hover': {
          backgroundColor: 'action.hover'
        },
        ...(active && {
          backgroundColor: 'action.hover'
        })
      }}
      {...other}
    >
      <div>
        <AvatarGroup
          max={2}
          sx={{
            [`& .${avatarClasses.root}`]: groupThread
              ? {
                height: 26,
                width: 26,
                '&:nth-of-type(2)': {
                  mt: '10px'
                }
              }
              : {
                height: 36,
                width: 36
              }
          }}
        >
          {/* {recipients.map((recipient) => (
            <Avatar
              key={recipient.id}
              src={recipient.avatar || undefined}
            />
          ))} */}
        </AvatarGroup>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <Typography
          noWrap
          variant="subtitle2"
        >
          {displayName}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          {isUnread && (
            <Box
              sx={{
                backgroundColor: 'primary.main',
                borderRadius: '50%',
                height: 8,
                width: 8
              }}
            />
          )}
          <Typography
            color="text.secondary"
            noWrap
            sx={{ flexGrow: 1 }}
            variant="subtitle2"
          >
            {displayContent}
          </Typography>
        </Stack>
      </Box>
      {lastActivity && (
        <Typography
          color="text.secondary"
          sx={{ whiteSpace: 'nowrap' }}
          variant="caption"
        >
          {lastActivity}
        </Typography>
      )}
    </Stack>
  );
};

ChatThreadItem.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  // @ts-ignore
  thread: PropTypes.object.isRequired
};

ChatThreadItem.defaultProps = {
  active: false
};

import { TLShape } from "tldraw";

export interface CanvasLayoutConfig {
    width: number;
    h: number;
    initX: number;
    initY: number;
    margin: number;
    title: string
    labels: {
        header: string
        subheader: string
    }[]
}

export interface IShape {
    x: number
    y: number
    w: number
    h: number
    text: string
    fill?: string
    isFilled?: boolean
    color?: "light-violet" | "black" | "blue" | "green" | "grey" | "orange" | "red" | "violet" | "yellow" | "light-blue" | "light-green" | "light-red" | undefined
}


export const getCanvasLayout = (config: CanvasLayoutConfig = {
    width: 230,
    h: 300,
    initX: 200,
    initY: 200,
    margin: 10,
    labels: [],
    title: ''
}): IShape[] => {
    const { width, h, initX, initY, margin, labels } = config
    const layout: IShape[] = [
        // lean canvas
        {
            x: initX,
            y: initY,
            w: width,
            h: 2 * h,
            text: labels[0].header,
            color: 'black'
        },
        {
            x: (initX + width + margin),
            y: initY,
            w: width,
            h: h - margin / 2,
            text: labels[1].header,
            color: 'green'
        },
        {
            x: (initX + width + margin),
            y: (initY + h) + margin / 2,
            w: width,
            h: h - margin / 2,
            text: labels[0].header,
            color: 'green'
        },
        {
            x: (initX + 2 * width + 2 * margin),
            y: initY,
            w: width,
            h: 2 * h,
            text: labels[0].header,
            color: 'red'

        },
        {
            x: (initX + 3 * width + 3 * margin),
            y: initY,
            w: width,
            h: h - margin / 2,
            text: labels[0].header,

        },
        {
            x: (initX + 3 * width + 3 * margin),
            y: initY + h + margin / 2,
            w: width,
            h: h - margin / 2,
            text: labels[0].header,
            color: 'red'
        },
        {
            x: (initX + 4 * width + 4 * margin),
            y: initY,
            w: width,
            h: 2 * h,
            text: labels[0].header,
            color: 'red'
        },
        {
            x: (initX),
            y: (initY + 2 * h + margin),
            w: (5 * width + 4 * margin) / 2 - margin / 2,
            h: h,
            text: labels[0].header,
            color: 'red'

        },
        {
            x: initX + (5 * width + 4 * margin) / 2 + margin / 2,
            y: (initY + 2 * h + margin),
            w: (5 * width + 4 * margin) / 2 - margin / 2,
            h: h,
            text: labels[0].header,
            color: 'red'

        }
    ]
    return layout.map((item, index) => {
        item.text = labels[index] ? labels[index].header : ''
        item.isFilled = true
        return item
    })
}
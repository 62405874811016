

import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { thunks as projectThunks } from 'src/thunks/project';
import { thunks as teamThunk } from 'src/thunks/team';
import { useDispatch, useSelector } from '../store/index';
import { Team } from 'src/API';

export const useSetTeam = () => {
    const dispatch = useDispatch();
    const { teamId, projectId, ...other } = useParams()
    const teams = useSelector(s => s.team.listTeams)
    const setProjectId = useCallback((team: Team) => {
        if (projectId && projectId !== 'undefined') {
            const foundProject = team.projects?.items.find(p => p!.id === projectId)
            if (foundProject) {
                dispatch(projectThunks.setCurrentProject({ project: foundProject }))
                return
            }
        } else {
            const firstProject = team.projects?.items.filter(f => f)[0]
            if (firstProject) {
                dispatch(projectThunks.setCurrentProject({ project: firstProject }))
            }
        }
    }, [dispatch, projectId])

    useEffect(() => {
        const foundTeam = teams?.find(f => f.id === teamId)
        if (!foundTeam) return
        dispatch(teamThunk.setTeam({ team: foundTeam }))
        setProjectId(foundTeam)
        return () => {
        };

    }, [dispatch, setProjectId, teamId, teams, projectId]);
    return null;
};
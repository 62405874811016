import { useCallback, useEffect } from 'react';
import { thunks } from 'src/thunks/team';
import { useDispatch, useSelector } from '../store/index';
import { useParams } from 'react-router';
import { useState } from 'react';
export const useGetTeams = () => {
    const dispatch = useDispatch()
    const team = useSelector((state) => state.team)
    const { teamId } = useParams()
    const [hasFetched, setHasFetch] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const handleGetTeams = useCallback(
        async () => {
            if (!hasFetched) {
                setHasFetch(true)
                setIsLoading(true)
                await dispatch(thunks.getTeams({ teamId }))
                setIsLoading(false)
            }
        },
        [dispatch, teamId],
    )

    useEffect(() => {
        handleGetTeams()
        return () => {
        }
    }, [dispatch, handleGetTeams])

    return { team, hasFetched, isLoading }
}